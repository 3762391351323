import React from 'react';
import { Container,Button ,Form,Table,Card,Icon,Grid ,Input,Placeholder,Label, Dropdown, GridColumn,Responsive } from 'semantic-ui-react'
import "../styles/account.css"
import Sprinter from '../Components/Sprinter'
import SimpleMap from '../Components/Simplemap';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import Navlinks from './Navlinks'
class Footer extends React.Component{
    constructor(props){
        super(props)
    }
    componentDidMount(){

    }
    render(){
        return(
            <div className="textual-description">
              <Grid stackable columns="equal" >
                <Grid.Row >
                  <Grid.Column>
                    <a target="blank" href="/about">About Us</a> <br/>
                    <a target="blank" href="/business">Service Engineer / Seller</a> <br/>
                    <a target="blank" href="/franchise">Local Partners / Franchise</a> <br/>
                  </Grid.Column>
                  <Grid.Column style={{textAlign:'center'}}>
                    <span className="head-text">Company Details:</span><br/>
                    CIN: U74999MP2016PTC041773<br/>
                    GST: 18AAXCS9739A1ZO<br/>
                    DIPP: DIPP1842<br/>
                    MSME UAM No.: AS03D0001520
                  </Grid.Column>
                  <Grid.Column className="last" style={{textAlign:'right'}}>
                    <span className="head-text">Contact:</span><br/>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@deskneed.com" target="_blank">contact@deskneed.com</a><br/>
                    +91-9954683549<br/>
                    <a target="blank" href="https://blog.deskneed.com">Blog</a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
        )
    }
}

export default Footer;
