import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore(initialState={}) {
    // return createStore(
    //     rootReducer,
    //     initialState,
    //     applyMiddleware(thunk)
    // );

    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
    return createStore(rootReducer, composeEnhancers(
      applyMiddleware(thunk),
      // other store enhancers if any
    ));
}
