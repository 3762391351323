import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import HomeBody from '../Components/home_body'
import SectionContact from '../Components/section_contact'
import SectionTestimonials from '../Components/section_testimonials'

class Home extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div>

                <HomeBody history={this.props.history}/>

                <SectionTestimonials/>

                <SectionContact/>

            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(Home);
