import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';

import Button from '@material-ui/core/Button';

import '../styles/section_testimonials.css'

class SectionTestimonials extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div className="testimonial-container">
                <div className="testimonials-background">
                    <div className="testimonial_background_div">
                        <img src="/images/testimonial_profile_1.png"/>
                        <img src="/images/testimonial_profile_2.png"/>
                    </div>
                    <div className="testimonial_background_div centered">
                        <img src="/images/testimonial_profile_2.png"/>
                        <img src="/images/quote_open.png" className="quote_unquote" style={{height:'40px'}}/>
                        <div></div>
                        <img src="/images/quote_close.png" className="quote_unquote" style={{height:'40px'}}/>
                        <img src="/images/testimonial_profile_1.png"/>
                    </div>
                    <div className="testimonial_background_div">
                        <img src="/images/testimonial_profile_1.png"/>
                        <img src="/images/testimonial_profile_2.png"/>
                    </div>
                </div>
                <div className="testimonial_content">
                    <p style={{fontWeight: "bold", fontSize: "x-large"}}>What Our Clients Say?</p>
                    <img src="/images/testimonial_profile_1.png" width="100vw"/>
                    <p className="text" style={{marginTop: "20px", fontSize: "large"}}>Great convenience, have been a great helper for procurement of goods of different types.</p>
                    <p style={{fontSize: "small", fontWeight: 'bold'}}>Vivek Agarwal</p>
                    <p style={{fontSize: "small"}}>BPIL, Naltali</p>
                    <div>
                        <span class="material-icons">
                            keyboard_arrow_left
                        </span>
                        <span class="material-icons">
                            keyboard_arrow_right
                        </span>
                    </div>
                </div>
            </div>
		)
    }
}
export default SectionTestimonials;
