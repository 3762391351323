import React, { useLayoutEffect } from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Modal, Popup, Table, Accordion, Breadcrumb, ButtonGroup, Loader, Segment, Dimmer } from 'semantic-ui-react'
import "../styles/pricing-modal.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import {InterRouteCommunication} from '../actions/interRouteCommunication'
import Client from '../services/Client';
import { log } from 'util';


class PricingModalForType extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show_able_services:[],
      table_fields : [
        {text:"Amount",value:"amount", type:"field"},
        {text:"Unit",value:"unit", type:"field"},
        {text:"Description",value:"description", type:"field"},
        {text:"Terms",value:"terms", type:"field"},
        {text:"Start Date",value:"start_date", type:"field"},
        {text:"End Date",value:"end", type:"field"},
      ],
      data_rows:[],
      rate_types:[
        {name:'AMC',value:0},
        {name:'Subscription',value:1},
        {name:'On Demand',value:2},
      ],
      show_rate_type:0,
      pricing_city:'',
      pricing_service:'',
      accordion_index:0,
      loading:false
    }
  }
  componentDidMount(){
    // if (this.props.app_data.user_details.login) {
    //   this.getApprovers()
    // }
    // if(this.props.new_data_row.branch){
    //   this.setCityAndServices({},{name:"pricing_branch",value:this.props.new_data_row.branch})
    // }else if (this.props.new_data_row.city) {
    //   this.setCityAndServices({},{name:"pricing_city",value:this.props.new_data_row.city})
    // } else {
    //   this.setState({show_able_services:this.props.option_services})
    // }
    // setTimeout(()=>{
    //   if(this.props.new_data_row.service_category){
    //     this.setCityAndServices({},{name:"pricing_service",value:this.props.new_data_row.service_category})
    //   }
    // },300)
    // this.setState({})
  }
  setCityAndServices(e,data){
    let state = {...this.state}
    let services = this.props.option_services
    if (data.name == "pricing_city" && data.value != "") {
      state.show_able_services = services.filter(service=>{
        return service.city.toUpperCase() == data.value.toUpperCase()
      })
    }
    if (data.name == "pricing_branch" && data.value != "") {
      let branch_object = this.props.app_data.user_details.branches.find(branch=>{
        return branch.id == data.value
      })
      console.log(branch_object,data.value);
      
      state.show_able_services = services.filter(service=>{
        console.log("from branches",service.city)
        return service.city.toUpperCase() == branch_object.city.toUpperCase()
      })
      state.pricing_city = branch_object.city
    }
    if ((data.name == "pricing_city" || data.name == "pricing_branch") && data.value == "") {
      state.show_able_services = services
      
    }
    if (data.name == "pricing_service" ) {
      let pricing_service_object = this.props.option_services.find(elem=>{return elem.value == data.value})
      state.pricing_sub_category = this.state.pricing_sub_category
      console.log(data.value,this.state.pricing_service);
      if (data.value != this.state.pricing_service) {
        
        state.pricing_sub_category=false
      }
      
    }
    state[data.name]=data.value
    if (state.pricing_city != "" && state.pricing_service !="") {
      
      this.getDataRows(state)
    }
    this.setState({...state})
  }
  getDataRows(state){
    // let user_details = this.props.app_data.user_details
    this.setState({loading:true})
    Client.getPricing(null,null,{},null,null,state.pricing_service,state.pricing_city)
    .then(res=>{
      console.log(res)
      this.setState({
        data_rows:res.pricing_structure.filter(elem=>{
          return elem.subscription_type == this.props.app_data.showPricingModalForType
        })
        ,loading:false})
    })
  }
  setSelectedPricing(data_row){
    this.props.newDataRowAction({...this.props.new_data_row,selected_pricing:data_row.amount,qty:0,subscription_type:data_row.subscription_type,description:data_row.description,unit:data_row.unit})
    this.props.setAppData({...this.props.app_data,showPricingModalForType:false})
  }
  render(){
    return(
     
        <Modal.Content scrolling>
          {
            this.state.loading
            ?
            <Segment>
              <Dimmer active>
                <Loader />
              </Dimmer>

              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
            </Segment>
            :

          
            this.props.app_data.data_rows.length
            ?
              <Table unstackable compact celled className="scroll-table">
                <Table.Header>
                  {
                    this.state.table_fields.map(field=>{
                      return(
                        <Table.HeaderCell>{field.text}</Table.HeaderCell>
                      )
                    })
                  }
                </Table.Header>
                <Table.Body>
                  {
                    this.props.app_data.data_rows.map(data_row=>{
                      if(data_row.subscription_type == this.props.app_data.showPricingModalForType)
                      return (
                        <Table.Row 
                          onClick={()=>this.setSelectedPricing(data_row)}
                          style={{cursor:'pointer'}}
                          >
                          {
                            this.state.table_fields.map(field=>{
                              return(
                                <Table.Cell>{data_row[field.value]}</Table.Cell>
                              )
                            })
                          }
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table>
            :
            <>
            <h2>This service does not have 
              {
                " \""+
                this.state.rate_types.find(elem=>{
                  return elem.value == this.props.app_data.showPricingModalForType
                })
                .name
                
                +"\" "
              } 
               rates </h2>
            </>
          }
        </Modal.Content>
    )
  }
}
const mapStateToProps = state => ({
  ...state
 })
const mapDispatchToProps = dispatch => ({
  newDataRowAction: (data) => dispatch(newDataRowAction(data)),
  Contact:  (data) => dispatch(contactAction(data)),
  optionServices: (data) => dispatch(serviceAction(data)),
  optionCategories: (data) => dispatch(categoryAction(data)),
  setAppData: (data) => dispatch(appDataAction(data)),
  deployInterRouteMessage: (data) => dispatch(InterRouteCommunication(data))
 })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingModalForType));
