import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../Components/section_testimonials';
import SectionFaq from '../Components/section_faq';
import SectionContact from '../Components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/about_body.css'

class About extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    render(){
        return(
            <div>
                <p style={{fontSize:'xx-large', fontWeight:'bold', textAlign:'center'}}>About Us</p>
                <div className="body-about">
                    <div className="body-about-1-1">
                        <div style={{fontSize: "large", width: "60%", textAlign:"left", paddingRight:'5%'}}>
                            <p style={{fontSize: "x-large"}}><b>Supply procurement solution for offices.</b></p>
                            <p style={{fontSize: 'medium'}}>
                                A tech enabled platform to manage office supply needs for your organisation (office / hotel / hospital / institute).
                                <br/><br/>
                                Deskneed bridges the gap of unprofessionalism, unreliability, timeliness by providing an automated process and software hence simplifying work for both organisations and suppliers. Based out of Guwahati, the company has its presence in entire North East. From office stationery to office furniture, get the best among various competitive prices in the market, and at one place.
                            </p>
                        </div>
                        <img src="images/wow_technology.png" style={{width: "40%"}}/>
                    </div>
                </div>

                <div className="body-about-2">
                    <div style={{fontSize: "large", width: "60%", textAlign:"left"}}>
                        <p style={{fontSize: "x-large"}}><b>Benefits</b> with Deskneed.</p>
                        <ul style={{lineHeight: "200%"}}>
                            <li>Procurement Management System</li>
                            <li>Technology monitoring</li>
                            <li>Expense Report and Analytics</li>
                            <li>Vendor Management - Add and manage vendors.</li>
                            <li>Proper Online Invoicing & Quotation</li>
                            <li>Payment tracking, GST report</li>
                        </ul>
                        {
                            // <Button variant="contained" color="primary" size="medium">Know More</Button>
                        }
                    </div>
                    <img src="images/laptop_ss.png" style={{width: "40%"}}/>
                </div>

{
                // <div className="body-about-3">
                //     <img src="images/software_explainer.png" style={{width: "45%"}}/>
                //     <div style={{fontSize: "large", width: "50%", margin: "0 0 5% 5%"}}>
                //         <p style={{fontSize: "x-large"}}>Not just a software<br/><b>an assistant for you.</b></p>
                //         <p style={{fontSize: 'medium'}}>Deskhub acts as your assistant to help you manage all
                //         procurement needs by acting as a bridge between
                //         company and supplier / service providers.
                //         <br/><br/>
                //         Deskhub manages on ground communication with vendors to increase reliability and quality during purchase of goods and services.
                //         </p>
                //     </div>
                // </div>
}
                <div className="body-about-traction">
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>1500+</p>
                        <p style={{fontSize: "large"}}>Orders Served</p>
                    </div>
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>800</p>
                        <p style={{fontSize: "large"}}>B2B Customers</p>
                    </div>
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>50+</p>
                        <p style={{fontSize: "large"}}>Cities Covered</p>
                    </div>
                </div>

                <SectionTestimonials/>

                <SectionFaq/>

                <SectionContact/>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(About);
