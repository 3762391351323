import React from 'react';
import { Container, Message, Button ,Form,Card,Icon,Step,Table,Grid ,Rating,Breadcrumb, Input,Pagination,Label, GridColumn, Responsive,Modal, Dropdown, Loader } from 'semantic-ui-react'
import "../styles/orders.css"
import Sprinter from '../Components/Sprinter'
import SimpleMap from '../Components/Simplemap';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import Client from '../services/Client';
import {InterRouteCommunication} from '../actions/interRouteCommunication'
import SaleView from './sale-voucher-view';
import QuotationView from './quotation-view';
import {appDataAction} from '../actions/appDataAction'
import FileOrdersPreview from './file-order-preview'
// import PurchaseView from './purchase-view';
import DeleveryChallanView from './d-note-view';
import Navlinks from './Navlinks'
import Footer from './footer';
import VerifyOrder from './verify-order';
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
class Orders extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            option_branches:[],
            cust_branch:'',
            orders :[],
            feedback:false,
            selected_order: {},
            no_of_pages:1,
            steps:{
                approval:{stages:["approval","approval_pending","approval_rejected"],icon:"clock"},
                initiated:{stages:['initiated','follow','vendor_assign'],icon:'right arrow'},
                quotation:{stages:['quotation','quotation_follow'],icon:'handshake'},
                work_progress:{stages:['work_due','work_progress','site_visit','designing','printing'],icon:'settings'},
                payment:{stages:['payment','invoice'],icon:'payment'},
                completed:{stages:['feedback','completed','cancelled'],icon:'check circle'}
            },
            offset:0,
            comments : [
                {comment:'order acknowledged',commenter:'badal mishra',date:'2019-08-1',commenter_type:'staff'},
                {comment:'how long will it take',commenter:'customer',date:'2019-08-1',commenter_type:'customer'},
                {comment:'by the 3rd of this month, you can check the order status and next date for such queries',commenter:'badal mishra',date:'2019-08-1',commenter_type:'staff'},
                {comment:'order dispatched inform@badal_mishra:12:',commenter:'badal mishra',date:'2019-08-1',commenter_type:'staff'}
            ],
            files:[],
            tableFields: [
                {"value": "slip_no", "text": "Slip No.", "type": "field"},
                {"value": "priority", "text": "Priority", "type": "field"},
                {"value": "slip_date", "text": "Slip Date", "type": "date"},
                {"value": "customer", "text": "Customer", "type": "select"},
                {"value": "requirement", "text": "Requirement", "type": "field"},
                {"value": "vendor", "text": "Vendor", "type": "select"},
                {"value": "current_status", "text": "Status", "type": "select"},
                {"value": "next_date", "text": "Next Update On", "type": "field"},
                {"value": "current_department", "text": "Department", "type": "select"},
                {"value": "current_employee", "text": "Employee", "type": "select"},
                {"value": "revenue_margin", "text": "Margin", "type": "field"},
            ],
            tableFieldsVoucher: [
                {"value": "date", "text": "Voucher Date", "type": "date"},
                {"value": "ledger", "text": "Vendor / Customer", "type": "select"},
                {"value": "voucher_id", "text": "Invoice Id", "type": "field"},
                {"value": "order_id", "text": "Order Id", "type": "field"},
                {"value": "payable_amount", "text": "Amount", "type": "field"},
                {"value": "status", "text": "Status", "type": "select"},
            ],
            redirect_to:[
                {text:'Sale',value:'/accounting/vouchers/invoice/create',key:'sale'},
                {text:'Quotation',value:'/accounting/vouchers/quotation/create',key:'quotation'},
                // {text:'Purchase',value:'/accounting/vouchers/purchase/create',key:'purchase'},
                {text:'Delivery Challan',value:'/accounting/vouchers/challan/create',key:'delivery_note'},
            ],
            feedback_status_criteria:['payment','feedback','completed'],
            api_sort:'slip_no',
            api_sort_order:'desc',
            last_order:{},
            users:[],
            currentDataRowAction:'none',
            remarks:{},
            approving:{},
            fetching: false
        }
        this.statusValueToText = this.statusValueToText.bind(this)
        this.orderSteps = this.orderSteps.bind(this)
        this.orderApprovers = this.orderApprovers.bind(this)
    }
    convertSelectToText(key, value){
        if(key == 'ledger'){
            if (this.state.voucher_name == 'purchase') {
                key = 'vendor'
            } else {
                key = 'customer'
            }
        }
        console.log(key,value);
        var datas = this.state['options_' + key];
        console.log(datas,this.state,key);

        if (datas) {
            for(var i = 0; i < datas.length; i++){
                var data = datas[i];
                if(data.value == value){
                    return data.text;
                }
            }
        }
        return value + ' : N/A';
    }
    orderSteps(status){
        let formated_steps = []
        let steps = this.state.steps
        for (const step in steps) {
            if (steps[step].stages.includes(status)) {
                formated_steps.push({
                    step:step,
                    active:1,
                    icon:steps[step].icon
                })
            } else {
                formated_steps.push({
                    step:step,
                    active:0,
                    icon:steps[step].icon
                })
            }
            console.log(steps[step]);
        }

        return formated_steps
    }
    componentDidMount(){
        let option_branches = []
        option_branches.push({text:'All',value:'All'})
        this.props.app_data.user_details.branches.forEach(branch => {
            option_branches.push({text:branch.branch_name, value:branch.id})
        })
        let cust_branch
        if(option_branches.length == 2){
            cust_branch = option_branches[1].value
        } else {
            cust_branch= option_branches[0].value
        }
        this.setState({option_branches,cust_branch},()=>{
            this.getUsers()
        })
    }
    setRemark(event){
        let remarks = this.state.remarks
        remarks[event.target.name] = event.target.value
        this.setState({remarks})
    }
    getUsers(){
        let user_details = this.props.app_data.user_details
        Client.getCompanyUsers(user_details.token,user_details.reg_id,user_details.username).then(res=>{
            // let users = []
            /*New Code */
            let users = res.data_rows
            /*end New Code*/
            // res.data_rows.forEach(user=>{
            //   user.corp_branch = JSON.parse(user.corp_branch)
            //   user.approvers = JSON.parse(user.approvers)
            //   try {
            //     user.city = JSON.parse(user.city)
            //   } catch (error) {
            //     console.log(error);

            //   }
            //   users.push(user)
            // })
            this.setState({users,observed_user:false},()=>{
                this.getOrders()
            })
        })
    }
    clearInterPageMessage(){
        this.props.deployInterRouteMessage(false)
    }
    onConsoleDataChange(e,data){
        this.setState({
            [data.name]:data.value
        },()=>{
            this.getOrders()
        })
    }
    getOrders(){
        this.setState({fetching:true, orders: []})
        let user_details = this.props.app_data.user_details
        let filter ={}
        if (this.state.cust_branch != 'All') {
            filter= {cust_branch:{filter_type: 'value_in', value:[this.state.cust_branch]}}
        } else {
            let filter_branches = this.props.app_data.user_details.branches.map(branch => {return branch.id})
            filter = {cust_branch:{filter_type: 'value_in', value:[...filter_branches]}}
        }
        Client.getOrders(user_details.token,user_details.reg_id,user_details.username,this.state.offset,this.state.offset+10,filter,this.state.api_sort,this.state.api_sort_order).then(res=>{
            console.log(res);
            let orders = res.data_rows.map(order=>{
                var listDisplay = []
                if(order.list != undefined && order.list != null)
                {
                    var list = JSON.parse(order.list)
                    list.forEach(function(listElement, index)
                    {
                        if(listElement.name != undefined && listElement.name.trim() != '')
                        listDisplay.push((listElement.name + " (Rs." + listElement.rate + ") - " + listElement.qty))
                    })
                }

                return {
                    slip_no:order.slip_no,
                    files:order.files || undefined,
                    list: order.list ? JSON.parse(order.list) : [],
                    priority: order.priority,
                    slip_date:order.slip_date,
                    date:order.slip_date,
                    customer:order.customer,
                    customer_name:order.customer_name,
                    existing_vouchers:order.existing_vouchers,
                    vouchers:order.vouchers,
                    branch:order.branch,
                    cust_branch:order.cust_branch,
                    service:order.requirement,
                    requirement:order.requirement,
                    vendor:order.vendor,
                    current_status:order.current_status,
                    status:order.current_status,
                    next_date:order.next_date,
                    current_department:order.current_department,
                    current_employee:order.current_employee,
                    assigned_to:order.current_employee,
                    approval_by:order.approval_by,
                    contacts:order.contacts,
                    corp_account:order.corp_account,
                    rating:order.rating,
                    review:order.review,
                    sugesstions:order.suggestions,
                    feedback_token:order.feedback_token,
                    listDisplay: listDisplay,
                    confirmation: order.confirmation,
                }
            })
            let last_order = [{}]
            if (this.props.inter_route.data) {
                last_order = orders.filter(order=>{return order.slip_no == this.props.inter_route.data})
            }
            this.setState({
                last_order:last_order[0],
                orders,
                no_of_pages:Math.floor(res.data_rows_size/10)+1,
                fetching:false
            })
        })
    }
    toggleCommentsOf(id){
        let show_comments_of = this.state.show_comments_of
        if (show_comments_of == id) {
            this.setState({commnets:[],show_comments_of:false})
        } else {
            show_comments_of = id
            this.setState({fetching_comments:true,show_comments_of,show_contacts_of:false,new_comment:''},()=>{
                this.getComments()
            })
        }
    }
    getComments(){
        let show_comments_of = this.state.show_comments_of
        let filter = {slip_no:[show_comments_of]}
        let user_details  = this.props.app_data.user_details
        Client.getOrderComments(user_details.token,user_details.reg_id,user_details.username,filter).then(res =>{
            console.log(res);
            let comments = res.data_rows.reverse().map(comment=>{
                return {
                    comment:comment.comment,
                    commenter: comment.commenter_name,
                    date: comment.commented_on.split(' ')[1],
                }
            })
            this.setState({comments,fetching_comments:false})
        })
    }
    toggleContactsOf(id){
        let show_contacts_of = this.state.show_contacts_of
        if (show_contacts_of == id) {
            show_contacts_of = false
        } else {
            show_contacts_of = id
        }
        this.setState({show_contacts_of,show_comments_of:false})
    }
    orderApprovers(id){
        let approver = ''
        // array.forEach((approver,i)=>{
        //   approvers += this.state.users.find(user=>{return user.account_id == approver}).username + (i== array.length-1)?' ':', '
        // })
        approver = this.state.users.find(user=>{return user.account_id == id}).name
        console.log(approver);

        return approver
    }
    orderApproversUsername(id){
        let approver = ''
        // array.forEach((approver,i)=>{
        //   approvers += this.state.users.find(user=>{return user.account_id == approver}).username + (i== array.length-1)?' ':', '
        // })
        approver = this.state.users.find(user=>{return user.account_id == id}).username
        console.log(approver);

        return approver
    }
    //re formatters
    checkShortHandsInComment(comment){

        let shortHandsArr = []
        let wordsInComment = comment.split(' ');
        wordsInComment.forEach(word => {
            if( word.includes("@") ){
                let arr = word.split('@')
                let value = ''
                value = arr[1]
                let string = arr[0]+"ed to "+ value
                shortHandsArr.push(
                    <span class="badge">
                    {string}
                    </span>
                )
            }
        });
        return shortHandsArr;
    }
    statusValueToText(value){
        let text
        // this.state.options_current_status.forEach(status=>{
        //   if (status.value == value) {
        //     console.log(value,status);
        //     text = status.text
        //   }
        // })
        for (const key in this.state.steps) {
            if(this.state.steps[key].stages.includes(value)){
                text = key.toUpperCase()
            }
            console.log(key,value,this.state.steps[key]);
        }
        return text
    }
    postNewComment(){
        let user_details  = this.props.app_data.user_details
        this.setState({sending_comment:true})
        let new_data_row = {
            slip_no : this.state.show_comments_of,
            comment:this.state.new_comment,
            show_customer:1
        }
        Client.createOrderComment(user_details.token,user_details.reg_id,user_details.username,new_data_row)
        .then(res => {
            this.setState({new_comment:'',sending_comment:false},()=>{
                this.getComments()
            })
        })
    }
    handlePaginationChange = (e, { activePage }) => {
        this.setState({ offset:(activePage*10)-10 },()=>{
            this.getOrders()
        })
    }
    handleApiSort(data) {
        let api_sort_order = this.state.api_sort_order
        let api_sort = this.state.api_sort
        if (data.name == 'api_sort') {
            api_sort =data.value
        } else if (data.name == "api_sort_order") {
            api_sort_order = api_sort_order == "asc"?"desc":"asc"
        }
        this.setState({api_sort,api_sort_order},()=>{
            this.getOrders()
        })
    }
    orderStatusAction(slip_no,action){
        let remarks = this.state.remarks[slip_no]
        // let action = this.state.currentDataRowAction
        let user_details = this.props.app_data.user_details
        this.setState({approving:{slip_no:slip_no,type:action}})
        Client.approveOrderSlip(
            user_details.token,user_details.reg_id,user_details.username,
            {remarks,approval:action,slip_no}
        ).then(res=>{
            this.setState({currentDataRowAction:'none',remarks:{},approving:false},()=>{
                this.getOrders()
            })
        })
    }
    showVoucherDetails=(data_row, module_name)=>{
        console.log("data_row:",data_row,module_name)
        data_row['cust_branch'] = data_row['branch']
        delete data_row['branch']
        this.setState({
            show_voucher:module_name,
            data_row_voucher:data_row,
            loading_voucher_for:false,
            [module_name+'loading']:false,
        },()=>{console.log("added")})
    }
    voucherList(voucher_list,voucher_name,redirect_to,order_id){
        this.setState({
            voucher_list:voucher_list,
            voucher_name:voucher_name,
            show_voucher:'list',
            voucher_redirect_to:redirect_to,
            loading_voucher_for:false,
            voucher_order_id:order_id,
            [redirect_to+'loading']:false

        })
    }
    onVoucherListSelect(id){
        let data_row = this.state.voucher_list.filter(elem=>{return elem.id == id})
        this.showVoucherDetails(data_row[0],this.state.voucher_name)
        // this.setState({voucher_list:false,voucher_name:false})
    }
    voucherAction(order_id,redirect_to,existing_vouchers){
        if (existing_vouchers.includes(redirect_to.key)) {
            this.setState({[redirect_to.key+'loading']:true,loading_voucher_for:order_id})
            let user_details = this.props.app_data.user_details
            switch (redirect_to.key) {
                case 'sale':
                Client.getAccountingSale(user_details.token,user_details.reg_id,user_details.username,null,null,null,null,{order_id:[order_id]},)
                .then(res=>{
                    this.showVoucherDetails(res.data_rows[0],'sale')
                })
                break;
                case 'quotation':
                Client.getAccountingQuotation(user_details.token,user_details.reg_id,user_details.username,null,null,null,null,{order_id:[order_id]},null,'sale')
                .then(res=>{
                    this.showVoucherDetails(res.data_rows[0],'quotation')
                })
                break;
                case 'delivery_note':
                Client.getAccountingDeleveryChallan(user_details.token,user_details.reg_id,user_details.username,null,null,null,null,{order_id:[order_id]},null,'sale')
                .then(res=>{
                    console.log(res.data_rows)
                    this.voucherList(res.data_rows,'delivery_note',redirect_to,order_id)
                })
                break;
                default:
                break;
            }
        } else {
            console.log(order_id,redirect_to)
            // localStorage.setItem(redirect_to.value,order_id)
            // this.props.history.push(redirect_to.value)
            this.setState({show_voucher:'NCY',voucher_message:redirect_to.key.charAt(0).toUpperCase()+redirect_to.key.slice(1)+' Yet To Be Created'})
        }
    }

    addToList(addOrRemove, index){
        var new_data_row = {...this.state.selected_order}
        var list = [...new_data_row.list]
        if(addOrRemove){
            list.push({name:''});
        }
        else{
            list.splice(index, 1);
        }
        new_data_row.list = list
        this.setState({
            selected_order: new_data_row
        })
    }
    modifyListElement(e, data, index){
        var new_data_row = {...this.state.selected_order}
        var list = [...new_data_row.list]
        var listElement = {...list[index]}
        listElement[data.name] = data.value.substring(0,30)
        listElement["qty"] = listElement["qty"] == undefined ? 0 : listElement["qty"]
        listElement["rate"] = listElement["rate"] == undefined ? 0 : listElement["rate"]
        list[index] = listElement
        new_data_row.list = list
        this.setState({
            selected_order: new_data_row
        })
    }

    updateList(){
        let data = new FormData()
        let user_details = this.props.app_data.user_details
        if(JSON.stringify(this.state.selected_order.list) != JSON.stringify(this.state.list_check)){
            this.setState({
                updatingOrder: true
            })
            Client.createOrder({...this.state.selected_order, edit: true}, user_details.token,user_details.reg_id,user_details.username,"",data).then(res=>{
                this.getOrders();
                this.setState({
                    updatingOrder: false,
                    showListModal: false
                })
            })
        }
        else{
            this.setState({
                showListModal: false
            })
        }
    }

    render(){
        return(
            <>
            {
                this.props.app_data.user_details.login
                ?
                <Navlinks
                company={this.state.company_details}
                user={this.state.user_details}
                />
                :
                null
            }
            <div className="min" textAlign="center">
            <div className="oreder-consoles">
            <Dropdown
            className="for"
            selection
            loading={this.state.fetching}
            name="cust_branch"
            onChange={(e,data)=>{this.onConsoleDataChange(e,data)}}
            options={this.state.option_branches}
            value={this.state.cust_branch}
            placeholder="Select A Branch"
            />
            <Button className="sort"   icon labelPosition='right'>
            <Dropdown onChange={(e,data)=>this.handleApiSort(data)} name="api_sort" value={this.state.api_sort} options={this.state.tableFields}/>
            <Icon basic onClick={()=>this.handleApiSort({name:'api_sort_order',value:null})} name={this.state.api_sort_order==="asc"?"up arrow":' down arrow'}/>
            </Button>
            <Pagination
            activePage={(this.state.offset/10)+1}
            boundaryRange={5}
            onPageChange={this.handlePaginationChange}
            size='mini'
            totalPages={this.state.no_of_pages}
            />
            </div>
            <div className="orders">
            {
                // !this.state.orders.length
                this.state.fetching
                ?
                <Loader active size="huge" color="black"/>
                :
                null
            }
            <div style={{textAlign: 'center', display: (!this.state.orders.length && !this.state.fetching) ? "block" : "none"}}>
            NO ORDERS! MAKE A BOOKING.
            </div>
            {
                this.state.orders.map(order => {
                    let contact_types = order.contacts.map((element)=>{
                        return element.type;
                    })
                    let distinct_types = [... new Set(contact_types)]
                    return(
                        <Card>
                        <Card.Content>

                        <div className="lines">
                        <span className="lefts">
                        <b> #{order.slip_no}</b>
                        </span>
                        <Responsive minWidth={768}>
                        <span className="rights">
                        <Step.Group stackable  >
                        {this.orderSteps(order.status).map(st=>(
                            <Step primary  active={st.active == 1}>
                            <Step.Content >
                            <Step.Title>
                            {/* <Icon name={"remove"}/> */}

                            {
                                st.active == 1
                                ?
                                order.status.toUpperCase()
                                :
                                this.statusValueToText(st.step)
                            }
                            </Step.Title>
                            </Step.Content>
                            </Step>
                        ))}
                        </Step.Group>
                        </span>
                        </Responsive>
                        <Responsive maxWidth={768}>
                        <span className="rights">
                        <label className="order-labels">
                        Status
                        </label>
                        {order.status}
                        </span>
                        </Responsive>
                        </div>
                        <div className="lines">
                        <span></span>
                        <Responsive minWidth={768}>
                        <span className="rights">
                        <label className="order-labels">
                        Status
                        </label>
                        {order.status}
                        </span>
                        </Responsive>
                        <Responsive maxWidth={768}>
                        <span className="rights">
                        <label className="order-labels">
                        Date
                        </label>
                        {order.date}
                        </span>
                        </Responsive>
                        </div>
                        <div className="lines">
                        <span className="lefts">
                        <label className="order-labels">
                        Placed By
                        </label>
                        {order.corp_account	|| 'N/A'}
                        </span>
                        <Responsive minWidth={768}>
                        <span className="rights">
                        <label className="order-labels">
                        Date
                        </label>
                        {order.date}
                        </span>
                        </Responsive>
                        <Responsive maxWidth={768}>
                        <span className="rights">
                        <label className="order-labels">
                        Next
                        </label>
                        {order.next_date}
                        </span>
                         </Responsive>
                        </div>
                        <div className="lines">
                            <span className="lefts">
                                <label className="order-labels">
                                Requirement
                                </label>
                                {order.service}
                            </span>
                            <Responsive minWidth={768}>
                                <span className="rights">
                                <label className="order-labels">
                                Next
                                </label>
                                {order.next_date}
                                </span>
                            </Responsive>
                        </div>
                        <div className="lines">
                            <span className="lefts">
                                <Message onClick={() => {this.setState({showListModal:true, selected_order: order, list_check: order.list})}}
                                style={{cursor:'pointer', display: (order.listDisplay != undefined && order.listDisplay != '') ? 'block' : 'none'}} list={order.listDisplay}/>
                            </span>
                        </div>
                        <div className="lines">

                        {
                            order.approval_by
                            // true
                            ?
                            <span className="lefts">
                            <label className="order-labels">
                            Approver
                            </label>
                            {this.orderApprovers(order.approval_by)}
                            </span>
                            :
                            null

                        }
                        <Responsive minWidth={768}>
                        {
                            order.approval_by
                            // true
                            ?
                            (this.orderApproversUsername(order.approval_by) == this.props.app_data.user_details.username
                            &&
                            order.status=="approval_pending")
                            // ||
                            // true
                            ?
                            <span className="rights">
                            <div className="oreder-consoles">
                            <Button loading={this.state.approving.slip_no == order.slip_no && this.state.approving.type == 1}  onClick={()=>{this.orderStatusAction(order.slip_no,1)}} className="theme-btn" size="tiny">
                            Approve
                            </Button>
                            <Input size="mini" name={order.slip_no} onChange={(event)=>this.setRemark(event)} value={this.state.remarks[order.slip_no]} placeholder="Remarks"></Input>
                            <Button loading={this.state.approving.slip_no == order.slip_no && this.state.approving.type == 0} onClick={()=>{this.orderStatusAction(order.slip_no,0)}} className="theme-btn" size="tiny">
                            Reject
                            </Button>
                            </div>
                            </span>
                            :
                            null
                            :
                            null
                        }
                        </Responsive>
                        </div>
                        <div className="lines">
                            <div className="lefts">
                            {
                                this.state.redirect_to.map(elem=>(
                                    <Button
                                    color={order.existing_vouchers.includes(elem.key)?'green':'whitesmoke'}
                                    size="tiny"
                                    loading={this.state[elem.key+'loading'] && this.state.loading_voucher_for ==order.slip_no}
                                    onClick={()=>{this.voucherAction(order.slip_no,elem,order.existing_vouchers)}}>
                                        {elem.text}
                                    </Button>
                                ))
                            }
                            {
                                order.files
                                ?
                                <Button
                                // color={order.existing_vouchers.includes(elem.key)?'green':'whitesmoke'}
                                size="tiny"
                                // loading={this.state[elem.key+'loading'] && this.state.loading_voucher_for ==order.slip_no}
                                // onClick={()=>{this.voucherAction(order.slip_no,elem,order.existing_vouchers)}}
                                onClick={()=>{
                                this.setState({files:JSON.parse(order.files)})
                                }}>
                                    Attachments
                                </Button>
                                :
                                null
                            }
                            </div>
                            <div className="rights">
                            {
                                this.state.feedback_status_criteria.includes(order.status)
                                ?
                                    <Button
                                    size="tiny"
                                    className="ui blue"
                                    color="blue"
                                    onClick={()=>{
                                        if(order['rating'])
                                        this.setState({feedback:{review:order.review,rating:order.rating,sugesstions:order.suggestions}})
                                        else
                                        this.props.history.push('/feedback/'+order.slip_no+'/'+(order.feedback_token?order.feedback_token.toString():'') )
                                    }}>
                                        Feedback
                                        </Button>
                                :
                                    null
                            }
                            {
                                order.confirmation != null
                                ?
                                    <Button size="small" color="green" icon="thumbs up" onClick={() => this.setState({showCompleteVerification: true, orderConfirmationData: order.confirmation, selected_order: order})}/>
                                :
                                    null
                            }
                            </div>
                        </div>
                        </Card.Content>
                        <Responsive maxWidth={768}>
                        {
                            order.approval_by
                            ?
                            this.orderApproversUsername(order.approval_by) == this.props.app_data.user_details.username && order.status=="approval_pending"
                            ?
                            <div className="oreder-consoles">
                            <Button loading={this.state.approving.slip_no == order.slip_no && this.state.approving.type == 1}  onClick={()=>{this.orderStatusAction(order.slip_no,1)}} className="theme-btn" size="tiny">
                            Approve
                            </Button>
                            <Input name={order.slip_no} onChange={(event)=>this.setRemark(event)} value={this.state.remarks[order.slip_no]} placeholder="Remarks"></Input>
                            <Button loading={this.state.approving.slip_no == order.slip_no && this.state.approving.type == 0} onClick={()=>{this.orderStatusAction(order.slip_no,0)}} className="theme-btn" size="tiny">
                            Reject
                            </Button>
                            </div>
                            :
                            null
                            :
                            null
                        }
                        </Responsive>
                        <Card.Content extra >
                        <span className="card-bottom">
                        <span className="dsk-btns" onClick={() => {this.toggleCommentsOf(order.slip_no)}}>
                        <Icon size="small" name={this.state.show_comments_of == order.slip_no?"up arrow":'down arrow'}/>
                        Comments
                        </span>
                        <span className="dsk-btns" onClick={() => {this.toggleContactsOf(order.slip_no)}} style={{float:'right'}} >
                        <Icon size="small" name={this.state.show_contacts_of == order.slip_no?"up arrow":'down arrow'}/>
                        Contacts
                        </span>
                        </span>
                        </Card.Content>
                        {
                            this.state.show_comments_of == order.slip_no
                            ?
                            <Card.Content>
                            <div className="comment-container">
                            {
                                this.state.fetching_comments?
                                <Loader loading={true} size="small"/>
                                :
                                this.state.comments.map((comment)=>(
                                    <>
                                    <div className="badge-container">
                                    {this.checkShortHandsInComment(comment.comment)}
                                    </div>
                                    <div className="comment">
                                    <div className="comment-head">
                                    <span className="comment-head-name">{comment.commenter}</span>
                                    <span className="comment-head-date">{comment.date}</span>
                                    </div>
                                    <div className="comment-body">
                                    {comment.comment}
                                    </div>
                                    </div>
                                    </>
                                ))
                            }
                            </div>
                            <div className="comment-input">
                            <Input
                            icon={<Icon onClick={()=>this.postNewComment()} name='paper plane' inverted circular link />}
                            fluid wide
                            loading={this.state.sending_comment}
                            name="new_comment"
                            placeholder='Ask Here...'
                            value={this.state.new_comment}
                            onChange={(e,data)=>this.setState({[data.name]:data.value})}
                            />
                            </div>
                            </Card.Content>
                            :
                            null
                        }
                        {	//contacts will show up here
                            this.state.show_contacts_of === order.slip_no?
                            <Card.Content>
                            {
                                order.contacts.length > 0?
                                <Table celled compact unstackable>
                                <Table.Header >
                                <Table.Row  className="contactRows">
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Phone</Table.HeaderCell>
                                <Table.HeaderCell>E-mail</Table.HeaderCell>
                                </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {
                                    distinct_types.map(type=>{
                                        return(
                                            <>
                                            <Table.Row textAlign="center" className="contactRows" fullWidth>
                                            <Table.Cell colSpan={3} className="typeTitle">{type}</Table.Cell>
                                            </Table.Row>
                                            {order.contacts.map((contact)=>(
                                                contact.type == type?
                                                <Table.Row className="contactRows" >
                                                <Table.Cell>{contact.name}</Table.Cell>
                                                <Table.Cell>{contact.phone}</Table.Cell>
                                                <Table.Cell>{contact.email}</Table.Cell>
                                                </Table.Row>
                                                :null)
                                            )
                                        }
                                        </>
                                    )
                                })
                            }

                            </Table.Body>
                            </Table>
                            :
                            <Card.Meta>
                            *No Contacts Yet
                            </Card.Meta>
                        }


                        </Card.Content>
                        :null
                    }

                    </Card>
                )})
            }

            </div>
            <Modal open={this.props.inter_route.data} onClose={()=>this.clearInterPageMessage()}>
            <Modal.Header>
            <span style={{display:'flex',justifyContent:'space-between'}}>
            <span>{this.props.inter_route.message}</span>
            <span style={{color:'teal'}}>#{this.props.inter_route.data}</span>
            </span>
            </Modal.Header>
            <Modal.Content scrolling>
            <Responsive  maxWidth={768}>
            <Breadcrumb>
            {this.orderSteps(this.state.last_order.status).map(st=>(
                <>
                <Breadcrumb.Section style={{textTransform:'Capitalize',color:st.active == 1 ? 'teal':'grey'}} link>
                {this.statusValueToText(st.step)}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                </>
            ))}
            </Breadcrumb>
            </Responsive>
            <Responsive minWidth={768}>
            <Step.Group stackable fluid wide size="small">
            {this.orderSteps(this.state.last_order.status).map(st=>(
                <Step primary active={st.active == 1}>
                <Step.Content >
                <Step.Title>
                <Icon name={st.icon}/>
                {this.statusValueToText(st.step)}
                </Step.Title>
                </Step.Content>
                </Step>
            ))}
            </Step.Group>
            </Responsive>
            </Modal.Content>
            <Modal.Content scrolling>
            {
                !this.state.fetching
                ?
                <>
                <div>
                <span className="lefts">
                <label className="order-labels">
                Placed By
                </label>
                {this.state.last_order.corp_account	|| 'N/A'}
                </span>
                <span className="rights">
                <label className="order-labels">
                Date
                </label>
                {this.state.last_order.date}
                </span>
                </div>
                <div>
                <span className="lefts">
                <label className="order-labels">
                Status
                </label>
                {this.statusValueToText(this.state.last_order.status)}
                </span>
                <span className="rights">
                <label className="order-labels">
                Next Date
                </label>
                {this.state.last_order.next_date}
                </span>
                </div>
                <div>
                <span className="lefts">
                <label className="order-labels">
                Requirement
                </label>
                {this.state.last_order.service}
                </span>
                </div>
                </>
                :
                <Loader style={{marginTop:'5vh'}} active inline='centered' />
            }
            </Modal.Content>
            </Modal>
            <Modal open={this.state.show_voucher} onClose={()=>{
                this.setState({
                    show_voucher:false,
                    data_row_voucher:false,
                    voucher_order_id:false,
                    voucher_name:false,
                    voucher_list:false,
                    voucher_redirect_to:false,
                    voucher_message:false
                })
            }}
            >
            <Modal.Content scrolling>
            {
                this.state.voucher_list && this.state.data_row_voucher
                ?
                <Button color="red" floated="right" onClick={()=>this.setState({data_row_voucher:false,show_voucher:'list'})}>Back To List</Button>
                :
                null
            }
            {
                this.state.voucher_list && (!this.state.data_row_voucher)
                ?
                <>
                <Table color="blue" key="blue" size="small" compact>
                <Table.Header>
                <Table.Row>
                {
                    this.state.tableFieldsVoucher.map((field) => (
                        <Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
                        {field.text}
                        {
                            this.state.data_rows_sort_order == "asc" ?
                            <Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                            :
                            <Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                        }
                        </Table.HeaderCell>
                    ))
                }
                </Table.Row>
                </Table.Header>

                <Table.Body>
                {
                    this.state.voucher_list.map((data_row, index) => (
                        <Table.Row className="data-row-table-row" onClick={() => this.onVoucherListSelect(data_row.id)}>
                        {
                            this.state.tableFieldsVoucher.map((field) => (
                                <Table.Cell>
                                {
                                    field.value == "ledger"
                                    ?
                                    data_row.customer_details ? data_row.customer_details.name : data_row.vendor_details.name
                                    :
                                    (field.type=='select'
                                    ?
                                    this.convertSelectToText(field.value, data_row[field.value])
                                    :
                                    data_row[field.value])
                                }
                                </Table.Cell>
                            ))
                        }
                        </Table.Row>
                    ))
                }
                </Table.Body>
                </Table>
                </>
                :
                null
            }
            {
                this.state.show_voucher == 'sale'
                ?
                <SaleView history={this.props.history} data_row={this.state.data_row_voucher}/>
                :
                null
            }
            {
                this.state.show_voucher == 'quotation'
                ?
                <QuotationView history={this.props.history} data_row={this.state.data_row_voucher}/>
                :
                null
            }
            {
                // this.state.show_voucher == 'purchase'
                // ?
                // <PurchaseView history={this.props.history} data_row={this.state.data_row_voucher}/>
                // :
                // null
            }
            {
                this.state.show_voucher == 'delivery_note'
                ?
                <DeleveryChallanView history={this.props.history} data_row={this.state.data_row_voucher}/>
                :
                null
            }
            {
                this.state.show_voucher == "NCY"
                ?
                <span style={{fontSize:'300%'}}>
                {this.state.voucher_message}
                </span>
                :
                null
            }
            </Modal.Content>
            </Modal>
            <Modal open={this.state.feedback} onClose={()=>{this.setState({feedback:false})}}>
            <Modal.Content>
            <h2>Service</h2>
            <Rating disabled size="huge" icon='star' rating={this.state.feedback.rating} maxRating={5} />
            <h2>Review</h2>
            {this.state.feedback.review}
            <h2>Sugesstions</h2>
            {this.state.feedback.sugesstions}
            {this.state.feedback.review || ''}
            </Modal.Content>
            </Modal>
            <Modal
            open={this.state.files.length}
            onClose={()=>this.setState({files:[]})}>
            <FileOrdersPreview files={this.state.files}/>
            </Modal>

            <Modal
            open={this.state.showListModal}
            onClose={() => {this.setState({showListModal:false})}}
            size='small' closeIcon>
                <Modal.Content>
                <h3>Create List.</h3>
                <table style={{width:'100%'}}>
                {
                    this.state.selected_order.list != undefined
                    ?
                    this.state.selected_order.list.map((list_element, index)=>(
                        <tr style={{width:'100%'}}>
                        <td style={{width:'50%'}}>
                        <Input fluid placeholder='Item Name' maxLenght="20" name="name" value={list_element.name} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
                        </td>
                        <td style={{width:'20%'}}>
                        <Input fluid type="number" placeholder='Quantity' name="qty" value={list_element.qty} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
                        </td>
                        <td style={{width:'20%'}}>
                        <Input fluid type="number" placeholder='Rate (Approx)' name="rate" value={list_element.rate} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
                        </td>
                        <td style={{width:'10%'}}>
                        <Button floated="right" color="red" icon="close" style={{display: (index > 0 ? "inline" : "none")}} onClick={() => this.addToList(false, index)}/>
                        </td>
                        </tr>
                    ))
                    :
                    null
                }
                </table>
                <Button floated="right" color="blue" onClick={() => this.addToList(true)}>Add More</Button>
                <Button floated="right" color="green" onClick={() => this.updateList()} loading={this.state.updatingOrder}>
                    {JSON.stringify(this.state.selected_order.list) != JSON.stringify(this.state.list_check) ? "Update" : "Close"}
                </Button>
                </Modal.Content>
            </Modal>

            <Modal open={this.state.showCompleteVerification} onClose={() => this.setState({showCompleteVerification: false, confirmationData: undefined, selected_order: {}})}>
                <Modal.Content>
                    <VerifyOrder confirmationData={this.state.orderConfirmationData} order_data={this.state.selected_order}/>
                </Modal.Content>
            </Modal>

            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    Contact:  (data) => dispatch(contactAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
    deployInterRouteMessage: (data) => dispatch(InterRouteCommunication(data)),
    setAppData: (data) => dispatch(appDataAction(data))

})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
