import React from 'react';
import '../styles/top-topbar.css'
import { Container,Button ,Form,Card,Icon,Modal ,Loader,Input,Select, Dropdown, GridColumn } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'

import { BrowserRouter,Router,withRouter,  Link,Route, Switch } from 'react-router-dom';
import Client from '../services/Client';
import Contact from './contact';

// import logo from './logo.png'
const logo = require('./logo.png')
class PagesLink extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            toggleState : 1,
            visibleToggleables : '',
            mode:'login',
            allowed_routes:{
                user:['/','/home','/orders','/invoice','/account','/company','/gst-reports','/quotation','/logout','/contract','/delivery'],
                admin:['/','/home','/orders','/invoice','/account','/company','/users','/gst-reports',,'/quotation','/logout','/contract','/delivery']
            }
        }
        this.myRef = React.createRef();
    }

    render(){
        return(
            <div className="top-topbar-container">
                <div className="up-links">
                    <a target="blank" href="/blog">Blog</a>
                    <a target="blank" href="/about">About Us</a>
                    <a target="blank" href="/business">Seller</a>
                    <a target="blank" href="/franchise">Franchise</a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
...state
})
const mapDispatchToProps = dispatch => ({
setAppData: (data) => dispatch(appDataAction(data)),
newDataRowAction: (data) => dispatch(newDataRowAction(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PagesLink));
