import React from 'react';
import { Loader, Table, Responsive, Segment, Rating, TextArea, Modal, Step, Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Label, Message } from 'semantic-ui-react'
import "../styles/home.css"
import "../styles/feedback.css"
import "../styles/orders.css"
import FileOrdersPreview from './file-order-preview';
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
import Client from '../services/Client';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import Navlinks from './Navlinks'
class VerifyOrder extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            files: [],
            order_data: props.order_data || {},
            option_rating:[
                {value:'1',text:"1"},
                {value:'2',text:"2"},
                {value:'3',text:"3"},
                {value:'4',text:"4"},
                {value:'5',text:"5"},
            ],
            redirect_to:[
                {text:'Sale',value:'/accounting/vouchers/invoice/create',key:'sale'},
                {text:'Quotation',value:'/accounting/vouchers/quotation/create',key:'quotation'},
                // {text:'Purchase',value:'/accounting/vouchers/purchase/create',key:'purchase'},
                {text:'Delivery Challan',value:'/accounting/vouchers/challan/create',key:'delivery_note'},
            ],
            feedback_status_criteria:['payment','feedback','completed'],
            checkbox: props.confirmationData ? JSON.parse(props.confirmationData.checkbox) : [true, true, true],
            rating: props.confirmationData ? props.confirmationData.rating : 0,
            review: props.confirmationData ? props.confirmationData.review : '',
            confirmationRecorded: props.confirmationData ? (props.confirmationData.verification_status == "verified" ? true : false) : false,
            regenerateOtpTime: 25,
            disableRegenerateOTP: true,
            urlToken: props.confirmationData ? props.confirmationData.token : null
        }
    }

    componentDidMount(){
        if(this.state.confirmationRecorded == false){
            let urlToken = this.state.urlToken || window.location.pathname.split('/').pop()
            Client.getOrderForVerification(null,null,null,urlToken, '').then(res=>{
                if (res.responseResult ==  "failure") {
                    this.setState({error:true,error_message:"Page doesn't exist or some other error occurred. Please go to homepage."})
                }
                else if (res.data_rows.length) {
                    var order = res.data_rows[0]
                    var listDisplay = []
                    if(order.list != undefined && order.list != null)
                    {
                        var list = JSON.parse(order.list)
                        list.forEach(function(listElement, index)
                        {
                            if(listElement.name != undefined && listElement.name.trim() != '')
                            listDisplay.push((listElement.name + " (Rs." + listElement.rate + ") - " + listElement.qty))
                        })
                    }
                    order.listDisplay = listDisplay

                    if(order.confirmation.verification_status == "verified"){
                        this.setState({
                            checkbox: JSON.parse(order.confirmation.checkbox),
                            rating: order.confirmation.rating,
                            review: order.confirmation.review,
                            otpMode: false,
                            otpMessage: res.message,
                            sendingConfirmation: false,
                            confirmationRecorded: true,
                            order_data: order
                        })
                    }
                    else{
                        this.setState({order_data: order})
                    }
                }
            })
        }
    }

    orderSteps(status){
        let formated_steps = []
        let steps = this.state.steps
        for (const step in steps) {
            if (steps[step].stages.includes(status)) {
                formated_steps.push({
                    step:step,
                    active:1,
                    icon:steps[step].icon
                })
            } else {
                formated_steps.push({
                    step:step,
                    active:0,
                    icon:steps[step].icon
                })
            }
            console.log(steps[step]);
        }

        return formated_steps
    }

    changeStateVariable(e, data){
        var state = {...this.state}
        if(data.name.includes("checkbox")){
            var checkbox = [...state.checkbox]
            checkbox[data.name.slice(-1)] = data.checked
            state.checkbox = checkbox
        }
        else{
            state[data.name] = data.rating || data.value || e.target.value
        }
        this.setState(state)
    }

    setContactFormData(e,data){
        let new_contact_row = this.state.new_contact_row
        new_contact_row[data.name] = data.value
        this.setState({new_contact_row})
    }

    sendConfirmation(e, data){
        if(this.state.confirmationRecorded == false){
            this.setState({sendingConfirmation:true, otp: (e.target.name=="regenerateOtp" ? undefined : this.state.otp)})
            let urlToken = this.state.urlToken || window.location.pathname.split('/').pop()
            Client.verifyOrderWithKey(null, null, null, urlToken, JSON.stringify(this.state.checkbox), this.state.rating, this.state.review, (e.target.name=="regenerateOtp" ? undefined : this.state.otp)).then(res=>{
                if (res.responseResult == "failure") {
                    this.setState({otpMode: true, otpMessage: res.message, sendingConfirmation: false})
                } else if(res.responseResult == "success" && res.response == "otpSent") {
                    this.setState({otp: '', otpMode: true, otpMessage: res.message, sendingConfirmation: false})
                    var timeleft = 25;
                    var downloadTimer = setInterval(function(){
                        if(timeleft <= 0){
                            this.setState({regenerateOtpTime: "Regenerate OTP", disableRegenerateOTP: false})
                            clearInterval(downloadTimer);
                        }
                        else{
                            timeleft -= 1;
                            this.setState({regenerateOtpTime: timeleft, disableRegenerateOTP: true})
                        }
                    }.bind(this), 1000);
                } else if(res.responseResult == "success" && res.response == "confirmationRecorded") {
                    this.setState({otpMode: false, otpMessage: res.message, sendingConfirmation: false, confirmationRecorded: true})
                }
            })
            .catch(err=>{
                this.setState({message:'Something went Wrong, Kindly contact +91-9954683549',sendingConfirmation: false})
            })
        }
    }

    render(){
        return(
        <>
            <div style={{padding: '2% 10% 2% 10%'}}>
                <Message style={{display:this.state.error ? 'block':'none'}} >
                    <Message.Header>
                        {this.state.error_message}
                    </Message.Header>
                </Message>
                <Message style={{display:(this.state.message || this.state.done) ? 'block':'none'}} >
                    <Message.Header>
                        {this.state.message}
                    </Message.Header>
                </Message>
                {
                Object.keys(this.state.order_data).length > 0
                ?
                <Card fluid>
                    <Card.Content>
                    <div className="lines">
                        <span className="lefts">
                            <b> #{this.state.order_data.slip_no}</b>
                        </span>
                        <Responsive minWidth={768}>
                            <span className="rights">
                            <Step.Group stackable  >
                            {
                                this.orderSteps(this.state.order_data.status).map(st=>(
                                    <Step primary  active={st.active == 1}>
                                        <Step.Content >
                                            <Step.Title>
                                            {
                                                st.active == 1
                                                ?
                                                this.state.order_data.status.toUpperCase()
                                                :
                                                this.statusValueToText(st.step)
                                            }
                                            </Step.Title>
                                        </Step.Content>
                                    </Step>
                                ))}
                            </Step.Group>
                            </span>
                        </Responsive>
                        <Responsive maxWidth={768}>
                            <span className="rights">
                                <label className="order-labels">
                                    Status
                                </label>
                                    {this.state.order_data.current_status}
                            </span>
                        </Responsive>
                    </div>
                    <div className="lines">
                        <Responsive minWidth={768}>
                            <span className="rights">
                                <label className="order-labels">
                                    Status
                                </label>
                                {this.state.order_data.current_status}
                            </span>
                        </Responsive>
                        <Responsive maxWidth={768}>
                            <span className="rights">
                                <label className="order-labels">
                                    Date
                                </label>
                                {this.state.order_data.slip_date}
                            </span>
                        </Responsive>
                    </div>
                    <div className="lines">
                        <span className="lefts">
                            <label className="order-labels">
                                Placed By
                            </label>
                            {this.state.order_data.corp_account	|| 'N/A'}
                        </span>
                        <Responsive minWidth={768}>
                            <span className="rights">
                                <label className="order-labels">
                                    Date
                                </label>
                                {this.state.order_data.slip_date}
                            </span>
                        </Responsive>
                        <Responsive maxWidth={768}>
                            <span className="rights">
                                <label className="order-labels">
                                    Next
                                </label>
                                {this.state.order_data.next_date}
                            </span>
                        </Responsive>
                    </div>
                    <div className="lines">
                        <span className="lefts">
                            <label className="order-labels">
                                Requirement
                            </label>
                            {this.state.order_data.requirement}
                        </span>
                        <Responsive minWidth={768}>
                            <span className="rights">
                                <label className="order-labels">
                                    Next
                                </label>
                                {this.state.order_data.next_date}
                            </span>
                        </Responsive>
                    </div>
                    <div className="lines">
                        <span className="lefts">
                            {/* <Message onClick={() => {this.setState({showListModal:true, selected_order: order, list_check: this.state.order_data.list})}}
                            style={{cursor:'pointer', display: (this.state.order_data.listDisplay != undefined && this.state.order_data.listDisplay != '') ? 'block' : 'none'}} list={this.state.order_data.listDisplay}/> */}
                        </span>
                    </div>
                    </Card.Content>
{
                    // <div className="lines">
                    //     <div className="lefts">
                    //     <Button
                    //         size="tiny"
                    //         onClick={()=>{this.setState({files:JSON.parse(this.state.order_data.files)})}}>
                    //         Attachments
                    //     </Button>
                    //     </div>
                    //     <div className="rights">
                    //         {
                    //         this.state.feedback_status_criteria.includes(this.state.order_data.status)
                    //         ?
                    //             <Button
                    //             size="tiny"
                    //             className="ui blue"
                    //             color="blue"
                    //             onClick={()=>{
                    //             if(order['rating'])
                    //             this.setState({feedback:{review:this.state.order_data.review,rating:this.state.order_data.rating,sugesstions:this.state.order_data.suggestions}})
                    //             else
                    //             this.props.history.push('/feedback/'+this.state.order_data.slip_no+'/'+(this.state.order_data.feedback_token?this.state.order_data.feedback_token.toString():'') )}}>
                    //                 Feedback
                    //             </Button>
                    //         :
                    //         null
                    //         }
                    //     </div>
                    // </div>
                    // </Card.Content>
                    // <Card.Content extra>
                    //     <span className="card-bottom">
                    //         <span className="dsk-btns" onClick={() => {this.toggleCommentsOf(this.state.order_data.slip_no)}}>
                    //         <Icon size="small" name={this.state.show_comments_of == this.state.order_data.slip_no?"up arrow":'down arrow'}/>
                    //             Comments
                    //         </span>
                    //         <span className="dsk-btns" onClick={() => {this.toggleContactsOf(this.state.order_data.slip_no)}} style={{float:'right'}} >
                    //         <Icon size="small" name={this.state.show_contacts_of == this.state.order_data.slip_no?"up arrow":'down arrow'}/>
                    //             Contacts
                    //         </span>
                    //     </span>
                    // </Card.Content>
}
                    {
                    this.state.show_comments_of == this.state.order_data.slip_no
                    ?
                    <Card.Content>
                        <div className="comment-container">
                        {
                        this.state.fetching_comments
                        ?
                        <Loader loading={true} size="small"/>
                        :
                        this.state.comments.map((comment)=>(
                            <>
                                <div className="badge-container">
                                    {this.checkShortHandsInComment(comment.comment)}
                                </div>
                                <div className="comment">
                                    <div className="comment-head">
                                        <span className="comment-head-name">{comment.commenter}</span>
                                        <span className="comment-head-date">{comment.date}</span>
                                    </div>
                                    <div className="comment-body">
                                        {comment.comment}
                                    </div>
                                </div>
                            </>
                        ))
                        }
                        </div>
                        <div className="comment-input">
                            <Input
                            icon={<Icon onClick={()=>this.postNewComment()} name='paper plane' inverted circular link />}
                            fluid wide
                            loading={this.state.sending_comment}
                            name="new_comment"
                            placeholder='Ask Here...'
                            value={this.state.new_comment}
                            onChange={(e,data)=>this.setState({[data.name]:data.value})}/>
                        </div>
                    </Card.Content>
                    :
                    null
                    }
                    {
                    this.state.show_contacts_of === this.state.order_data.slip_no
                    ?
                    <Card.Content>
                        {
                        this.state.order_data.contacts.length > 0
                        ?
                        <Table celled compact unstackable>
                            <Table.Header >
                                <Table.Row  className="contactRows">
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Phone</Table.HeaderCell>
                                    <Table.HeaderCell>E-mail</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    // distinct_types.map(type=>{
                                    // return(
                                    //     <>
                                    //         <Table.Row textAlign="center" className="contactRows" fullWidth>
                                    //             <Table.Cell colSpan={3} className="typeTitle">{type}</Table.Cell>
                                    //         </Table.Row>
                                    //         {
                                    //             this.state.order_data.contacts.map((contact)=>(
                                    //                 contact.type == type
                                    //                 ?
                                    //                 <Table.Row className="contactRows" >
                                    //                     <Table.Cell>{contact.name}</Table.Cell>
                                    //                     <Table.Cell>{contact.phone}</Table.Cell>
                                    //                     <Table.Cell>{contact.email}</Table.Cell>
                                    //                 </Table.Row>
                                    //                 :
                                    //                 null
                                    //             ))
                                    //         }
                                    //     </>
                                    // )
                                    // })
                                }
                            </Table.Body>
                        </Table>
                        :
                        <Card.Meta>
                            *No Contacts Yet
                        </Card.Meta>
                        }
                    </Card.Content>
                    :
                    null
                    }

                </Card>
                :
                null
                }
                {
                Object.keys(this.state.order_data).length > 0
                ?
                <Segment>
                    <Label as='a' color={this.state.confirmationRecorded ? 'green' : 'red'} ribbon>
                        {this.state.confirmationRecorded ? "Confirmation Recorded. THANK YOU." : "Please Provide Confirmation and Review"}
                    </Label>
                    <br/><br/>
                    <Checkbox disabled={this.state.confirmationRecorded} name="checkbox_0" label='Received materials as per list.' checked={this.state.checkbox[0]} onChange={(e, data) => this.changeStateVariable(e, data)}/>
                    <br/>
                    <Checkbox disabled={this.state.confirmationRecorded} name="checkbox_1" label='All materials are in good conditions.' checked={this.state.checkbox[1]} onChange={(e, data) => this.changeStateVariable(e, data)}/>
                    <br/>
                    <Checkbox disabled={this.state.confirmationRecorded} name="checkbox_2" label='Proper installation or service done (If Applicable).' checked={this.state.checkbox[2]} onChange={(e, data) => this.changeStateVariable(e, data)}/>
                    <br/><br/>
                    <Label>Rating</Label><br/><Rating disabled={this.state.confirmationRecorded} name="rating" size="huge" icon='star' defaultRating={0} maxRating={5} rating={this.state.rating} onRate={(e, data) => this.changeStateVariable(e, data)}/>
                    <br/><br/>
                    <Form><TextArea disabled={this.state.confirmationRecorded} fluid name="review" placeholder='Remarks / Review' value={this.state.review} onChange={(e, data) => this.changeStateVariable(e, data)}/></Form>
                    <br/>
                    {
                        this.state.otpMode
                        ?
                        <div>
                            <Input name="otp" value={this.state.otp} onChange={(e, data) => this.changeStateVariable(e, data)} placeholder="Enter OTP"/>
                            <Label>{this.state.otpMessage}</Label>
                        </div>
                        :
                        null
                    }
                    <Button disabled={this.state.confirmationRecorded} color="blue" loading={this.state.sendingConfirmation} onClick={(e, data) => this.sendConfirmation(e, data)}>Verify</Button>
                    {
                        this.state.otpMode
                        ?
                        <Button disabled={this.state.disableRegenerateOTP} name="regenerateOtp" color="blue" loading={this.state.sendingConfirmation} onClick={(e, data) => this.sendConfirmation(e, data)}>{this.state.regenerateOtpTime}</Button>
                        :
                        null
                    }
                </Segment>
                :
                null
                }
            </div>
            <Modal open={this.state.files.length}
            onClose={()=>this.setState({files:[]})}>
                <FileOrdersPreview files={this.state.files}/>
            </Modal>
        </>
        )
    }
}
const mapStateToProps = state => ({
    ...state
   })
  const mapDispatchToProps = dispatch => ({
    // newDataRowAction: (data) => dispatch(newDataRowAction(data)),

   })
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyOrder));
