import React from 'react';
import {connect } from 'react-redux';
import {newDataRowAction } from '../actions/newDataRowAction';
import {contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import Client from '../services/Client';
import { NavLink, withRouter } from "react-router-dom";
import ContactForm from './contact-form';

import { Button, Container, Menu, Form, Card, Icon, Modal, Loader, Input, Select, Dropdown, GridColumn } from 'semantic-ui-react'

import DehazeIcon from '@material-ui/icons/Dehaze';

import '../styles/topbar.css'

class Topbar extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            allowed_routes:{
                user:['/','/home','/orders','/invoice','/account','/company','/gst-reports','/quotation','/logout','/contract','/delivery'],
                admin:['/','/home','/orders','/invoice','/account','/company','/users','/gst-reports',,'/quotation','/logout','/contract','/delivery']
            }
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

        this.myRef = React.createRef();
    }
	componentDidMount() {
	}

    login(){
        this.setState({loading:1})
        Client.login(this.state.username,this.state.key,this.props.app_data.user_details.reg_id).then((res)=>{
            if (res.responseResult == 'success') {
                let userdata = {username:this.state.username,key:this.state.key,reg_id:this.props.app_data.reg_id}
                let user_obj = {
                    name : res.name,
                    username : res.username,
                    city: res.city,
                    role : res.role,
                    email : res.email,
                    phone  : res.phone,
                    company : res.company.name,
                    company_id : res.company.id,
                    branches : res.company.branches,
                    designation : res.designation,
                    token : res.token,
                    reg_id : this.props.app_data.user_details.reg_id,
                    login : true,
                    approval_required:res.approval_required,
                    approvers:JSON.parse(res.approvers)
                }
                if ( Object.entries(this.props.new_data_row).length == 0 ) {
                    this.props.newDataRowAction({list:[{name:''}]})
                }
                let options_branches= user_obj.branches.map(branch =>{
                    return {text:branch.branch_name,value:branch.id,city:branch.city}
                })
                user_obj['options_branches'] = options_branches

                this.props.setAppData({...this.props.app_data,user_details:user_obj,showLoginModal:false})
                localStorage.setItem('user_details',JSON.stringify(user_obj))

                this.setState({showLoginModal:false,loading:0})

                this.props.history.push('/')

                let new_data_row = this.props.new_data_row
                new_data_row["branch"]=user_obj.options_branches[0]["value"]
                console.log(user_obj.options_branches[0]["value"]);
                this.props.newDataRowAction(new_data_row)

                if (user_obj.approval_required) {
                    let approvers = user_obj.approvers
                    Client.getCompanyUsers(user_obj.token,user_obj.reg_id,user_obj.username,null,null,{account_id:approvers},"rehydrate").then(res=>{
                        let users = res.data_rows.map(user=>{
                            return {value:user.account_id,text:user.name}
                        })
                        user_obj['options_approvers'] =users
                        this.props.setAppData({...this.props.app_data,user_details:user_obj})
                    })
                }
            } else {
                this.setState({err_message:"Invalid Credentials",loading:0})
            }
        })
    }

    showLoginModal(e){
        e.preventDefault();
        this.props.setAppData({...this.props.app_data,showLoginModal:true,login_mode:"login"})
        setTimeout(()=>{
        if (this.myRef.current) {
            this.myRef.current.focus()
            this.props.history.push('/login')
        }
        },200)
    }

    closeModal(){
        if (this.props.app_data.login_mode == "query") {
            this.props.newDataRowAction({list:[{name:''}]})
        }
        this.props.setAppData({...this.props.app_data,showLoginModal:false,login_mode:"login"})
        this.setState({mode:'login',err_message:false})
    }

    switchAuthMode(mode){
        this.props.setAppData({...this.props.app_data,login_mode:mode})
    }

    changeLoginData(data){
        this.setState({[data.target.name]:data.target.value})
    }

    onEnterPress(data){
        if (data.target.name == "key") {
            if (data.which == 13 || data.key == 13) {
                this.login()
            }
        }
    }

    showTogleablesOf(e,masterLink){
        e.preventDefault()
        let visibleToggleables = this.props.app_data.visibleToggleables
        if (visibleToggleables == masterLink) {
            visibleToggleables = ''
        } else {
            visibleToggleables = masterLink
        }
        this.props.setAppData({...this.props.app_data,visibleToggleables:visibleToggleables})
    }

    render(){
        let allowed_routes= this.state.allowed_routes[this.props.app_data.user_details.role]

        return(
            <div className="topbar-main">
                <div className="sidebar-trigger">
                    <DehazeIcon onClick={() => this.props.toggleSidebar()}/>
                </div>
                <div className="topbar-logo">
                    <a href="/">
                        <img alt="logo" className="brand-image" src="/images/deskneed.png"/>
                    </a>
                </div>
                {
                    this.props.app_data.user_details.login == true
                    ?
                    <Menu text className="topbar-navigation">
                        <NavLink style={{display:allowed_routes.includes('/orders')?'flex':'none'}} activeClassName="active" className="nav-link" to="/orders">Orders</NavLink>
                        <Dropdown item simple text="Vouchers">
                            <Dropdown.Menu style={{marginTop: 0}}>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/invoice')?'flex':'none'}} activeClassName="active" className="nav-link" to="/invoice">Invoices</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/quotation')?'flex':'none'}} activeClassName="active" className="nav-link" to="/quotation">Quotations</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/contract')?'flex':'none'}} activeClassName="active" className="nav-link" to="/contract">Contract</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/delivery')?'flex':'none'}} activeClassName="active" className="nav-link" to="/delivery">Delivery Note</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown item simple text="Account">
                            <Dropdown.Menu style={{marginTop: 0}}>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/invoice')?'flex':'none'}} activeClassName="active" className="nav-link" to="/account">
                                        Details
                                    </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/company')?'flex':'none'}} activeClassName="active" className="nav-link" to="/company">
                                        Company
                                    </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/users')?'flex':'none'}} activeClassName="active" className="nav-link" to="/users">
                                        Users
                                    </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/logout')?'flex':'none'}} activeClassName="active" className="nav-link" to="/logout">Logout</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <NavLink style={{display:allowed_routes.includes('/gst-reports')?'flex':'none'}} activeClassName="active" className="nav-link" to="/gst-reports">GST-Reports</NavLink>
                        <a className="nav-link" href="https://fix.deskhub.in" style={{alignSelf:'flex-end'}}>
                            <img src="/images/deskfix.png"/>
                        </a>
                    </Menu>
                    :
                    <div className="topbar-navigation">
                        <a href="https://deskhub.in">Home</a>
                        <NavLink activeClassName="active" className="nav-link" to="/about">About</NavLink>
                        <NavLink activeClassName="active" className="nav-link" to="/seller">Seller</NavLink>
                        <NavLink activeClassName="active" className="nav-link" to="/franchisee">Franchisee</NavLink>
{
                        // <NavLink activeClassName="active" className="nav-link" to="/blog">Blog</NavLink>
}
                        <a className="nav-link" href="https://fix.deskhub.in" style={{alignSelf:'flex-end'}}>
                            <img src="/images/deskfix.png"/>
                        </a>
                    </div>
                }
                <div className={"topbar-buttons" + (this.props.app_data.user_details.login == true ? ' hide' : '')}>
                    <Button variant="contained" color="primary" size="medium" onClick={(e)=>{this.showLoginModal(e)}}>Login</Button>
                </div>

                <Modal size="small" open={this.props.app_data.showLoginModal} onClose={()=>{this.closeModal()}}>
                    <Modal.Content scrolling>
                        {
                            this.state.err_message
                            ?
                            <div>
                                <Icon name="stop"/>
                                {this.state.err_message}
                            </div>
                            :
                            null
                        }
                        {
                            this.props.app_data.login_mode == "login"
                            ?
                            <span>
                                <Form.Field name="username" style={{marginBottom:'3%',marginTop:'3%'}}>
                                    <Input ref={this.myRef} fluid wide onChange={(event)=>{this.changeLoginData(event)}} name="username" placeholder="Username" icon="user" />
                                </Form.Field>
                                <Form.Field name="key" style={{marginBottom:'3%'}}>
                                    <Input fluid wide type="password" onKeyPress={(e)=>this.onEnterPress(e)} onChange={(event)=>{this.changeLoginData(event)}} name="key" placeholder="Key" icon="key" />
                                </Form.Field>
                                <Button icon color="blue" fluid wide
                                loading={this.state.loading}
                                onClick={()=>{this.login() }}>
                                    <Icon name='lock' />
                                    Login
                                </Button>
                                <Button className="alternate" fluid wide onClick={(e)=>{this.switchAuthMode('signup')}}>
                                    Need an account?
                                </Button>
                            </span>
                            :
                            <span>
                                <h4 style={{display:'inline'}}>Have an account? </h4><Button size="tiny" className="top-right-login" color="blueviolet" onClick={(e)=>{this.switchAuthMode('login')}}>Login</Button>
                                <ContactForm query_category="Make a Purchase" category_disabled={true} header="Query for Booking" style={{marginLeft:'50%', transform: 'translateX(-50%)'}}/>
                            </span>
                        }
                    </Modal.Content>
                </Modal>
            </div>
		)
    }
}

const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    setAppData: (data) => dispatch(appDataAction(data)),
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));
