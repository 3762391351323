import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import { newDataRowAction } from '../actions/newDataRowAction';
import {appDataAction} from '../actions/appDataAction'

import {Button, TextField, FormControl, FormGroup, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core';
// import {Form} from 'semantic-ui-react'

import '../styles/section_contact.css'

class SectionContact extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            options_query_category:[
                {text: 'General Question', value: 'General Question'},
                {text: 'Make a Purchase', value: 'Make a Purchase'},
                {text: 'Support', value: 'Support'},
                {text: 'Seller Partnership', value: 'Seller Partnership'},
                {text: 'Become a Franchise', value: 'Become a Franchise'},
            ],
            form_data:{
                query_category: props.query_category || 'General Question',
                category_disabled: props.category_disabled || false
            }
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
        if (this.props.app_data.login_mode == "query") {
            let new_contact_row = this.state.form_data
            let queryObject = this.props.new_data_row
            let service = this.props.option_services.find(elem=>{return elem.value == queryObject.service_category})
            let service_name = service.text
            let city = queryObject.city
            let requirement = queryObject.requirement
            let service_time = queryObject.service_time
            let subscription_type = queryObject.subscription_type
            let description = queryObject.description
            new_contact_row["query"] = `query for service ${service_name} ${description?'with subscription type: '+description:''}`+
                                        `, expected timing: ${service_time}, addition details ${requirement}`
            this.setState({new_contact_row})
        }
	}

    setContactFormData(e,data){
        // console.log(e)
        let form_data = this.state.form_data

        // this.setState({error_feilds:this.state.error_feilds.filter(elem => elem!=data['name'])})
        form_data[e.target.name || data.name] = e.target.value || data.value
        this.setState({form_data})
    }
    sendRequest(){
        let data = new FormData()
        let user_details = this.props.app_data.user_details
        let new_data_row = this.state.form_data
        new_data_row.files = []
        // this.props.app_data.files.forEach((file,i)=>{
        //     data.append('image'+i,file.file,file.file.name)
        //     console.log("test",file);
        //     // new_data_row.files.push('image'+i)
        //     new_data_row.files.push({file_name:file.file.name,file_key:'image'+i})
        // })
        // for (var key of data.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }
        if (this.validator(["name","phone"],new_data_row)) {
            this.setState({loading:true})

            Client.createContactRequest(user_details.token,user_details.reg_id,user_details.username,new_data_row,data)
            .then(res=>{
                this.setState({
                    message:false,
                    form_data:{
                        query_category: this.props.query_category || 'General Question',
                        category_disabled: this.props.category_disabled || false
                    },
                    query_submitted: true
                })
                this.props.newDataRowAction({list:[{name:''}]})
                this.props.setAppData({...this.props.app_data,files:[],show_preview:false})
                setTimeout(function(){ this.setState({query_submitted: false}) }.bind(this), 5000);
            })
            .catch(err=>{
                this.setState({message:"Request Not Sent",loading:false})
                setTimeout(()=>{
                    this.setState({message:false})
                },7000)
            })
        } else {
            console.log("validation failed for contact form.")
        }
    }

    validator(keys_array,target_obj){
        let error_feilds = []
        // console.log(this.props.app_data.user_details.approval_required != 1);

        keys_array.forEach(key => {
        if ( target_obj[key] == '' || target_obj[key] == null || target_obj[key] == undefined || target_obj[key] == 'undefined') {
        console.log("key :" ,key,"value",target_obj[key]);

        error_feilds.push(key)
        }
        });
        this.setState({error_feilds},()=>{
        })
        if (error_feilds.length) {
        // console.log(error_feilds);
        //some errors send false
        return false
        } else {
        // console.log(error_feilds,"pass");
        //0 errors send true
        return true
        }
    }

    render(){
        return(
            <div>
            {
                this.props.header
                ?
                <p style={{fontWeight: "bold", fontSize: "x-large", textAlign:'center'}}>{this.props.header || 'Lets get in touch'}</p>
                :
                null
            }
            {
                this.state.query_submitted
                ?
                <div className="contact-form" style={this.props.style}>
                    <h3 style={{padding: '5vw 0'}}>
                    Thank you for your interest.
                    <br/>
                    We will contact you back soon.
                    </h3>
                </div>
                :
                <FormControl className="contact-form" style={this.props.style}>
                    <div>
                        <TextField id="standard-basic" label="Full Name" placeholder="Your Name"
                        InputLabelProps={{shrink: true}} style={{width:'48%', marginRight: '1%'}}
                        value={this.state.form_data.name || ''}
                        name="name" onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                        <TextField id="standard-basic" label="Phone" placeholder="+91-99********"
                        InputLabelProps={{shrink: true}} style={{width:'48%', marginLeft: '1%'}}
                        value={this.state.form_data.phone || ''}
                        name="phone" onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                    </div>
                    <br/><br/>
                    <TextField id="standard-basic" label="Organisation" placeholder="Your Organisation"
                    InputLabelProps={{shrink: true}} fullWidth
                    value={this.state.form_data.organisation || ''}
                    name="organisation" onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                    <br/><br/>
                    <FormGroup>
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Query Related To
                        </InputLabel>
                        <Select
                        name="query_category" onChange={(e,data)=>{this.setContactFormData(e,data)}}
                        value={this.state.form_data.query_category}
                        displayEmpty>
                        {
                            this.state.options_query_category.map((option) => (
                                <MenuItem value={option.value}>{option.text}</MenuItem>
                            ))
                        }
                        </Select><br/><br/>
                    </FormGroup>
                    <TextField id="standard-basic" label="Type Your Message" placeholder="How can we help you?"
                    InputLabelProps={{shrink: true}} multiline rows="4" fullWidth
                    value={this.state.form_data.query || ''}
                    name="query" onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                    <br/><br/>
                    <Button variant="contained" color="primary" size="medium" type='submit' loading={this.state.loading}  onClick={()=>this.sendRequest()} >Schedule A Demo</Button>
                </FormControl>
            }
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    // newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    setAppData: (data) => dispatch(appDataAction(data)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionContact));
