import React from 'react';
import { Container,Button ,Form,Table,Card,Icon,Grid ,Checkbox,Input,Modal,Label, Dropdown, GridColumn,Responsive } from 'semantic-ui-react'
import "../styles/account.css"
import Sprinter from '../Components/Sprinter'
import SimpleMap from '../Components/Simplemap';
import { connect } from 'react-redux';
import {appDataAction} from '../actions/appDataAction'
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import Navlinks from './Navlinks'
import Footer from './footer';
import Client from '../services/Client';

class Company extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            observed_branch:false,
            table_fields:[
                {value:'branch_name',text:'Name',type:'field'},
                {value:'city',text:'City',type:'field'},
                {value:'contact_person',text:'Primary Contact Person',type:'field'},
            ],
            new_data_row:{},
            options_pos: [
				{text: "1 - JAMMU AND KASHMIR", value: 1},
				{text: "2 - HIMACHAL PRADESH", value: 2},
				{text: "3 - PUNJAB", value: 3},
				{text: "4 - CHANDIGARH", value: 4},
				{text: "5 - UTTARAKHAND", value: 5},
				{text: "6 - HARYANA", value: 6},
				{text: "7 - DELHI", value: 7},
				{text: "8 - RAJASTHAN", value: 8},
				{text: "9 - UTTAR PRADESH", value: 9},
				{text: "10 - BIHAR", value: 10},
				{text: "11 - SIKKIM", value: 11},
				{text: "12 - ARUNACHAL PRADESH", value: 12},
				{text: "13 - NAGALAND", value: 13},
				{text: "14 - MANIPUR", value: 14},
				{text: "15 - MIZORAM", value: 15},
				{text: "16 - TRIPURA", value: 16},
				{text: "17 - MEGHLAYA", value: 17},
				{text: "18 - ASSAM", value: 18},
				{text: "19 - WEST BENGAL", value: 19},
				{text: "20 - JHARKHAND", value: 20},
				{text: "21 - ODISHA", value: 21},
				{text: "22 - CHATTISGARH", value: 22},
				{text: "23 - MADHYA PRADESH", value: 23},
				{text: "24 - GUJARAT", value: 24},
				{text: "25 - DAMAN AND DIU", value: 25},
				{text: "26 - DADRA AND NAGAR HAVELI", value: 26},
				{text: "27 - MAHARASHTRA", value: 27},
				{text: "28 - ANDHRA PRADESH(BEFORE DIVISION)", value: 28},
				{text: "29 - KARNATAKA", value: 29},
				{text: "30 - GOA", value: 30},
				{text: "31 - LAKSHWADEEP", value: 31},
				{text: "32 - KERALA", value: 32},
				{text: "33 - TAMIL NADU", value: 33},
				{text: "34 - PUDUCHERRY", value: 34},
				{text: "35 - ANDAMAN AND NICOBAR ISLANDS", value: 35},
				{text: "36 - TELANGANA", value: 36},
				{text: "37 - ANDHRA PRADESH (NEW)", value: 37},
			],
        }
        this.rehydrate = this.rehydrate.bind(this)
        window.re = this.rehydrate
    }
    componentDidMount(){

    }
    rehydrate(){
        let user_details = JSON.parse(localStorage.getItem('user_details'))
            if (user_details) {
              if (user_details.hasOwnProperty('login')) {
                if (user_details.login == true) {
                  Client.getUserDetails(user_details.token,user_details.username,user_details.reg_id).then((res)=>{
                    this.setState({status:'Fetching User Data ...'})
                    if (res.responseResult == 'success') {
                      let user_obj = {
                        name : res.name,
                        username : res.username,
                        city: res.city,
                        role : res.role,
                        email : res.email,
                        phone  : res.phone,
                        company : res.company.name,
                        branches : res.company.branches,
                        designation : res.designation,
                        token : user_details.token,
                        login : true,
                        company_id : res.company.id,
                        reg_id : user_details.reg_id,
                        // allowed_routes: this.state.allowed_routes[res.role],
                        approval_required:res.approval_required,
                        approvers:JSON.parse(res.approvers)
                      }
                      // if(user_obj.city != "All"){
                      //   let user_cities = user_obj.city.map(city_name=>{
                      //     optionCities.push({text:city_name,value:city_name})
                      //   })
                      //   this.props.optionCities(user_cities)
                      // }
                    //   let allowed_routes = user_obj.allowed_routes
                    //   console.log("location",window.location.pathname.split('/'))
                    //   if (!allowed_routes.includes(window.location.pathname) && !allowed_routes.includes(window.location.pathname.split('/')[0])) {
                        // window.location.href = '/'
                    //   }
                    user_obj = {...this.props.app_data.user_details,user_obj}
                      let options_branches= user_obj.branches.map(branch =>{
                        return {text:branch.branch_name,value:branch.id,city:branch.city}
                      })
                      user_obj['options_branches'] = options_branches
                      user_obj['options_approvers'] =[]
                      this.props.setAppData({...this.props.app_data,user_details:user_obj})
                    }
                  })
                }
            }
        }

    }
    consoleDataChange(e,data){
        console.log(data);
        let observed_branch =[]
        if(data.name == 'observed_branch'){
            observed_branch = this.props.app_data.user_details.branches.filter(branch=>{
                console.log(data.value,branch.id);

                return branch.id == data.value
            })

            this.setState({[data.name]:data.value,observed_branch:observed_branch[0]})
        }
    }
    newDataRowChange(e,data){
        let new_data_row = this.state.new_data_row
        new_data_row[data.name]=data.value
        if (data.name=="GSTIN") {
			data.value = data.value.trim()
			let gst_code = data.value.slice(0,2)
			new_data_row['pos'] = gst_code
		}
        this.setState({new_data_row})
    }
    createNewBranch(  ){
        this.setState({createing_user:true})
        let new_data_row = this.state.new_data_row
        let user_details = this.props.app_data.user_details
        new_data_row['customer'] = user_details.company_id
        // let status = new_data_row.edit?'passed':this.validator(new_data_row,["name","corp_branch"])
        if (true) {
            // new_data_row['active'] = 1
            Client.createCompanyBranch(user_details.token,user_details.reg_id,user_details.username,new_data_row).then(res=>{
                this.setState({createing_user:false,showCreateBranchModal:false,error:false,new_data_row:{}})
                this.rehydrate()
                // this.getUsers()
            }).catch(err=>{
                this.setState({error:'please fill all fields'})
            })
        } else {
            this.setState({createing_user:false,error:'status'})
        }
    }
    validator(data,shouldHave){
        if ((data.key == data.confirm_key)&& data.key != undefined) {
            return 'passed'
        } else {
            return "Password did'nt matched"
        }
    }
    render(){
        let user =  this.props.app_data.user_details
        let company = this.props.app_data.user_details.company
        return(
            <>
            {
                this.props.app_data.user_details.login
                ?
                <Navlinks
                company={this.state.company_details}
                user={this.state.user_details}
                />
                :
                null
            }
            <Container className="account">
                <div className="user">
                    <h1 className="company-title"><Icon name="building"/>{company}</h1>
                    <hr></hr>
                    <Button color="black" icon="add" onClick={()=>this.setState({showCreateBranchModal:true})}><Icon name="add" /> Add</Button>
                <Grid stackable>
                        <Grid.Column only='large screen' className="branch-table" width={this.state.observed_branch?8:16}>
                        <Table celled>
                            <Table.Header>
                            <Table.Row>
                                {
                                    this.state.table_fields.map(field =>{
                                    return <Table.HeaderCell>{field.text}</Table.HeaderCell>
                                    })
                                }
                            </Table.Row>
                            </Table.Header>
                            <Table.Body >
                            {
                                    this.props.app_data.user_details.branches.map(branch=>{
                                        return (
                                            <Table.Row
                                                style={{backgroundColor:branch.id == this.state.observed_branch.id?'red':'white',cursor:'pointer'}}
                                                onClick={(e)=>{this.consoleDataChange(e,{name:'observed_branch',value:branch.id})}}
                                                >
                                                {
                                                    this.state.table_fields.map(field=>{
                                                        return <Table.Cell>{branch[field.value]}</Table.Cell>
                                                    })
                                                }
                                            </Table.Row>
                                        )
                                    })
                            }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Responsive maxWidth={1200}>
                            <Dropdown
                                fluid
                                name="observed_branch"
                                wide
                                selection
                                value={this.state.observed_branch.id}
                                style={{marginTop:'4vh',width:'100%'}}
                                options={user.options_branches}
                                onChange={(e,data)=>{this.consoleDataChange(e,data)}}
                                placeholder="Select a branch"
                                />
                    </Responsive>
                    <Grid.Column style={{display:this.state.observed_branch?'block':'none'}} width={8}>
                        <div className="detail">
                            <Label basic color="red"><Icon name="user"/>Name</Label>
                            <span className="value">{this.state.observed_branch.branch_name}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="road"/>Address</Label>
                            <span className="value address" >{this.state.observed_branch.address}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="star"/>GSTIN</Label>
                            <span className="value">{this.state.observed_branch.GSTIN}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="paper plane"/>E mail</Label>
                            <span className="value">{this.state.observed_branch.email}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="numbered list"/>PIN Code</Label>
                            <span className="value">{this.state.observed_branch.pincode}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="map marker"/>POS</Label>
                            <span className="value">{this.state.observed_branch.pos}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="map"/>City</Label>
                            <span className="value">{this.state.observed_branch.city}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="handshake"/>Contact</Label>
                            <span className="value">{this.state.observed_branch.contact_person}</span>
                        </div>
                    </Grid.Column>
                </Grid>
                </div>
            </Container>
            <Modal open={this.state.showCreateBranchModal} onClose={()=>{this.setState({showCreateBranchModal:false,})}}>
            <Modal.Header>Create Branch</Modal.Header>
                <Modal.Content>
                    {
                        this.state.error
                        ?
                        <h3>{this.state.error}</h3>
                        :null
                    }
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Branch Name</label>
                                <Input value={this.state.new_data_row.branch_name || ''} fluid placeholder='Branch Name' name="branch_name" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Suffix</label>
                                <Input value={this.state.new_data_row.name_suffix || ''} fluid placeholder='suffix' name="name_suffix" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Contact Person</label>
                                <Input value={this.state.new_data_row.contact_person || ''} fluid placeholder='Conatact Person' name="contact_person" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Input value={this.state.new_data_row.email || ''} fluid placeholder='Email' name="email" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>GSTIN</label>
                                <Input value={this.state.new_data_row.GSTIN || ''} fluid placeholder='GSTIN' name="GSTIN" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>POS</label>
                                <Form.Select options={this.state.options_pos} value={parseInt(this.state.new_data_row.pos) || ''} fluid placeholder='POS' name="pos" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Address</label>
                                <Input value={this.state.new_data_row.address || ''} fluid placeholder='Address' name="address" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>City</label>
                                <Form.Select options={this.props.option_cities} value={this.state.new_data_row.city || ''} fluid placeholder='City' name="city" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Pincode</label>
                                <Input value={this.state.new_data_row.pincode || ''} fluid placeholder='Pincode' name="pincode" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Phone</label>
                                <Input value={this.state.new_data_row.phone || ''} fluid placeholder='Phone' name="phone" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                            </Form.Field>
                        </Form.Group>
                        <span style={{display:"flex",justifyContent:"space-between"}}>
                            <Button
                                color={this.state.new_data_row.edit?'black':'green'}
                                loading={this.state.createing_user}
                                onClick={()=>{this.createNewBranch()}}>
                                    {this.state.new_data_row.edit?'Edit':'Create'}
                            </Button>
                        </span>
                    </Form>
            </Modal.Content>
            </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
...state
})
const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    Contact:  (data) => dispatch(contactAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
    setAppData: (data) => dispatch(appDataAction(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Company);
