import React, { useLayoutEffect } from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Modal, Popup, Table, Accordion, Breadcrumb, ButtonGroup } from 'semantic-ui-react'
import "../styles/pricing-modal.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import {InterRouteCommunication} from '../actions/interRouteCommunication'
import Client from '../services/Client';
import { log } from 'util';


class PricingModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show_able_services:[],
      table_fields : [
        {text:"Amount",value:"amount", type:"field"},
        {text:"Unit",value:"unit", type:"field"},
        {text:"Description",value:"description", type:"field"},
        {text:"Terms",value:"terms", type:"field"},
        {text:"Start Date",value:"start_date", type:"field"},
        {text:"End Date",value:"end", type:"field"},
      ],
      data_rows:[
        // {"subscription_type":1,"amount":150,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":1,"amount":180,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":0,"amount":290,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":0,"amount":120,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":2,"amount":110,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":2,"amount":990,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":2,"amount":980,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
        // {"subscription_type":2,"amount":220,"unit":"hr","description":"On Demand Rates Per Hour","terms":null,"start_date":null,"end_date":null},
      ],
      rate_types:[
        {name:'AMC',value:0},
        {name:'Subscription',value:1},
        {name:'On Demand',value:2},
      ],
      show_rate_type:0,
      pricing_city:'',
      pricing_service:'',
      accordion_index:0
    }
  }
  componentDidMount(){
    // if (this.props.app_data.user_details.login) {
    //   this.getApprovers()
    // }
    if(this.props.new_data_row.branch){
      this.setCityAndServices({},{name:"pricing_branch",value:this.props.new_data_row.branch})
    }else if (this.props.new_data_row.city) {
      this.setCityAndServices({},{name:"pricing_city",value:this.props.new_data_row.city})
    } else {
      this.setState({show_able_services:this.props.option_services})
    }
    setTimeout(()=>{
      if(this.props.new_data_row.service_category){
        this.setCityAndServices({},{name:"pricing_service",value:this.props.new_data_row.service_category})
      }
    },300)
    // this.setState({})
  }
  setCityAndServices(e,data){
    let state = {...this.state}
    let services = this.props.option_services
    if (data.name == "pricing_city" && data.value != "") {
      state.show_able_services = services.filter(service=>{
        return service.city.toUpperCase() == data.value.toUpperCase()
      })
    }
    if (data.name == "pricing_branch" && data.value != "") {
      let branch_object = this.props.app_data.user_details.branches.find(branch=>{
        return branch.id == data.value
      })
      console.log(branch_object,data.value);
      
      state.show_able_services = services.filter(service=>{
        console.log("from branches",service.city)
        return service.city.toUpperCase() == branch_object.city.toUpperCase()
      })
      state.pricing_city = branch_object.city
    }
    if ((data.name == "pricing_city" || data.name == "pricing_branch") && data.value == "") {
      state.show_able_services = services
      
    }
    if (data.name == "pricing_service" ) {
      let pricing_service_object = this.props.option_services.find(elem=>{return elem.value == data.value})
      state.pricing_sub_category = this.state.pricing_sub_category
      console.log(data.value,this.state.pricing_service);
      if (data.value != this.state.pricing_service) {
        
        state.pricing_sub_category=false
      }
      
    }
    state[data.name]=data.value
    if (state.pricing_city != "" && state.pricing_service !="") {
      
      this.getDataRows(state)
    }
    this.setState({...state})
  }
  getDataRows(state){
    Client.getPricing(null,null,{},null,null,state.pricing_service,state.pricing_city)
    .then(res=>{
      console.log(res)
      this.setState({data_rows:res.pricing_structure})
    })
  }
  render(){
    return(
      <>
     
        <Modal.Content scrolling>
          <Grid>
            <Grid.Column width="4">
              {
                this.props.app_data.user_details.login
                ?
                <Dropdown
                // className="drp1"
                
                clearable
                search
                // className={this.props.new_data_row.up?"drp1 flat-bottom":"drp1"}
                selection
                name="pricing_branch"
                placeholder="Select Branch"
                style={{fontSize:'12px'}}
                value={this.state.pricing_branch}
                options={this.props.app_data.user_details.options_branches}
                onChange={(e,data)=>{
                  this.setCityAndServices(e,data)
                }}
                />
              :
              <Dropdown
                
                // className={this.props.new_data_row.up?"drp1 flat-bottom":"drp1"}
                clearable
                style={{fontSize:'12px'}}
                search
                selection
                name="pricing_city"
                placeholder="Select City"
                value={this.state.pricing_city}
                options={this.props.option_cities}
                onChange={(e,data)=>{
                  this.setCityAndServices(e,data)
                }}
                />
              }
              
              <Card fluid wide className="compact-card"  style={{maxHeight:'40vh',overflowY:'scroll'}}>
                {/* <Card.Content header='Categories' /> */}
                { 
                 this.state.show_able_services.map((service)=>{
                    return (
                    <Card.Content
                        className={this.state.pricing_service == service.value?" active":""}
                        onClick={()=>{this.setCityAndServices({},{name:"pricing_service",value:service.value})}}
                      >
                      
                      {service.text}
                        {/* {
                          service.sub_categories.length
                          ?
                          <Popup
                            trigger={<Icon  name="arrow right"/>}
                            on="click"
                            // flowing
                            position={"center right"}
                            >
                              <Card fluid wide  >
                            {
                              service.sub_categories.map(elem=>(
                                <Card.Content
                                // style={{background:this.state.pricing_sub_category == elem.id?'teal':''}}
                                  className="cat-content"
                                  // onClick={(e)=>{
                                  //   e.stopPropagation()
                                  //   this.setState({pricing_sub_category:elem.id})
                                  // }}
                                >
                                  {elem.category}
                                </Card.Content>
                              ))
                            } 
                            </Card>
                          </Popup>
                          :
                          null
                        } */}
                    </Card.Content>
                  )})
                }
              </Card>
            </Grid.Column>
            <Grid.Column width={12} >
              <span style={{width:'100%'}}> 

                {/* <Icon
                  name="image"
                  size="massive"
                  style={{height:'150px',}}
                /> */}
                {/* <Accordion styled>
                  {
                    this.state.rate_types.map(rate_type=>(
                      <>
                        <Accordion.Title
                          active={this.state.accordion_index === rate_type.value}
                          index={rate_type.value}
                          onClick={()=>{
                            if (this.state.accordion_index === rate_type.value) {
                              this.setState({accordion_index:false})
                            } else {
                              this.setState({accordion_index:rate_type.value})
                            }
                          }}
                        >
                          <Icon name='dropdown' />
                          {rate_type.name}
                        </Accordion.Title>
                        <Accordion.Content active={this.state.accordion_index === rate_type.value}>
                        <Table unstackable compact celled className="scroll-table">
                          <Table.Header>
                            {
                              this.state.table_fields.map(field=>{
                                return(
                                  <Table.HeaderCell>{field.text}</Table.HeaderCell>
                                )
                              })
                            }
                          </Table.Header>
                          <Table.Body>
                            {
                              this.state.data_rows.map(data_row=>{
                                if(data_row.subscription_type == rate_type.value)
                                return (
                                  <Table.Row>
                                    {
                                      this.state.table_fields.map(field=>{
                                        return(
                                          <Table.Cell>{data_row[field.value]}</Table.Cell>
                                        )
                                      })
                                    }
                                  </Table.Row>
                                )
                              })
                            }
                          </Table.Body>
                        </Table>
                      
                        </Accordion.Content>
                      </>
                    ))
                  }
                </Accordion>
              */}
              <div>
              <ButtonGroup size="tiny">
              {
                this.state.rate_types.map(rate_type=>(
                  <Button onClick={()=>{this.setState({show_rate_type:rate_type.value})}} positive={this.state.show_rate_type == rate_type.value}>
                    {rate_type.name}
                  </Button>
                ))
              }
              </ButtonGroup>
              <Table unstackable compact celled className="scroll-table">
                <Table.Header>
                  {
                    this.state.table_fields.map(field=>{
                      return(
                        <Table.HeaderCell>{field.text}</Table.HeaderCell>
                      )
                    })
                  }
                </Table.Header>
                <Table.Body>
                  {
                    this.state.data_rows.map(data_row=>{
                      if(data_row.subscription_type == this.state.show_rate_type)
                      return (
                        <Table.Row>
                          {
                            this.state.table_fields.map(field=>{
                              return(
                                <Table.Cell>{data_row[field.value]}</Table.Cell>
                              )
                            })
                          }
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table>
              
              </div>
              </span>
            </Grid.Column>
          </Grid>
        </Modal.Content>
    
      </>
    )
  }
}
const mapStateToProps = state => ({
  ...state
 })
const mapDispatchToProps = dispatch => ({
  newDataRowAction: (data) => dispatch(newDataRowAction(data)),
  Contact:  (data) => dispatch(contactAction(data)),
  optionServices: (data) => dispatch(serviceAction(data)),
  optionCategories: (data) => dispatch(categoryAction(data)),
  setAppData: (data) => dispatch(appDataAction(data)),
  deployInterRouteMessage: (data) => dispatch(InterRouteCommunication(data))
 })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingModal));
