import React from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Label, Message } from 'semantic-ui-react'
import "../styles/home.css"
import "../styles/feedback.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
import Client from '../services/Client';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import Navlinks from './Navlinks'
class Feedback extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            new_contact_row:{},
            message:false,
            option_rating:[
                {value:'1',text:"1"},
                {value:'2',text:"2"},
                {value:'3',text:"3"},
                {value:'4',text:"4"},
                {value:'5',text:"5"},
            ]
        }
    }
    componentDidMount(){
        let params = window.location.pathname.split('/')
        // if(params.length >= 3){
        //     this.setState({order_id:params[2]},()=>{
                
        //     })
        // }
        // let user_details = this.props.app_data.user_details
        if (params.length == 4) {
            this.setState({order_id:params[2],key:params[3]},()=>{
                Client.ordersFeedbackGet(null,null,null,null,null,"order_slip","asc",{order_slip:[this.state.order_id]},{})
                .then(res=>{
                    if (res.data_rows.length) {
                        this.setState({done:true,done_message:"Feedback For This Order Has  Been Already Generated!"})
                    }
                })
            })
        }
    }
    setContactFormData(e,data){
        let new_contact_row = this.state.new_contact_row
        new_contact_row[data.name] = data.value
        this.setState({new_contact_row})
    }
    sendRequest(){
        this.setState({loading:true})
        // let user_details = this.props.app_data.user_details
        // let new_data_row = this.state.new_contact_row
        // new_data_row['order_slip'] = this.state.order_id
        let user = this.props.app_data.user_details
        Client.createFeedbackWithKey(user.token,user.reg_id,user.username,{...this.state.new_contact_row,token:(this.state.key==""?undefined:this.state.key),order_slip:this.state.order_id})
        .then(res=>{
            if (res.responseResult ==	"failure") {
                this.setState({done:true,done_message:`Something went Wrong, Kindly contact help and support `,link:true})
            } else {
                this.setState({message:'FeedBack Submitted Successfully',done:true,done_message:'',new_contact_row:{},loading:false,link:false})
            }
        })
        .catch(err=>{
            this.setState({message:'FeedBack Not Submitted Successfully'})
        })
    }
    render(){
        return(
        < >
            {
                this.props.app_data.user_details.login
                    ?
                        <Navlinks
                            company={this.state.company_details}
                            user={this.state.user_details}
                        />
                    :
                null
            }
            <div className="feedback">
                <Message style={{display:this.state.message?'block':'none'}} >
                    <Message.Header>
                        {this.state.message}
                    </Message.Header>
                </Message>
                {
                    this.state.order_id 
                    ?
                    (!this.state.done)
                    ?
                    <>
                        <Form onSubmit={()=>this.sendRequest()} className="contact-form">
                            <h2>Rating</h2>
                            <Message>
                                <Message.Header>
                                    Order: {this.state.order_id || ''}
                                </Message.Header>
                            </Message>
                            <div className="review_field">
                                <Label basic >
                                    Service
                                </Label>
                            <Dropdown
                                // label={<Label style={{width:'20%',textAlign:'left'}} basic>Service</Label>} 
                                value= {this.state.new_contact_row.rating || ''} 
                                name="rating"
                                selection 
                                fluid
                                options={this.state.option_rating}
                                wide
                                placeholder='Choose Your Rating' 
                                onChange={(e,data)=>{this.setContactFormData(e,data)}} 
                                />
                            </div>
                            <div className="review_field">
                                <Label basic >
                                    Review
                                </Label>
                                <div >
                                    <Form.Input
                                        fluid
                                        style={{width:'100%'}}
                                        wide
                                        value={this.state.new_contact_row.review || ''} 
                                        name="review" 
                                        placeholder='Enter Your Review' 
                                        onChange={(e,data)=>{this.setContactFormData(e,data)}} 
                                        />
                                </div>
                            </div>
                            <div className="review_field"> 
                                <Label  basic>
                                    Suggestion
                                </Label>
                                <div>
                                    <Form.Input
                                        fluid
                                        // style={{display:'block',width:'80%'}}
                                        wide
                                        value={this.state.new_contact_row.suggestions || ''} 
                                        name="suggestions" 
                                        placeholder='Enter Your Suggestion' 
                                        onChange={(e,data)=>{this.setContactFormData(e,data)}} 
                                        />
                                </div>
                            </div>
                            <Button  
                                fluid 
                                wide 
                                style={{backgroundColor:'blueviolet'}} 
                                type='submit' 
                                loading={this.state.loading} 
                                positive
                                >
                                    Send
                            </Button>
                        </Form>
                    </>  
                    :
                    <h1>{this.state.done_message}
                        {
                            this.state.link
                            ?
                            <a href="https://deskneed.com/contact">Here</a>
                            :
                            null
                        }
                    </h1>         
                    :       
                    <h1>
                        Something went wrong, Try going back to oders page or check your email
                    </h1>
                }
            </div>
        </>
        )
    }
}
const mapStateToProps = state => ({
    ...state
   })
  const mapDispatchToProps = dispatch => ({
    // newDataRowAction: (data) => dispatch(newDataRowAction(data)),

   })
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Feedback));
  