import React from 'react';
import '../styles/navlinks.css'
import { connect } from 'react-redux';
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Loader, Dropdown, Modal } from 'semantic-ui-react'
import { newDataRowAction } from '../actions/newDataRowAction';

import {appDataAction} from '../actions/appDataAction'
import Client from '../services/Client';
import { thisTypeAnnotation } from '@babel/types';
class Navlinks extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      toggleState : 1,
      visibleToggleables : '',
      allowed_routes:{
        user:['/','/home','/orders','/invoice','/account','/company','/gst-reports','/quotation','/logout','/contract'],
        admin:['/','/home','/orders','/invoice','/account','/company','/users','/gst-reports',,'/quotation','/logout','/contract']
      }
    }
    this.myRef = React.createRef();
  }
  componentDidMount(){

    let links = document.getElementById('links')
    if (window.innerWidth>720) {
      this.setState({toggleState:true})
    } else {
      this.setState({toggleState:false})
    }
    window.addEventListener("resize", ()=>{
      let links = document.getElementById('links')
      if (window.innerWidth>720) {
        this.setState({toggleState:true})
      } else {
        this.setState({toggleState:false})
      }
    });
  }
  onEnterPress(data){
    console.log(data);
    if (data.target.name == "key") {

      if (data.which == 13 || data.key == 13) {
        this.login()
      }
    }
  }
  changeLoginData(data){
    this.setState({[data.target.name]:data.target.value})
  }
  getApprovers(){
    let user_details = this.props.app_data.user_details
    let approvers = user_details.approvers
    Client.getCompanyUsers(user_details.token,user_details.reg_id,user_details.username,null,null,{account_id:approvers}).then(res=>{
        let users = res.data_rows.map(user=>{
          return {value:user.account_id,text:user.name}
        })

        this.props.setApprovers({approvers:users})
    })
  }
  login(){
    this.setState({loading:1})
    Client.login(this.state.username,this.state.key,this.props.app_data.user_details.reg_id).then((res)=>{
      if (res.responseResult == 'success') {
        let userdata = {username:this.state.username,key:this.state.key,reg_id:this.props.app_data.reg_id}
        let user_obj = {
          name : res.name,
          username : res.username,
          city: res.city,
          role : res.role,
          email : res.email,
          phone  : res.phone,
          company : res.company.name,
          company_id : res.company.id,
          branches : res.company.branches,
          designation : res.designation,
          token : res.token,
          reg_id : this.props.app_data.user_details.reg_id,
          login : true,
          approval_required:res.approval_required,
          approvers:JSON.parse(res.approvers)
        }
        if ( Object.entries(this.props.new_data_row).length == 0 ) {
          this.props.newDataRowAction({})
        }
        let options_branches= user_obj.branches.map(branch =>{
          return {text:branch.branch_name,value:branch.id,city:branch.city}
        })
        user_obj['options_branches'] = options_branches
        this.props.setAppData({...this.props.app_data,user_details:user_obj})
        localStorage.setItem('user_details',JSON.stringify(user_obj))
        this.setState({showLoginModal:false,loading:0})
        this.props.setAppData({...this.props.app_data,showLoginModal:false})
        if (user_obj.approval_required) {
          let approvers = user_obj.approvers
          Client.getCompanyUsers(user_obj.token,user_obj.reg_id,user_obj.username,null,null,{account_id:approvers},"rehydrate").then(res=>{
              let users = res.data_rows.map(user=>{
                return {value:user.account_id,text:user.name}
              })
              user_obj['options_approvers'] =users
              this.props.setAppData({...this.props.app_data,user_details:user_obj})
          })
        }
      } else {
        this.setState({err_message:"Invalid Credentials",loading:0})
      }
    })
  }

  closeModal(){
    this.props.setAppData({...this.props.app_data,showLoginModal:false})
  }
  showLoginModal(){
    this.props.setAppData({...this.props.app_data,showLoginModal:true})
    setTimeout(()=>{
      this.myRef.current.focus()
    },200)
  }
  showTogleablesOf(e,masterLink){
    e.preventDefault()
    let visibleToggleables = this.props.app_data.visibleToggleables
    if (visibleToggleables == masterLink) {
      visibleToggleables = ''
    } else {
      visibleToggleables = masterLink
    }
    this.props.setAppData({...this.props.app_data,visibleToggleables:visibleToggleables})
  }
  render(){
    let allowed_routes= this.state.allowed_routes[this.props.app_data.user_details.role]
    return(
      <div className="navlinks" id={this.state.toggleState?'box':''}>
        <span className="companyName">
          {
              this.props.app_data.user_details.login == true
              ?
              <span>
                <span>{this.props.app_data.user_details.company}</span>
                <span> ({this.props.app_data.user_details.name})</span>
              </span>
              :
              null
          }
          <button className="sandwich" onClick={()=>{this.setState({toggleState:!this.state.toggleState})}}>=</button>
        </span>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ...state
 })
 const mapDispatchToProps = dispatch => ({
  setAppData: (data) => dispatch(appDataAction(data)),
  newDataRowAction: (data) => dispatch(newDataRowAction(data)),
 })
 export default connect(mapStateToProps, mapDispatchToProps)(Navlinks);
