import React, { useLayoutEffect } from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Modal, Popup, Table, FormButton, Segment, Dimmer, Loader, Label } from 'semantic-ui-react'
import "../styles/home.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
// import FileViewer from 'react-file-viewer';
import  'file-viewer'
import Sprinter from '../Components/Sprinter'
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import {InterRouteCommunication} from '../actions/interRouteCommunication'
import Client from '../services/Client';
import back_1 from '../assets/deskneed_back_1.jpg';
import back_person from '../assets/deskneed_back_person.jpg';
import back from '../assets/back.jpg';
import Contact from './contact';
import Navlinks from './Navlinks';
import Footer from './footer';

class FilePreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    
    }
    
  }
  componentDidMount(){
   
  }

  changeNewDataRow(data,e){
    
  }
  handleFileAddition(e){  
    let files = []
    const target_files =  Array.from(e.target.files)

      for (let index = 0; index < target_files.length; index++) {
        const file = target_files[index];
        this.fileReader(file).then(res=>{
          this.props.setAppData({...this.props.app_data,files:[...this.props.app_data.files,{file:file}]})
  
        })
        
      }
  }
  async fileReader(file){
    let reader = new FileReader();
      reader.onload =  (e) => {
        file.result = e.target.result
        // files.push({'file':file})
        return file
      };
      await reader.readAsDataURL(file);
  }



  
  removeFile(name){
    console.log(name,"name remove");
    
    // let files = this.state.files.filter(elem=>{return elem.file.name != name})
    let redux_files = this.props.app_data.files.filter(elem=>{return elem.file.name != name})
    // this.setState({files})
    this.props.setAppData({...this.props.app_data,files:redux_files})
  }

  render(){
    return(
          <>
            <span style={{display:'flex',alignItems:'center',width:'100%'}}>
              
              {
                this.props.app_data.show_preview !== false
                ?
                <span   
                  style={{flex:1,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',height:'50vh'}}>
                  
                    {
                      this.props.app_data.loading_preview
                      ?
                      <Segment style={{height:'50vh',width:'100%'}}>
                        <Dimmer style={{width:"100%"}} active>
                          <Loader active />
                        </Dimmer>
                      </Segment>
                      :
                      <file-viewer
                        style={{width:'100%',flexGrow:1,display:'flex',justifyContent:'center'}}
                        // id={this.state.files[this.state.show_preview].file.name+"."+this.state.files[this.state.show_preview].file.type.split('/')[1]}
                        filename={this.props.app_data.files[this.props.app_data.show_preview].file.name+"."+this.props.app_data.files[this.props.app_data.show_preview].file.type.split('/')[1]}
                        url={this.props.app_data.files[this.props.app_data.show_preview].file.result}
                      />

                    }
                    {this.props.app_data.files[this.props.app_data.show_preview].file.name}
                    {
                      this.props.app_data.files.length
                      ?
                      <span style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>

                      {this.props.app_data.files.map((file,i)=>{
                        let name = file.file.name.split('.')
                        let show_name = name[0].slice(0,3)+'...'+name[1]
    
                        return(
                          <Label color={this.props.app_data.show_preview === i?"olive":""} onClick={()=>{
                            this.props.setAppData({...this.props.app_data,loading_preview:true})
                            setTimeout(()=>{
                              this.props.setAppData({...this.props.app_data,show_preview:i,loading_preview:false})

                            },100)

                            }}>
                            <Icon name='close' onClick={(e)=>{
                              e.stopPropagation()
                              this.props.setAppData({...this.props.app_data,show_preview:this.props.app_data.files.length==1?false:0})
                                setTimeout(()=>{
                                  this.removeFile(file.file.name)
                                },100)     
                              }} />
                              {show_name}
                          </Label>
                      )})}
                      </span>
                      :
                      null
                    }
                </span>
                :
                null
              }
            </span>
            <div className="preview-console">

            <Icon
                style={{visibility:this.props.app_data.show_preview > 0?'visible':'hidden'}}
                onClick={()=>{
                  if (this.props.app_data.show_preview > 0) {
                    this.props.setAppData({...this.props.app_data,show_preview:this.props.app_data.show_preview-1,loading_preview:true})
                    setTimeout(()=>{
                      this.props.setAppData({...this.props.app_data,loading_preview:false})
                    },100)
                  }
                }}
                size="huge" name="angle left"/>
              <Icon 
                style={{visibility:this.props.app_data.show_preview < this.props.app_data.files.length-1?'visible':'hidden'}}

                onClick={()=>{
                    if (this.props.app_data.show_preview < this.props.app_data.files.length-1) {
                      this.props.setAppData({...this.props.app_data,show_preview:this.props.app_data.show_preview+1,loading_preview:true})
                      setTimeout(()=>{
                        this.props.setAppData({...this.props.app_data,loading_preview:false})
                      },100)
                    }
                }}
                size="huge" name="angle right"/>
            </div>
          </>
    )
  }
}
const mapStateToProps = state => ({
  ...state
 })
const mapDispatchToProps = dispatch => ({
  newDataRowAction: (data) => dispatch(newDataRowAction(data)),
  Contact:  (data) => dispatch(contactAction(data)),
  optionServices: (data) => dispatch(serviceAction(data)),
  optionCategories: (data) => dispatch(categoryAction(data)),
  setAppData: (data) => dispatch(appDataAction(data)),
  deployInterRouteMessage: (data) => dispatch(InterRouteCommunication(data))
 })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilePreview));
