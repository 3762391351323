import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../Components/section_testimonials';
import SectionFaq from '../Components/section_faq';
import SectionContact from '../Components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/seller_body.css'

class SellerBody extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            onboarding_details: [
                {
                    image: '/images/partnership.png',
                    text: 'Register with Deskhub with an easy onboarding process and start getting benefits.'
                },
                {
                    image: '/images/relax_work.png',
                    text: 'Ensure getting regular business with the hellp of Deskneed. New leads everyday.'
                },
                {
                    image: '/images/work_checklist.png',
                    text: 'Complete your work with quality on time. Only you are best at the work you do.'
                },
                {
                    image: '/images/earn_money.png',
                    text: 'Regularised payments released in a timely manner. No need to chase with customers anymore.'
                },
            ],
            selected_onboarding: {
                index: 0,
                image: '/images/partnership.png',
                text: 'Register with Deskhub with an easy onboarding process and start getting benefits.'
            }
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    showOnboardingDetails(index, flag){
        if(flag){
            if(index < 0){
                index = 0;
            }
            if(index > 3){
                index = 3;
            }
            switch(index){
                case 0:
                    $('.seller-onboarding-points').css('left', '3.5%')
                    break;
                case 1:
                    $('.seller-onboarding-points').css('left', '-89.5%')
                    break;
                case 2:
                    $('.seller-onboarding-points').css('left', '-182.5%')
                    break;
                case 3:
                    $('.seller-onboarding-points').css('left', '-275.5%')
                    break;
            }
        }
        this.setState({
            selected_onboarding: {...this.state.onboarding_details[index], index: index}
        })
    }

    render(){
        return(
            <div>
                <div className="body-seller">
                    <div className="body-seller-1-1">
                        <div style={{fontSize: "large", width: "60%", textAlign:"left", paddingRight:'5%'}}>
                            <p style={{fontSize: "x-large"}}><b>Having problems in fulfilling B2B Client's expectations?</b></p>
                            <p style={{fontSize: 'medium'}}>
                                Join Deskhub. Which lets you focus on your primary work and handles communication and support with B2B customers.
                                Now Grow your business hassle free.<br/>
                                Deskhub helps you by providing support in compliance, delivery, customer support. With deskhub as a bridge, you get more business and the B2B customers gets needs fulfilled as per their expectations.
                            </p>
                        </div>
                        <img src="/images/stressed_seller.png" style={{width: "40%"}}/>
                    </div>
                </div>

                <div className="body-seller-2">
                    <p style={{fontSize: "x-large", fontWeight:'bold', textAlign:'center'}}>Benefits & Support.</p>
                    <div>
                        <div className="body-about-2-1">
                            <img src="/images/man_calling.png"/>
                            <h2>Benefits by joining!</h2>
                            <ul>
                                <li>Process and paper work handled by Deskneed.</li>
                                <li>Regularised payments.</li>
                                <li>Tech Platform to get realtime updates about requirements.</li>
                                <li>One time onboarding for business with multiple customers.</li>
                                <li>A2Z Compliance Handling</li>
                            </ul>
                        </div>
                        <div className="body-about-2-2">
                            <img src="/images/tech_support.png"/>
                            <h2>Support from Deskhub!</h2>
                            <ul>
                                <li>Deskneed does all compliances with corporates.</li>
                                <li>Supervises services provided, monitors quality, maintains reliability</li>
                                <li>Mantains relationships</li>
                                <li>Helps seller build their portfolio for clear understanding.</li>
                                <li>Bridges the gap of requirements and problems faced by sellers as well as the customers</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="body-seller-3">
                    <p style={{fontSize: "x-large", fontWeight:'bold', textAlign:'center'}}>How It Works?</p>
                    <div className="seller-onboarding">
                        <div className="seller-onboarding-points">
                            <div onClick={() => this.showOnboardingDetails(0)}>
                                <p>1</p>
                                <p>Sign up with Deskhub in no time.</p>
                            </div>
                            <div onClick={() => this.showOnboardingDetails(1)}>
                                <p>2</p>
                                <p>Get new leads daily.</p>
                            </div>
                            <div onClick={() => this.showOnboardingDetails(2)}>
                                <p>3</p>
                                <p>Deliver services to clients.</p>
                            </div>
                            <div onClick={() => this.showOnboardingDetails(3)}>
                                <p>4</p>
                                <p>Get paid without any chase.</p>
                            </div>
                        </div>
                        <div className="seller-onboarding-detail">
                            <img className="seller-onboarding-detail-image" src={this.state.selected_onboarding.image}/>
                            <p className="seller-onboarding-detail-text">
                                {this.state.selected_onboarding.text}
                            </p>
                            {
                                // <Button variant="contained" color="primary" size="medium">Sign Up</Button>
                            }
                        </div>
                        <div className="seller-onboarding-sweep">
                            <div onClick={() => this.showOnboardingDetails(this.state.selected_onboarding.index - 1, true)}>
                                <span class="material-icons">
                                    keyboard_arrow_left
                                </span>
                            </div>
                            <div onClick={() => this.showOnboardingDetails(this.state.selected_onboarding.index + 1, true)}>
                                <span class="material-icons">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(SellerBody);
