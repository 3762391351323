import React from 'react';
import { Table,Modal,Menu,Button ,Form,Card,Icon,Grid ,Input,Select,Pagination, Dropdown, GridColumn, Container, Message } from 'semantic-ui-react'
import "../styles/invoice.css"
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import QuotationView from './quotation-view';
import {categoryAction} from '../actions/optionsCategoryAction'
import Client from '../services/Client';
import Navlinks from './Navlinks'
import Footer from './footer';
const logo = require('./DSK_ICON.png')
const converter = require('number-to-words');


class Quotation extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            invoices:[],
            sort_key:'voucher_date',
            order:'asc',
            data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFields: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "voucher_id", "text": "Voucher Id", "type": "field"},
				{"value": "customer", "text": "Customer", "type": "select"},
				{"value": "order_id", "text": "Order Id", "type": "field"},
				{"value": "payable_amount", "text": "Amount", "type": "field"},
				{"value": "status", "text": "Status", "type": "field"},
			],
			defaultSize: 'small',
			tableButtons: [
			],
			paymentReceipt: {paymentInvoices:[{}]},
			currentDataRow: {},
			currentDataRowAction: 'none',
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'customer',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_customer: [],
			options_voucher_id: [],
			options_to_ledger: [],
			graph_customer:false,
			data_rows_branch:'',
			options_branch:[
			],
			options_branch_sort:[
			],
			api_sort:'date',
			api_sort_order:"desc"
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
    sortby(field){
        let invoices = this.state.data_rows
        let sort_key = this.state.sort_key
        let order = this.state.order
        if (sort_key == field) {
            order = order == 'asc'?'dsc':'asc'
        } else {
            sort_key = field
        }
        let new_invoices = invoices.sort((a,b)=>{
            console.log(a[field]);
            return order == 'asc' ?a[field] > b[field]: b[field] > a[field]
        })
        this.setState({sort_key,order})
        console.log(new_invoices);
    }

	showDataRowDetails(index, print, title){
		console.log(index);

		if(index == undefined || index == null)
			index = this.state.currentDataRowIndex || 0

		var sale = {...this.state.data_rows[index]};
		var taxDetails = this.calculateTax(sale)

		this.setState({
			currentDataRowIndex: index,
			currentDataRow: sale
		})

		var taxDetailsView = '';
		taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>' +
												'<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount) + '</td></tr>'
			}
		}.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>Amount</th></tr>'
		}
		else{
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
						'<th>Amount</th></tr>'
		}

		sale.products.forEach(function(product, index) {
			if(taxDetails.length > 0){
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:left">' + product.HSN +
								'</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}
			else{
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td><td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
							'</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + this.perfectDecimal(product.rate * product.qty) + '</td></tr>'
		}.bind(this));

		var invoiceHtml = `
        <!doctype html>
        <html>
        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
            <table>
                <tr>
                    <td style="width:10%">
                        <img style="width:100%" src=${logo}><img/>
                    </td>
                    <td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
                        <p style="padding:0; margin:0 0 0 5px">
                        R G B Road, Ganeshguri<br/>
                        Guwahati, Assam - 781006<br/>
                        GSTIN: 18AAXCS9739A1ZO<br/>
                        PAN: AAXCS9739A<br/>
                        MSME UAM No.: AS03D0001520<br/>
                        Contact: +91-9954683549<br/>
                        E-mail: contact@doorhopper.in
                        </p>
                    </td>
                    <td style="font-size:300%; text-align:right; padding-right:40px">
                        ` +
						(title || `QUOTATION`)
						+ `
                    </td>
                </tr>
            </table>

            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
                <table style="margin:0 0 0 5px">
                    <tr>
                        <td>Quotation Number</td>
                        <td><b>: ${sale.voucher_id}</b></td>
                    <tr>
                    <tr>
                        <td>Quotation Date</td>
                        <td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
                    <tr>
                </table>
            </div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999; display:` +
			((sale.contact_person == '' || sale.contact_person == null || sale.contact_person == undefined || sale.contact_person == 'undefined') ? `none` : `block`)
			+ `">
                Contact Person:
            </div>

			<div style="padding:5px 5px 5px 5px; display:` +
			((sale.contact_person == '' || sale.contact_person == null || sale.contact_person == undefined || sale.contact_person == 'undefined') ? `none` : `block`)
			+ `">
                ${sale.contact_person.replace(/\n/g, "<br />")}
            </div>

            <div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
                Quote For:
            </div>

            <div style="padding:5px 5px 5px 5px;">
                <p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
                <p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
                    ${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
                </p>
            </div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px">
						<div style="float:right; padding:2px 5px 0 0">E. & O.E.</div>
						Total in Words:<br/>
						<b>Rupees ${converter.toWords(sale.payable_amount)}</b>
						<br/>
						${sale.remarks != undefined && sale.remarks != '' ? sale.remarks.replace(/\n/g, "<br />") : ""}
						<br/>
						Hope to serve you again soon.
						<br/>
						<br/>
						<b>CHEQUE to be made in favour of SCIENCE2HOME PVT. LTD.</b><br/>
						Company's Bank Details:<br/>
						<b>Acc No: 39010200000238</b><br/>
						<b>IFSC Code: BARB0AMBARI‎</b><br/>
						<b>Bank Of Baroda</b><br/>
						Ambari, Guwahati

						<div style="text-align:center; padding:2px 0px 5px 0">SUBJECT TO GUWAHATI JURISDICTION</div>
					</td>
					<td style="border-left:solid 1px #999; padding:0; display: block">
						<div style="width:100%; margin:0; padding:5px 0 5px 0px">
							<table style="width:100%">
								<tr>
									<td>Sub Total</td><td style="text-align:right">
										${parseFloat(Math.round(sale.taxable_amount * 100) / 100).toFixed(2)}
									</td>
								</tr>
								${taxDetailsView}`
								if(sale.adjustment_name != null && sale.adjustment_name != '')
									invoiceHtml = invoiceHtml +
									`<tr>
										<td>${sale.adjustment_name}</td><td style="text-align:right">${sale.adjustment_amount}</td>
									</tr>`
								invoiceHtml = invoiceHtml +
								`<tr><td>Rounding</td><td style="text-align:right">${parseFloat(Math.round(sale.round_off * 100) / 100).toFixed(2)}</td></tr>
								<tr style="font-weight:bold"><td>Total</td><td style="text-align:right">${parseFloat(Math.round(sale.payable_amount * 100) / 100).toFixed(2)}</td></tr>
							</table>
						</div>
						<div style="text-align:center; border-top:solid 1px #999; width:100%">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		this.setState({
			showSaleDetailsModal: true
		})

		setTimeout(function(){
			document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		}, 500);

		if(print){
			var printwindow = window.open('','dhp_inv_sale', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = sale.voucher_id;
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		}
	}
	printDeliveryNote(print){

		var index = this.state.currentDataRowIndex
		var sale = {...this.state.data_rows[index]};
		var taxDetails = this.calculateTax(sale)

		var taxDetailsView = '';

		var productsTable =
		'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th></tr>'
		sale.products.forEach(function(product, index) {
			productsTable = productsTable +
			'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
							(product.description != null ? product.description : '') + '</td><td style="text-align:center">' + product.qty +
							'</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						DELIVERY NOTE
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Reference Number</td>
						<td><b>: ${sale.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Date</td>
						<td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				Delivery To
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
				<p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
					${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
						<br/>
						Hope to serve you again soon.
						<br/>
					</td>
					<td style="border-left:solid 1px #999; padding:0; text-align:center">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		// document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		// document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

		// if(print){
			var printwindow = window.open('','dhp_inv_delivery_note', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = sale.voucher_id + "_DELIVERY_NOTE";
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		// }
	}

	calculateTax(sale={customer_details:{pos:'AS'}}){
		var taxDetails = [];
		var subTotal = 0;
		var total = 0;
		var taxStateType = sale.customer_details.pos == "AS" ? 0 : 1;
		sale.products.forEach(function(element) {
			var taxDetail = {};
			var found = 0
			if(element.tax != 'N/A'){
				taxDetails.forEach(function(elem) {
					if(elem.taxPc == element.tax){
						found = 1;
						elem.taxStateType = taxStateType;
						elem.taxable_amount = Number(elem.taxable_amount) + (element.qty * element.rate)
						elem.tax_amount = elem.taxable_amount *  elem.taxPc / 100;
						elem.taxable_amount = parseFloat(Math.round(elem.taxable_amount * 100) / 100).toFixed(2);
						elem.tax_amount = parseFloat(Math.round(elem.tax_amount * 100) / 100).toFixed(2);
					}
				})
				if(found == 0){
					taxDetail['taxStateType'] = taxStateType;
					taxDetail['taxPc'] = element.tax;
					taxDetail['taxable_amount'] = element.qty * element.rate;
					taxDetail['taxable_amount'] = parseFloat(Math.round(taxDetail['taxable_amount'] * 100) / 100).toFixed(2);
					taxDetail['tax_amount'] = (element.qty * element.rate) * element.tax / 100;
					taxDetail['tax_amount'] = parseFloat(Math.round(taxDetail['tax_amount'] * 100) / 100).toFixed(2);
					taxDetails.push(taxDetail)
				}
			}
			subTotal = subTotal + (element.qty * element.rate);
		});

		taxDetails.forEach(function(elem) {
			total = total + Number(elem.tax_amount);
		});
		return taxDetails
	}


	componentDidMount() {
		var banks = [7,10];
		var options_voucher_id = [];
		var options_record_ledgers = []
		var options_customer = []
		let option_branches = []
		option_branches.push({text:'All',value:'All'})
		this.props.app_data.user_details.branches.forEach(branch => {
		  option_branches.push({text:branch.branch_name, value:branch.id})
		})
		let cust_branch
		if(option_branches.length == 2){
		  cust_branch = option_branches[1].value
		} else {
		  cust_branch= option_branches[0].value
		}
		this.setState({option_branches,cust_branch},()=>{
			this.getDataRows(this.state);
		})


	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data, stopLoad){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name || e.target.name] = data.value || e.target.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field" || data.name == "data_rows_search"){
			var filter = this.state.data_rows_filter
			delete filter.cust_branch
			state.data_rows_filter = filter
			state.showBranch = 0
		}
		//SELECTIVE OPERATIONS
		if(this.state.data_rows_search_field=="customer" && data.name == "data_rows_search"){
			state.showBranch = 1
			state.loading_branch = 1
			Client.getBranchesOfCustomer(data.value).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch_sort:options_branch,
						loading_branch:0
					})
				} else {
					this.setState({
						showBranch:0,
						options_branch_sort:''
					})
				}
			})
		}
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
			state['data_rows_search']=''
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name == "cust_branch"){
			if(data.value != "" && data.value != undefined){
				var filter = {...this.state.data_rows_filter, cust_branch:[data.value]}
			}
			else
				var filter = {...this.state.data_rows_filter, cust_branch:[]}
			state.data_rows_filter =filter
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state);
		if(!stopLoad)
			this.getDataRows(state);
	}
	handlePaginationChange= (e, { activePage }) => {
		this.setState({ data_rows_offset:(activePage*10)-10, },()=>{
		  this.getDataRows(this.state)
		})
	  }

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
	getDataRows(state){
		this.setState({fetching:true})
		let filter ={}
		if (this.state.cust_branch != 'All') {
		  filter= {cust_branch:[this.state.cust_branch]}
		} else {
		  let filter_branches = this.props.app_data.user_details.branches.map(branch => {return branch.id})
		  filter = {cust_branch:[...filter_branches]}
		}
		let user_details = this.props.app_data.user_details
		Client.getAccountingQuotation(user_details.token,user_details.reg_id,user_details.username,
			state.data_rows_offset,
			state.data_rows_limit,
			state.api_sort,
			state.api_sort_order,
			filter
			).then(res=>{
			var totalSale = 0;
			var totalTax = 0;
			res.data_rows.forEach(function(sale){
				totalSale = totalSale + parseFloat(sale.payable_amount);
				totalTax = totalTax + parseFloat(sale.tax_amount);
			})
            this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size,
				totalSale: totalSale.toFixed(2),
				totalTax: totalTax.toFixed(2),
				fetching:false
			})
		})
	}

	//CUSTOM FUNCTIONS
	changeStateVariableRecord(e, data, index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = this.state.new_data_row;
		records[index][data.name] = data.value || e.target.value
		new_data_row.records = [...records]
		if(data.name == "ledger"){
			this.getRecordReferences(new_data_row, records, index);
		}
		if(data.name == "against_reference"){
			records[index]['options_references'].forEach(function(reference){
				if(reference.value == data.value){
					records[index]['amount'] = reference.payable_amount
				}
			})
		}
		this.setState({
			new_data_row: new_data_row
		})
		this.makeCalculations();
	}

	getRecordReferences(new_data_row, records, index){
		records[index]['loadingReferences'] = true
		this.setState({
			new_data_row: new_data_row
		})
		Client.getAccountingSales(null, null, 'date', 'desc', null, null, null, {'customer': records[index]['ledger'], 'type': 'sale'}).then((res) => {
			records[index]['loadingReferences'] = false
			var options_references = [{text: 'ON ACCOUNT', value: null}]
			res.data_rows.forEach(function(data_row) {
				options_references.push({...data_row, text: data_row.voucher_id + ' - ' + data_row.date + ' - ' + data_row.payable_amount, value: data_row.voucher_id})
			})
			records[index]['options_references'] = options_references
			new_data_row.records = [...records]
			this.setState({
				new_data_row: new_data_row
			})
		})
	}

	addRecord(e){
		e.preventDefault();
		var records = this.state.new_data_row.records;
		var new_data_row = this.state.new_data_row;
		new_data_row.records = [...records, {}]
		this.setState({
			new_data_row: new_data_row
		})
		setTimeout(() => {
			document.getElementById('accounting-receipt-record-ledger' + (new_data_row.records.length - 1)).getElementsByTagName('input')[0].focus()
		},300)
		this.makeCalculations();
	}

	removeRecord(index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = {...this.state.new_data_row};
		var records1 = []
		records.forEach(function(element, index1) {
			if(index1 != index){
				records1.push(element)
			}
		});
		new_data_row.records = [...records1]
		this.setState({
			new_data_row: new_data_row
		})
		this.makeCalculations();
	}

	makeCalculations(){
		var new_data_row = {...this.state.new_data_row}
		var records_total = 0;
		new_data_row.records.map(function(record){
			records_total = records_total + parseFloat(record.amount)
		})
		new_data_row.records_total = records_total
		this.setState({
			new_data_row: new_data_row
		})
	}

	handleVoucherIdAdditions(e, {value}){
		this.setState({
			options_voucher_id: [
				...this.state.options_voucher_id,
				{text: value, value: value}
			]
		})
	}

	handleInputChangePayment(e, data){
		var paymentReceipt = {...this.state.paymentReceipt};
		paymentReceipt[data.name] = e.target.value
		this.setState({
			paymentReceipt: paymentReceipt
		})
	}

	setFilter(e, data){
		var filter = {...this.state.data_rows_filter}
		var array = data.value;
		array.forEach(function(element, index){
			if(element == null){
				array.splice(index, 1)
			}
		})
		filter[data.name] = data.value
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}

	setMonthSelections(){
		var monthSelection = [];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		if(endMonth < 12){
			endMonth++;
		}
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}
		this.setState({
			options_month: monthSelection
		})
	}
	onConsoleDataChange(e,data){
		this.setState({
		  [data.name]:data.value
		},()=>{
		  this.getDataRows(this.state)
		})
	  }
	handleApiSort(data) {
		let api_sort_order = this.state.api_sort_order
		let api_sort = this.state.api_sort
		if (data.name == 'api_sort') {
			api_sort =data.value
		} else if (data.name == "api_sort_order") {
			api_sort_order = api_sort_order == "asc"?"desc":"asc"
		}
		this.setState({api_sort,api_sort_order},()=>{
			this.getDataRows(this.state)
		})
	}
	showSaleDetails(print, index, title){
        if(index != undefined){
            var sale = {...this.state.data_rows[index]};
            this.setState({
                currentDataRowIndex: index,
                currentInvoiceSale: sale
            })
        }
        else{
            index = this.state.currentDataRowIndex
        }

		var sale = {...this.state.data_rows[index]};
		console.log(sale)
        var taxDetails = this.calculateTax(sale)

        var taxDetailsView = '';
        taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>' +
	                                            '<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount) + '</td></tr>'
			}
        }.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>Amount</th></tr>'
		}
		else{
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
                        '<th>Amount</th></tr>'
		}

        sale.products.forEach(function(product, index) {
            if(taxDetails.length > 0){
				productsTable = productsTable +
	            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:left">' + product.HSN +
	                            '</td><td style="text-align:right">' + product.qty +
	                            '</td><td style="text-align:right">' + product.rate + '</td>';
			}
			else{
				productsTable = productsTable +
	            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:right">' + product.qty +
	                            '</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
                            '</td><td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
                            '</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
                            '</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + this.perfectDecimal(product.rate * product.qty) + '</td></tr>'
        }.bind(this));

        var invoiceHtml = `
        <!doctype html>
        <html>
        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
            <table>
                <tr>
                    <td style="width:10%">
                        <img style="width:100%" src=${logo}><img/>
                    </td>
                    <td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
                        <p style="padding:0; margin:0 0 0 5px">
                        R G B Road, Ganeshguri<br/>
                        Guwahati, Assam - 781006<br/>
                        GSTIN: 18AAXCS9739A1ZO<br/>
                        PAN: AAXCS9739A<br/>
                        MSME UAM No.: AS03D0001520<br/>
                        Contact: +91-9954683549<br/>
                        E-mail: contact@doorhopper.in
                        </p>
                    </td>
                    <td style="font-size:300%; text-align:right; padding-right:40px">
                        ` +
						(title || `QUOTATION`)
						+ `
                    </td>
                </tr>
            </table>

            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
                <table style="margin:0 0 0 5px">
                    <tr>
                        <td>Quotation Number</td>
                        <td><b>: ${sale.voucher_id}</b></td>
                    <tr>
                    <tr>
                        <td>Quotation Date</td>
                        <td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
                    <tr>
                </table>
            </div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999; display:` +
			((sale.contact_person == '' || sale.contact_person == null || sale.contact_person == undefined || sale.contact_person == 'undefined') ? `none` : `block`)
			+ `">
                Contact Person:
            </div>

			<div style="padding:5px 5px 5px 5px; display:` +
			((sale.contact_person == '' || sale.contact_person == null || sale.contact_person == undefined || sale.contact_person == 'undefined') ? `none` : `block`)
			+ `">
                ${sale.contact_person.replace(/\n/g, "<br />")}
            </div>

            <div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
                Quote For:
            </div>

            <div style="padding:5px 5px 5px 5px;">
                <p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
                <p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
                    ${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
                </p>
            </div>

            <table class="productsTable">
                ${productsTable}
            </table>

            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
                <tr>
                    <td style="vertical-align:top; padding:5px 0 0 5px">
                        Total in Words:<br/>
                        <b>Rupees ${converter.toWords(sale.payable_amount)}</b>
                        <br/>
						${sale.remarks != undefined && sale.remarks != '' ? sale.remarks.replace(/\n/g, "<br />") : ""}
						<br/>
                        <br/>
                        Looking forward to serve you.
                        <br/>
                    </td>
                    <td style="border-left:solid 1px #999; padding:0; display:block">
                        <div style="width:100%; margin:0; padding:5px 0 5px 0px">
                            <table style="width:100%">
                                <tr><td>Sub Total</td><td style="text-align:right">${parseFloat(Math.round(sale.taxable_amount * 100) / 100).toFixed(2)}</td></tr>
                                ${taxDetailsView}`
								if(sale.adjustment_name != null && sale.adjustment_name != '')
                                	invoiceHtml = invoiceHtml +
									`<tr><td>${sale.adjustment_name}</td><td style="text-align:right">${sale.adjustment_amount}</td></tr>`
                                invoiceHtml = invoiceHtml +
								`<tr><td>Rounding</td><td style="text-align:right">${parseFloat(Math.round(sale.round_off * 100) / 100).toFixed(2)}</td></tr>
                                <tr style="font-weight:bold"><td>Total</td><td style="text-align:right">${parseFloat(Math.round(sale.payable_amount * 100) / 100).toFixed(2)}</td></tr>
                            </table>
                        </div>
                        <div style="text-align:center; border-top:solid 1px #999; width:100%">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        Authorised Signatory
                        </div>
                    </td>
                </tr>
            </table>

        </body>
        <style>
            body{
                margin: 0em;
            }
            *{
                font-family: sans-serif
            }
            table{
                border-collapse: collapse;
            }
            .productsTable, .productsTableInner{
                width:100%;
            }
            .productsTable td{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTable th{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTableInner td{
                border:none;
            }
            .productsTableInner th{
                border:none;
            }
        </style>
        </html>
        `

        // document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
        // document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

        if(print){
            var printwindow = window.open('','dhp_inv_sale', 'height=1000, width=1000');
    		printwindow.document.write(
                invoiceHtml
    		);
            printwindow.focus();
            setTimeout(function() {
                printwindow.document.title = sale.voucher_id;
                printwindow.print();
                printwindow.close();
                printwindow.document.close();
            }, 500);
        }
    }
	showSaleDetails1(print, index, title){
        if(index != undefined){
            var sale = {...this.state.data_rows[index]};
            this.setState({
                currentDataRowIndex: index,
                currentInvoiceSale: sale
            })
        }
        else{
            index = this.state.currentDataRowIndex
        }

        var sale = {...this.state.data_rows[index]};
        var taxDetails = this.calculateTax(sale)

        var taxDetailsView = '';
        taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount/2) + '</td></tr>' +
	                                            '<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount) + '</td></tr>'
			}
        }.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>MRP</th>' +
                        '<th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>MRP</th><th>Amount</th></tr>'
		}
		else{
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
                        '<th>MRP</th><th>Amount</th></tr>'
		}

		var totalRate = 0;
		var totalCGST = 0;
		var totalSGST = 0;
		var totalMRP = 0;
		var totalTotalAmount = 0;
        sale.products.forEach(function(product, index) {
			var totalAmount = this.perfectDecimal(product.rate * product.qty);
            if(taxDetails.length > 0){
				productsTable = productsTable +
	            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:left">' + product.HSN +
	                            '</td><td style="text-align:right">' + product.qty +
	                            '</td><td style="text-align:right">' + product.rate + '</td>';
				totalAmount = this.perfectDecimal(parseFloat(totalAmount) + product.rate * product.qty * product.tax/100);
			}
			else{
				productsTable = productsTable +
	            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:right">' + product.qty +
	                            '</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
                            '</td><td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
                            '</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
                            '</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + ((this.perfectDecimal(product.MRP) == 0.00 || product.MRP == '' || product.MRP == null) ? '--' : this.perfectDecimal(product.MRP * product.qty)) + '</td>' +
											'<td style="text-align:right">' + totalAmount + '</td></tr>';


			totalRate = totalRate + parseFloat(product.rate);
			totalCGST = totalCGST + parseFloat(this.perfectDecimal(product.rate * product.qty * product.tax/200));
			totalSGST = totalSGST + parseFloat(this.perfectDecimal(product.rate * product.qty * product.tax/200));
			totalMRP = totalMRP + ((this.perfectDecimal(product.MRP) == 0.00 || product.MRP == '' || product.MRP == null))?product.MRP : null;
			totalTotalAmount = totalTotalAmount + parseFloat(totalAmount);
        }.bind(this));

		if(taxDetails.length > 0){
			productsTable = productsTable +
			'<tr><td style="text-align:center"> </td><td><b>TOTAL</b></td><td style="text-align:left">' +
							'</td><td style="text-align:right">' +
							'</td><td style="text-align:right">' + this.perfectDecimal(totalRate) + '</td>';
		}
		else{
			productsTable = productsTable +
			'<tr><td style="text-align:center"></td><td><b>TOTAL</b></td><td style="text-align:right">' +
							'</td><td style="text-align:right">' + this.perfectDecimal(totalRate) + '</td>';
		}

		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			productsTable = productsTable + '<td style="position:relative">'+
						'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
						'<table class="productsTableInner">' +
						'<tr><td style="width:50px; text-align:right"></td>'+
						'<td style="min-width:50px; max-width:100px; text-align:right">' +
						this.perfectDecimal(totalCGST) + '</td></tr></table>' +
						'</td><td style="position:relative">'+
						'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
						'<table class="productsTableInner">' +
						'<tr><td style="width:50px; text-align:right"></td>'+
						'<td style="min-width:50px; max-width:100px; text-align:right">' +
						this.perfectDecimal(totalSGST) + '</td></tr></table>' +
						'</td>'
		}
		else if(taxDetails.length > 0){
			productsTable = productsTable + '<td style="position:relative">'+
						'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
						'<table class="productsTableInner">' +
						'<tr><td style="width:50px; text-align:right"></td>'+
						'<td style="min-width:50px; max-width:100px; text-align:right">' +
						this.perfectDecimal(totalCGST + totalSGST) + '</td></tr></table>' +
						'</td>'
		}
		console.log(totalMRP);

		productsTable = productsTable + '<td style="text-align:right">' + ((totalMRP == 0 || totalMRP== null || totalMRP == undefined || totalMRP == 'undefined' || totalMRP == NaN)? '--' : this.perfectDecimal(totalMRP)) + '</td>' +
										'<td style="text-align:right">' + this.perfectDecimal(totalTotalAmount) + '</td></tr>';

        var invoiceHtml = `
        <!doctype html>
        <html>
        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
            <table>
                <tr>
                    <td style="width:10%">
                        <img style="width:100%" src=${logo}><img/>
                    </td>
                    <td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
                        <p style="padding:0; margin:0 0 0 5px">
                        R G B Road, Ganeshguri<br/>
                        Guwahati, Assam - 781006<br/>
                        GSTIN: 18AAXCS9739A1ZO<br/>
                        PAN: AAXCS9739A<br/>
                        MSME UAM No.: AS03D0001520<br/>
                        Contact: +91-9954683549<br/>
                        E-mail: contact@doorhopper.in
                        </p>
                    </td>
                    <td style="font-size:300%; text-align:right; padding-right:40px">
                        ` +
						(title || `QUOTATION`)
						+ `
                    </td>
                </tr>
            </table>

            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
                <table style="margin:0 0 0 5px">
                    <tr>
                        <td>Quotation Number</td>
                        <td><b>: ${sale.voucher_id}</b></td>
                    <tr>
                    <tr>
                        <td>Quotation Date</td>
                        <td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
                    <tr>
                </table>
            </div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999; display:` +
			((sale.contact_person == '' || sale.contact_person == null || sale.contact_person == undefined || sale.contact_person == 'undefined') ? `none` : `block`)
			+ `">
                Contact Person:
            </div>

			<div style="padding:5px 5px 5px 5px; display:` +
			((sale.contact_person == '' || sale.contact_person == null || sale.contact_person == undefined || sale.contact_person == 'undefined') ? `none` : `block`)
			+ `">
                ${sale.contact_person.replace(/\n/g, "<br />")}
            </div>

            <div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
                Quote For:
            </div>

            <div style="padding:5px 5px 5px 5px;">
                <p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
                <p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
                    ${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
                </p>
            </div>

            <table class="productsTable">
                ${productsTable}
            </table>

            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
                <tr>
                    <td style="vertical-align:top; padding:5px 0 0 5px">
                        Total in Words:<br/>
                        <b>Rupees ${converter.toWords(sale.payable_amount)}</b>
                        <br/>
						${sale.remarks != undefined && sale.remarks != '' ? sale.remarks.replace(/\n/g, "<br />") : ""}
						<br/>
                        <br/>
                        Looking forward to serve you.
                        <br/>
                    </td>
                    <td style="border-left:solid 1px #999; padding:0">
                        <div style="text-align:center; width:100%">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        Authorised Signatory
                        </div>
                    </td>
                </tr>
            </table>

        </body>
        <style>
            body{
                margin: 0em;
            }
            *{
                font-family: sans-serif
            }
            table{
                border-collapse: collapse;
            }
            .productsTable, .productsTableInner{
                width:100%;
            }
            .productsTable td{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTable th{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTableInner td{
                border:none;
            }
            .productsTableInner th{
                border:none;
            }
        </style>
        </html>
        `

        // document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
        // document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

        if(print){
            var printwindow = window.open('','dhp_inv_sale', 'height=1000, width=1000');
    		printwindow.document.write(
                invoiceHtml
    		);
            printwindow.focus();
            setTimeout(function() {
                printwindow.document.title = sale.voucher_id;
                printwindow.print();
                printwindow.close();
                printwindow.document.close();
            }, 500);
        }
	}
	// quotationAction(){
	// 	let remarks = this.state.remarks
	// 	let action = this.state.currentDataRowAction
	// 	let user_details = this.props.app_data.user_details
	// 	Client.approveQuotation(
	// 		user_details.token,user_details.reg_id,user_details.username,
	// 		remarks,action,this.state.currentDataRow.id
	// 	).then(res=>{
	// 		let currentDataRow = this.state.currentDataRow
	// 		currentDataRow['status'] = action.toUpperCase
	// 		let message = {body:action ?'Approved':'Rejected',type:'possitive'}
	// 		this.setState({currentDataRow,message,currentDataRowAction:'none',remarks:''},()=>{
	// 			this.getDataRows(this.state)
	// 		})
	// 	}).catch(err=>{
	// 		let message = {body:'Something went wrong.',type:'negative'}
	// 		this.setState({message,currentDataRowAction:'none',remarks:''},()=>{
	// 			// this.getDataRows(this.state)
	// 		})
	// 	})
	// }
	refresh(){
		this.getDataRows(this.state)
	}
    render(){
        return(
			<>
			{
				this.props.app_data.user_details.login
				?
				<Navlinks
				company={this.state.company_details}
				user={this.state.user_details}
				/>
				:
				null
			}

			<div className="area">
				<div className="oreder-console">

						<Dropdown
							selection
							loading={this.state.fetching}
							name="cust_branch"
							className="for"
							onChange={(e,data)=>{this.onConsoleDataChange(e,data)}}
							options={this.state.option_branches}
							value={this.state.cust_branch}
							placeholder="Select A Branch"
							/>

						<Button className="sort" fluid wide basic icon labelPosition='right'>
							<Dropdown onChange={(e,data)=>this.handleApiSort(data)} name="api_sort" value={this.state.api_sort} options={this.state.tableFields}/>
							<Icon basic onClick={()=>this.handleApiSort({name:'api_sort_order',value:null})} name={this.state.api_sort_order==="asc"?"up arrow":' down arrow'}/>
						</Button>

					<Pagination
						size='mini'
						boundaryRange={1}
						activePage={(this.state.data_rows_offset/10)+1}
						onPageChange={this.handlePaginationChange}
						totalPages={Math.floor(this.state.data_rows_size/10)+1}
					/>
				</div>
            <div className=" theme-table">
                <Table style={{marginTop:'0px'}} celled>
                    <Table.Header>
                    <Table.Row>
                        {
                            this.state.tableFields.map(field=>(
								<Table.HeaderCell style={{cursor:'pointer'}}
									// onClick={()=>this.sortby(field.value )}
									>
                                    {field.text}
                                    {/* <Icon name=
                                    {
                                        this.state.sort_key == field.value
                                        ?
                                            this.state.order == 'asc'
                                            ?
                                            'angle up'
                                            :
                                            'angle down'
                                        :
                                        null
                                    }
                                    /> */}
                                </Table.HeaderCell>
                            ))
                        }
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            this.state.data_rows.map((invoice,index) =>(
                                <Table.Row onClick={()=>{this.showDataRowDetails(index)}} >
                                    {
                                        this.state.tableFields.map((field)=>(
                                            <Table.Cell>
                                                {field.value == 'customer'?invoice.customer_details.name:invoice[field.value]}
                                            </Table.Cell>
                                        ))
                                    }
                                </Table.Row>
                            ))
                        }

                    </Table.Body>
                </Table>
				<Modal size="large" open={this.state.showSaleDetailsModal} onClose={() => this.setState({showSaleDetailsModal:false, showCancelInvoiceOption: false,currentDataRowAction:'none',message:false})}>
					<Modal.Content scrolling>
					{
						this.state.showSaleDetailsModal
						?
						<QuotationView refresh={this.refresh.bind(this)} history={this.props.history} data_row={this.state.currentDataRow}/>
						:
						null
					}
					</Modal.Content>
				</Modal>



            </div>
			</div>
			</>
		)
    }
}
   const mapStateToProps = state => ({
    ...state
   })
  const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    Contact:  (data) => dispatch(contactAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
   })
  export default connect(mapStateToProps, mapDispatchToProps)(Quotation);
