import React from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Loader } from 'semantic-ui-react'
import "../styles/home.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom'; 

import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import {appDataAction} from '../actions/appDataAction'
import Client from '../services/Client';

import back_1 from '../assets/deskneed_back_1.jpg';
import back_person from '../assets/deskneed_back_person.jpg';
// import {interRouteReducer} from '../reducers/interRouteReducer';
import back from '../assets/back.jpg';

class Logout extends React.Component {
  constructor(props){
    super(props);

  }
  componentWillMount(){
    this.setState({loggin_out:true})
    let user =  this.props.app_data.user_details || JSON.parse(localStorage.getItem('user_details')) || {}
    let reg_id =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    localStorage.removeItem('user_details')
    this.props.setAppData({...this.props.app_data,user_details:{login:false,reg_id:reg_id}})
    // window.location.pathname ='/'
    this.props.newDataRowAction({})
    setTimeout(()=>{
      this.props.history.push('/')
    },100)

    Client.logout(user.username,user.token,user.reg_id).then(res=>{
      // console.log(res)
      // this.setState({loggin_out:false},()=>{
      // })
    })
    .catch(err=>{
      // console.log(err)
      // let reg_id =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      // localStorage.removeItem('user_details')
      // this.props.setAppData({...this.props.app_data,user_details:{login:false,reg_id:reg_id}})
      // this.setState({loggin_out:false},()=>{
      //   this.props.history.push("/")
      // })
    })
    
  }
   render(){
    return(
      <div style={{minHeight:'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Loader active inline='centered'/>
        <div id="mainback_images">
            <img src={back} style={{}} className="mainback mainback_1"/>
            {/* <img src={back_person} style={{}} class="mainback mainback_2"/> */}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ...state
 })
const mapDispatchToProps = dispatch => ({
  setAppData: (data) => dispatch(appDataAction(data)),
  newDataRowAction: (data) => dispatch(newDataRowAction(data)),
 })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
