import { combineReducers } from 'redux';
import new_data_row from './newDataRowReducer';
import contact from './contactReducer';
import option_cities from './citiesReducer'
import option_categories from './categoriesReducer'
import option_services from './servicesReducer'
import app_data from './appDataReducer'
import inter_route from "./interRouteReducer";

export default combineReducers({
    new_data_row,contact,
    option_cities,
    option_categories,
    option_services,
    app_data,
    inter_route
});
