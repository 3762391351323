import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';

import {Button, TextField} from '@material-ui/core';
import ContactForm from './contact-form';

import '../styles/section_contact.css'

class SectionContact extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            title: props.title
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    render(){
        return(
            <div className="section-contact">
                <p style={{fontSize: "x-large", marginBottom:0}}>{this.state.title || "Facing Problems?"}</p>
                <ContactForm header="Lets get in touch"/>
            </div>
		)
    }
}
export default SectionContact;
