import React, { useLayoutEffect } from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Modal, Popup, Table, FormButton, Segment, Dimmer, Loader, Label } from 'semantic-ui-react'
import "../styles/home.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
// import FileViewer from 'react-file-viewer';
import  'file-viewer'
import Sprinter from '../Components/Sprinter'
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import {InterRouteCommunication} from '../actions/interRouteCommunication'
import Client from '../services/Client';
import back_1 from '../assets/deskneed_back_1.jpg';
import back_person from '../assets/deskneed_back_person.jpg';
import back from '../assets/back.jpg';
import Contact from './contact';
import Navlinks from './Navlinks';
import Footer from './footer';

class FileOrdersPreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show_preview:0,
      loading_order_preview:false,
    }
    
  }
  componentDidMount(){
   
  }

  changeNewDataRow(data,e){
    
  }


  render(){
    return(
          <>
            <span style={{display:'flex',alignItems:'center',width:'100%'}}>
              
              {
                this.props.files.length
                ?
                <span   
                  style={{flex:1,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',height:'50vh'}}>
                  
                    {
                      this.state.loading_order_preview
                      ?
                      <Segment style={{height:'50vh',width:'100%'}}>
                        <Dimmer style={{width:"100%"}} active>
                          <Loader active />
                        </Dimmer>
                      </Segment>
                      :
                      <file-viewer
                        style={{width:'100%',flexGrow:1,display:'flex',justifyContent:'center'}}
                        // id={this.state.files[this.state.show_preview].file.name+"."+this.state.files[this.state.show_preview].file.type.split('/')[1]}
                        filename={this.props.files[this.state.show_preview].file_key.split('.')[1]}
                        url={"https://dsk-order-files.s3.ap-south-1.amazonaws.com/"+this.props.files[this.state.show_preview].file_key}
                      />

                    }
                    {this.props.files[this.state.show_preview].file_key.split('_')[1]}
                    {
                      this.props.files.length
                      ?
                      <span style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>

                      {this.props.files.map((file,i)=>{
                        let name = file.file_key.split('_')
                        let show_name = name[1]
    
                        return(
                          <Label color={this.state.show_preview === i?"olive":""} onClick={()=>{
                            this.setState({loading_order_preview:true})
                            setTimeout(()=>{
                              this.setState({show_preview:i,loading_order_preview:false})

                            },100)

                            }}>
                            
                              {show_name}
                          </Label>
                      )})}
                      </span>
                      :
                      null
                    }
                </span>
                :
                null
              }
            </span>
            <div className="preview-console">

            <Icon
                style={{visibility:this.state.show_preview > 0?'visible':'hidden'}}
                onClick={()=>{
                  if (this.state.show_preview > 0) {
                    this.setState({show_preview:this.state.show_preview-1,loading_order_preview:true})
                    setTimeout(()=>{
                      this.setState({loading_order_preview:false})
                    },100)
                  }
                }}
                size="huge" name="angle left"/>
              <Icon 
                style={{visibility:this.state.show_preview < this.props.files.length-1?'visible':'hidden'}}

                onClick={()=>{
                    if (this.state.show_preview < this.props.files.length-1) {
                      this.setState({show_preview:this.state.show_preview+1,loading_order_preview:true})
                      setTimeout(()=>{
                        this.setState({loading_order_preview:false})
                      },100)
                    }
                }}
                size="huge" name="angle right"/>
            </div>
          </>
    )
  }
}
const mapStateToProps = state => ({
  ...state
 })
const mapDispatchToProps = dispatch => ({
  newDataRowAction: (data) => dispatch(newDataRowAction(data)),
  Contact:  (data) => dispatch(contactAction(data)),
  optionServices: (data) => dispatch(serviceAction(data)),
  optionCategories: (data) => dispatch(categoryAction(data)),
  setAppData: (data) => dispatch(appDataAction(data)),
  deployInterRouteMessage: (data) => dispatch(InterRouteCommunication(data))
 })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileOrdersPreview));
