import React, { useLayoutEffect } from 'react';
import { Container,Button, Header ,Form,Card,Icon,Grid ,Input,Select, Message, Dropdown, GridColumn, Image, Checkbox, Modal, Popup, Table, FormButton, Segment, Dimmer, Loader, Label } from 'semantic-ui-react'
import "../styles/home.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
// import FileViewer from 'react-file-viewer';
import 'file-viewer'
import Sprinter from '../Components/Sprinter'
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import {InterRouteCommunication} from '../actions/interRouteCommunication'
import Client from '../services/Client';
import back_1 from '../assets/deskneed_back_1.jpg';
import back_person from '../assets/deskneed_back_person.jpg';
import back from '../assets/back.jpg';
// import Contact from './contact';
// import Navlinks from './Navlinks';
// import Footer from './footer';
import FilePreview from '../routes/file-preview';
import TextLoop from "react-text-loop";
import light_off from "../assets/home/light_off.svg";
import servicemen from "../assets/home/servicemen.png";
import call_contact from "../assets/home/call_contact.png";
import light_on from "../assets/home/light_on.svg";
import clients from "../assets/clients/clients.jpg";
import deskfix_reference from "../assets/deskfix_reference.png";
import SendBird from 'sendbird';
import '../styles/home_body.css';

class HomeBody extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            chatMessages: [],
            contracts: [],
            selectedContractProducts: {},
            cat_scroll_index:0,
            new_contact_row:{},
            options_estimates:[
                // {text:'ASAP',value:'ASAP'},
                {text:'By Today',value:'By Today'},
                {text:'By End of this week',value:'End of this week'},
                {text:'By End of this month',value:'End of this month'},
            ],
            // options_approvers:[]
            showable_cat:[],
            current:[],
            error_feilds:[],
            rate_types:[
                {name:'AMC',value:0},
                {name:'Subscription',value:1},
                {name:'On Demand',value:2},
            ],
            files:[],
            show_preview:false,
            form_data:new FormData()
        }
        this.myRef = React.createRef();
        this.fileInputRef = React.createRef();
        this.changeNewDataRow = this.changeNewDataRow.bind(this)
        this.props.newDataRowAction({list:[{name:''}]})
    }
    componentDidMount(){
        // if (this.props.app_data.user_details.login) {
        //   this.getApprovers()
        // }
        let params = window.location.pathname.split('/')
        if(params.length == 3){
            let city ={}
            //url_decoding
            let url_service = params[2].split("_").join(" ").toUpperCase().split('AND').join('&')
            let url_city =params[1].split("_").join(" ").toUpperCase().split('AND').join('&')
            //url_decoding ends
            let service =this.props.option_services.find((elem)=>{
                return elem.text.toUpperCase() == url_service.toUpperCase()
            })
            // alert(url_service)
            if (!service) {
                alert('service not found')
                window.location.pathname = ''
                return 0
            }
            if (!this.props.app_data.user_details.login) {
                //if user is not logged in
                city = this.props.option_cities.find((elem)=>{
                    return elem.value.toUpperCase() == url_city.toUpperCase()
                })
                if (city == undefined) {
                    window.action = this.props.history
                    return 0
                }
                if (city.value.toUpperCase() == service.city.toUpperCase()) {
                    //if the service is available in that city
                    this.changeNewDataRow({name:'city',value:city.value})
                    this.changeNewDataRow({name:'service_category',value:service.value})
                }else{
                    // console.log("city/service mismatch");
                }
            } else {
                let branch = ''
                let branch_list = this.props.app_data.user_details.branches.filter(elem=>{
                    return elem.city.toUpperCase() == url_city.toUpperCase()
                })
                if (branch_list.length) {
                    branch = branch_list[0]
                }else{
                    window.location.pathname = ''
                }
                if (branch.city.toUpperCase() == service.city.toUpperCase()) {
                    //if the service is available in that city
                    this.changeNewDataRow({name:'branch',value:branch.id})
                    this.changeNewDataRow({name:'service_category',value:service.value})
                }else{
                    // console.log("city/service mismatch");
                }
            }
        }
        if (params.length == 2) {
            let scrollTo = params[1]
            // console.log(scrollTo);

            let element =''
            if (element = document.getElementById(scrollTo.toLowerCase())) {
                element.scrollIntoView({behavior: "smooth"});
            }
        }
        if(this.props.app_data.user_details.approval_required){
            if (this.props.app_data.user_details.approvers.length == 1) {
                let new_data_row = this.state.new_data_row || {list:[{name:''}]}
                new_data_row['approval_by'] = this.props.app_data.user_details.approvers[0]
                this.setState({new_data_row})
            }
        }
        let current = [...this.props.app_data.options.service]
        .splice(this.state.cat_scroll_index,6)
        this.setState({current})
        setInterval(() => {
            this.scrollCat()
        }, 5000);
    }

    changeNewDataRow(data,e){
        let new_data_row = this.props.new_data_row || {list:[{name:''}]}
        let option_services = this.props.option_services
        let option_categories = []
        let current_service
        let area = this.props.app_data.user_details.login?'branch':'city'
        this.setState({error_feilds:this.state.error_feilds.filter(elem => elem!=data['name'])})
        if( data.name == 'city'){
            // this.props.history.replace('')
            this.props.history.replace("/" + data.value.split(" ").join("_").toLowerCase().split('&').join('and'))
            // if ( data.value!='') {
            //   option_services= this.props.app_data.options.service.filter((srvc=>{
            //     return srvc.city == data.value
            //   }))
            // } else {
            option_services = this.props.app_data.options.service
            // }
        }
        if (data.name == 'branch' && data.value == ''){
            this.props.history.replace('')
        }
        if (data.name == 'branch' && data.value != ''){
            let branch_selected = this.props.app_data.user_details.options_branches.filter(branch=>{
                return branch.value == data.value
            })
            this.props.history.replace('')
            this.props.history.replace(branch_selected[0].city.split(" ").join("_").toLowerCase().split('&').join('and'))
            new_data_row['city'] = branch_selected[0].city
            // option_services = this.props.app_data.options.service.filter((srvc=>{
            //     // console.log(srvc,branch_selected[0].city)
            //     return srvc.city.toUpperCase() == branch_selected[0].city.toUpperCase()
            // }))
            if (new_data_row['service_category']) {
                let flag = 0
                option_services.forEach(elem=>{
                    console.log(elem.value,new_data_row.service_category);
                    if (elem.value == new_data_row.service_category) {
                        flag = 1
                    }
                })
                if (flag == 0) {
                    new_data_row['service_category'] = "#empty"
                    this.props.optionCategories([])
                }
            }
        }
        new_data_row[data.name] = data.value
        if((new_data_row.service_category != null && new_data_row.service_category != '') && (new_data_row[area] != null && new_data_row[area] != '')){
            new_data_row['up'] = 1
        }
        else{
            new_data_row['up'] = 0
            console.log("clearing");
            this.setState({error_feilds:[]})
        }
        if(data.name == 'category'){
            new_data_row['final'] = true
        }
        if (data.name == 'service_category' && data.value == '') {
            this.props.history.replace('')
        }
        if(data.name == 'service_category' && data.value != '' && data.value != undefined){
            new_data_row['final'] = false
            option_categories = []
            current_service = this.props.option_services.filter((srvc)=>{
                return srvc.value == data.value
            })
            // alert('reached')
            let first_param = this.props.new_data_row.city || current_service[0].city
            if (first_param ) {
                // this.props.history.replace('')
                this.props.history.replace(
                    first_param.split(" ").join("_").toLowerCase().split('&').join('and')+
                    '/'+
                    current_service[0].text.split(" ").join("_").toLowerCase().split('&').join('and')
                )
                // new_data_row['city'] = current_service[0].city
                new_data_row['up'] = true
                this.getDataRows(new_data_row)
                if(!new_data_row.branch && this.props.app_data.user_details.login){
                    new_data_row['branch'] =this.props.app_data.user_details.options_branches[0].value
                }
            }
            current_service[0].sub_categories.map((cat)=>{
                option_categories.push({text:cat.category,value:cat.id})
            })
            if (!option_categories.length) {
                // option_categories = [{text:current_service[0].text,value:''}]
                new_data_row['category'] = ''
                // option_categories[0].value
                new_data_row['final'] = true
            }

            this.props.optionCategories(option_categories)
        }
        // discard if city or service is revoked
        if ((data.name == 'service_category' || data.name == area )) {
            delete new_data_row['selected_pricing']
            delete new_data_row['unit']
            delete new_data_row['description']
            delete new_data_row['subscription_type']
            delete new_data_row['qty']
            this.getDataRows(new_data_row)
        }
        if ((data.name == 'service_category' || data.name == area ) && data.value == ''){
            this.props.setAppData({...this.props.app_data,data_rows:[]})

            new_data_row = {final:false, list:[{name:''}]}
            new_data_row[area] = ''
            new_data_row['service_category'] = ''
        }
        if(data.name == 'approval_by'){
            this.setState({error:false})
        }
        // console.log("datas",data.name);

        this.props.newDataRowAction(new_data_row)
        this.props.optionServices(option_services)
    }
    handleFileAddition(e){
        let files = []
        const target_files =  Array.from(e.target.files)
        // this.setState({loading_files:true,files:[],loading_length:target_files.length},()=>{
        this.props.setAppData({...this.props.app_data,files:[]})
        setTimeout(()=>{
            for (let index = 0; index < target_files.length; index++) {
                const file = target_files[index];
                this.fileReader(file).then(res=>{
                    this.props.setAppData({...this.props.app_data,files:[...this.props.app_data.files,{file:file}]})
                })
            }
        },100)
    }
    async fileReader(file){
        let reader = new FileReader();
        reader.onload =  (e) => {
            file.result = e.target.result
            // files.push({'file':file})
            return file
        };
        await reader.readAsDataURL(file);
    }

    validator(keys_array,target_obj){
        let error_feilds = []
        // console.log(this.props.app_data.user_details.approval_required != 1);

        keys_array.forEach(key => {
            if (key == 'approval_by' && this.props.app_data.user_details.approval_required != 1) {
                // console.log("entered approv :",this.props.app_data.user_details.approval_required);
                // return 0;
                // if (this.props.app_data.user_details.approval_required != 1) {
                //   console.log("entered not ",);
                // }
            } else {
                // console.log("entered");
                if (target_obj[key] == '#empty' || target_obj[key] == '' || target_obj[key] == null || target_obj[key] == undefined || target_obj[key] == 'undefined') {
                    // console.log("key :" ,key);

                    error_feilds.push(key)
                }
            }
        });
        this.setState({error_feilds},()=>{
        })
        if (error_feilds.length) {
            // console.log(error_feilds);
            //some errors send false
            return false
        } else {
            // console.log(error_feilds,"pass");
            //0 errors send true
            return true
        }
    }
    removeFile(name){
        // let files = this.state.files.filter(elem=>{return elem.file.name != name})
        let redux_files = this.props.app_data.files.filter(elem=>{return elem.file.name != name})
        // this.setState({files})
        this.props.setAppData({...this.props.app_data,files:redux_files})
    }
    placeOrder(){
        let data = new FormData()
        let new_data_row = this.props.new_data_row
        if (!this.validator(['requirement','service_time','approval_by'],new_data_row)) {
            return 0
        }
        let user_details = this.props.app_data.user_details
        if(this.props.app_data.user_details.approval_required){
            if (!this.props.new_data_row.approval_by) {
                this.setState({error:'approval_by'})
                return 0
            }
        }
        new_data_row.files = []
        this.props.app_data.files.forEach((file,i)=>{
            data.append('image'+i,file.file,file.file.name)
            console.log("test",file);
            new_data_row.files.push({file_name:file.file.name,file_key:'image'+i})
        })
        for (var key of data.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
        if(this.props.app_data.user_details.login){
            this.props.newDataRowAction({...this.props.new_data_row,placing:true})
            new_data_row['requirement'] = new_data_row['requirement'] + " expected_service_time:" + new_data_row['service_time']
            Client.createOrder(this.props.new_data_row, user_details.token,user_details.reg_id,user_details.username,"",data).then(res=>{
                this.props.newDataRowAction({list:[{name:''}]})
                this.props.deployInterRouteMessage({data:res.slip_no,message:'Order Created'})
                this.props.history.push("/orders")
                this.setState({error:false})
                this.props.setAppData({...this.props.app_data,files:[],show_preview:false})
            })
        } else {
            this.props.setAppData({...this.props.app_data,showLoginModal:true,login_mode:'query'})
        }
    }

    scrollCat(order){
        let degree = 6
        let cat_scroll_index = parseInt(this.state.cat_scroll_index) + (order == "back"?-1*degree:degree)
        if (cat_scroll_index >= this.props.app_data.options.service.length || cat_scroll_index < 0){
            this.setState({cat_scroll_index:0},()=>{
                // console.log(cat_scroll_index,degree);
                let current = [...this.props.app_data.options.service]
                .splice(this.state.cat_scroll_index,6)
                this.setState({current})
            })
            return 0
        }
        this.setState({cat_scroll_index},()=>{
            // console.log(cat_scroll_index,degree);
            let current = [...this.props.app_data.options.service]
            .splice(this.state.cat_scroll_index,6)
            this.setState({current})
        })

    }
    showPricingModal(){
        this.setState({show_pricing_modal:true})
    }
    getDataRows(new_data_row){
        this.props.setAppData({...this.props.app_data,data_rows_loading:true})
        Client.getPricing(null,null,{},null,null,new_data_row.service_category,new_data_row.city)
        .then(res=>{
            console.log(res)
            this.props.setAppData({...this.props.app_data,data_rows:res.pricing_structure,data_rows_loading:false})
        })
    }

    addToList(addOrRemove, index){
        var new_data_row = {...this.props.new_data_row}
        var list = [...new_data_row.list]
        if(addOrRemove){
            list.push({name:''});
        }
        else{
            list.splice(index, 1);
        }
        new_data_row.list = list
        this.props.newDataRowAction(new_data_row)
    }
    modifyListElement(e, data, index){
        var new_data_row = {...this.props.new_data_row}
        var list = [...new_data_row.list]
        var listElement = {...list[index]}
        listElement[data.name] = data.value.substring(0,30)
        listElement["qty"] = listElement["qty"] == undefined ? 0 : listElement["qty"]
        listElement["rate"] = listElement["rate"] == undefined ? 0 : listElement["rate"]
        list[index] = listElement
        new_data_row.list = list
        this.props.newDataRowAction(new_data_row)
    }

	updateList(){
		if(JSON.stringify(this.state.selected_data_row.list) != JSON.stringify(this.state.data_row_list_check)){
			this.setState({
				updatingOrder: true
			})
			this.setState({
				creatingNewDataModal: true
			})
			var state = this.state
			Client.createOrderSlip(state.selected_data_row, true).then(res=>{
				this.setState({
					showCreateDataModal: false,
					creatingNewDataModal: false,
					editMode: false,
					last_created:res.slip_no,
					new_data_row: {slip_date: this.state.today, 'current_status': 'initiated', list: [{}]},
					data_rows_sort:"slip_no",
					data_rows_sort_order:"desc",
                    updatingOrder: false,
                    showListModal: false
				},()=>{
					this.getDataRows(this.state);
				})
			})
		}
		else{
			this.setState({
				showListModal: false
			})
		}
	}

    loadContracts(){
        this.setState({loadingContracts:true, selectedContractProducts: {}})
		let filter ={}
		// if (this.state.cust_branch != 'All' && this.state.cust_branch != null) {
		//   filter= {cust_branch:[this.state.cust_branch]}
		// } else {
		//   let filter_branches = this.props.app_data.user_details.branches.map(branch => {return branch.id})
		//   filter = {cust_branch:[...filter_branches]}
		// }
		let user_details = this.props.app_data.user_details
        filter = {customer:[user_details.company_id]}
		Client.getAccountingContract(user_details.token,user_details.reg_id,user_details.username,null,null,null,null,
			filter
			).then(res=>{
			var totalSale = 0;
			var totalTax = 0;
			res.data_rows.forEach(function(sale){
				totalSale = totalSale + parseFloat(sale.payable_amount);
				totalTax = totalTax + parseFloat(sale.tax_amount);
			})
            this.setState({
				contracts: res.data_rows,
				loadingContracts:false
			})
		})
    }

    populateContract(e, data){
        var contract = {...this.state.contracts[data.value]}
        this.setState({
            selectedContractProducts:contract.products
        })
    }

    addProductToList(addOrRemove, index){
        var new_data_row = {...this.props.new_data_row}
        var list = [...new_data_row.list]
        if(addOrRemove){
            var product = this.state.selectedContractProducts[index];
            if(list.length > 0 && list[0].name.trim() == "")
            {
                list.splice(0, 1);
            }
            var found = false;
            list.forEach((item, i) => {
                if(item.product_id == product.product_id &&
                // (product.name + (product.description != "" ? " (" + product.description + ")" : "")) == item.name){
                product.rate == item.rate){
                    found = true;
                    return;
                }
            });

            if(found){
                alert("Product Already Added");
            }
            else{
                list.push({
                    product_id: product.product_id,
                    name: product.name + (product.description != "" ? " (" + product.description + ")" : ""),
                    qty:1,
                    rate: product.rate
                });
            }
        }
        else{
            list.splice(index, 1);
        }
        new_data_row.list = list
        this.props.newDataRowAction(new_data_row)
    }

    render(){
        var listDisplay = []
        if(this.props.new_data_row.list != undefined)
            this.props.new_data_row.list.forEach(function(listElement, index)
            {
                if(listElement.name != undefined && listElement.name.trim() != '')
                listDisplay.push((listElement.name + " (" + listElement.rate + ") - " + listElement.qty))
            })

        return(
        <div>
        {
            // this.props.app_data.user_details.login
            // ?
            // <Navlinks
            //     company={this.state.company_details}
            //     user={this.state.user_details}
            //     />
            //     :
            //     null
        }


        <div className="base">
            <div className={"home-headers " + (this.props.new_data_row.up?"swim-left":"")}>
            <h1>Fulfill supply needs for your office.</h1>
            <p>Fulfill supply needs for your office. Stationery to Electronics, Furniture and more.</p>
            </div>
{
            // <a href="https://deskfix.in" target="_blank" className="deskfix_reference" style={{display:(this.props.new_data_row.up ? "none" : "block")}}>
            //     <img src={deskfix_reference} style={{width:'12vw'}}/>
            //     <br/>
            //     For Office Maintenance<br/>Electrician / Plumber / Carpenter / AMC
            // </a>
}
            <div className={this.props.new_data_row.up?"transparent":""}>
                <div id="search-consoles"  className={this.props.new_data_row.up?"swim-right":"full"}>
                    <span className="form-head">
                        {
                            this.props.app_data.user_details.login
                            ?
                            <Button.Group color='blue'>
                                <Dropdown clearable search selection floating
                                className={'home-hero-dropdown city button' + (this.props.new_data_row.up?" flat-bottom":'')}
                                style={{marginRight: '20px'}}
                                name="branch" placeholder="Select Branch"
                                value={this.props.new_data_row.branch || ''}
                                options={this.props.app_data.user_details.options_branches}
                                onChange={(e,data)=>{this.changeNewDataRow(data)}}/>
                            </Button.Group>
                            :
                            <Button.Group color='blue'>
                                <Dropdown clearable search selection floating
                                className={'home-hero-dropdown city button' + (this.props.new_data_row.up?" flat-bottom":'')}
                                style={{marginRight: '20px'}}
                                name="city" placeholder="Select City"
                                value={this.props.new_data_row.city || ''}
                                options={this.props.option_cities}
                                onChange={(e,data)=>{this.changeNewDataRow(data)}}/>
                            </Button.Group>
                        }
                        <Dropdown clearable search selection
                        className={"category" + (this.props.new_data_row.up?" flat-bottom":"")}
                        error={this.props.new_data_row.service_category == "#empty"}
                        name="service_category"
                        value={this.props.new_data_row.service_category || ''}
                        placeholder="Search Category Stationery/Furniture"
                        options={[...this.props.option_services]}
                        onChange={(e,data)=>{this.changeNewDataRow(data)}}/>
                    </span>
                    {
                        true
                        ?
                        <span className={!this.props.new_data_row.up?"hide-height ":"form-body"}>
                        <Form>
                            {
                                !this.props.app_data.data_rows_loading
                                ?
                                <Card fluid wide className="types-card">
                                {
                                    this.state.rate_types.map(rate_type=>{
                                        if(this.props.app_data.data_rows && this.props.app_data.data_rows.filter(pricing=>{return pricing.subscription_type == rate_type.value}).length)
                                        return(
                                            <span
                                            fluid wide
                                            onClick={()=>{
                                                this.props.newDataRowAction({...this.props.new_data_row,subscription_type:rate_type.value})
                                            }}
                                            style={{
                                                background:this.props.new_data_row.subscription_type == rate_type.value?"blueviolet":"",
                                                color:this.props.new_data_row.subscription_type == rate_type.value?"white":"black"
                                            }}
                                            className={"type-content"}>
                                                <div>
                                                    {rate_type.name}
                                                </div>
                                                <div>
                                                    <Button
                                                    color={"white"}
                                                    circular
                                                    className={this.props.new_data_row.subscription_type === rate_type.value?"rate-btn white-back":"rate-btn "}
                                                    size="tiny"
                                                    // basic={this.props.new_data_row.subscription_type != rate_type.value}
                                                    // positive={this.props.new_data_row.subscription_type == rate_type.value}
                                                    onClick={()=>{this.props.setAppData({...this.props.app_data,showPricingModalForType:rate_type.value})}}
                                                    icon="rupee sign"/>
                                                </div>
                                            </span>
                                        )
                                    })
                                }
                                </Card>
                                :
                                <Segment style={{zIndex:'-10'}}>
                                    <Dimmer active>
                                    <Loader size="mini" />
                                    </Dimmer>
                                </Segment>
                            }
                            <div>
                                <h4 style={{"display":"inline"}}>Requirement</h4>
                                <Button icon="list ul" color="blue" style={{float:"right"}} size="mini"
                                onClick={() => {this.setState({showListModal:true, contracts: [], selectedContractProducts: []})}}></Button>
                            </div>
                            <Message floating compact onClick={() => {this.setState({showListModal:true})}}
                            style={{cursor:'pointer', display: (listDisplay != undefined && listDisplay != '') ? 'block' : 'none'}} list={listDisplay}/>
                            {
                                this.props.new_data_row.hasOwnProperty("description")
                                ?
                                <div>
                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                        <span>
                                            {this.props.new_data_row.description} -Rs{this.props.new_data_row.selected_pricing}
                                        </span>
                                        <span>
                                        </span>
                                    </div>
                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                        <span>
                                        Quantity:
                                        <Input
                                        label={this.props.new_data_row.unit}
                                        labelPosition="right"
                                        value={this.props.new_data_row.qty}
                                        style={{fontSize:'9px',minWidth:'0px',width:'120px',margin:'5px',marginLeft:'5px'}}
                                        type="number" name="qty" size="mini" onChange={(e,data)=>this.changeNewDataRow(data)}/>
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                            }
                            <Form.TextArea
                            error={this.state.error_feilds.includes('requirement')}
                            ref={this.myRef}  name='requirement'
                            onChange={(e,data)=>{this.changeNewDataRow(data)}}
                            value={this.props.new_data_row.requirement || ''}
                            placeholder="Describe your needs / issue."/>
                            {
                                this.props.app_data.user_details.login
                                ?
                                this.props.app_data.user_details.approval_required
                                ?
                                <Dropdown
                                error={this.state.error_feilds.includes('approval_by')}
                                style={{borderColor:this.state.error == 'approval_by'?'red':'rgba(34,36,38,.15)'}}
                                name="approval_by"
                                className="time-dropdown"
                                fluid wide
                                upward
                                placeholder="Select An Approver"
                                search
                                value={this.props.new_data_row.approval_by}
                                selection
                                options={this.props.app_data.user_details.options_approvers}
                                onChange={(e,data)=>this.changeNewDataRow(data)}/>
                                :
                                null
                                :
                                null
                            }
                            {
                                this.state.loading_files
                                ?
                                <Segment>
                                    <Dimmer active>
                                    <Loader size="mini" />
                                    </Dimmer>
                                </Segment>
                                :
                                this.props.app_data.files.length
                                ?
                                <span style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                                {
                                    this.props.app_data.files.map((file,i)=>{
                                        let name = file.file.name.split('.')
                                        let show_name = name[0].slice(0,3)+'...'+name[1]
                                        return(
                                            <Label onClick={()=>{this.props.setAppData({...this.props.app_data,show_preview:i})}}>
                                            <Icon name='close' onClick={(e)=>{
                                                e.stopPropagation()
                                                this.removeFile(file.file.name)
                                            }} />
                                            {show_name}
                                            </Label>
                                        )
                                    })
                                }
                                </span>
                                :
                                null
                            }
                            <span className="form-footer" style={{display:'flex'}}>
                                <Dropdown
                                error={this.state.error_feilds.includes('service_time')}
                                className="time-dropdown"
                                style={{fontSize:'12px', flexGrow:'1', marginRight:'3px'}}
                                onChange={(e,data)=>{this.changeNewDataRow(data)}}
                                placeholder="Service Time"
                                name="service_time"
                                upward
                                value={this.props.new_data_row.service_time || ''}
                                search
                                // style={{zIndex:'999'}}
                                selection
                                options={this.state.options_estimates}
                                />
                                <Button
                                // color="black"
                                // content="Upload"
                                icon="attach"
                                color="green"
                                // labelPosition="left"
                                style={{fontSize:'12px'}}

                                onClick={(e) => {
                                    e.preventDefault()
                                    this.fileInputRef.current.click(e)
                                }}/>
                                <input
                                ref={this.fileInputRef}
                                type="file"
                                hidden
                                multiple="multiple"
                                onChange={(e)=>this.handleFileAddition(e)}
                                />
                                <Button
                                loading={this.props.new_data_row.placing}
                                onClick={()=>{this.placeOrder()}}
                                style={{background:'blueviolet',color:'white',fontSize:'12px'}}
                                // disabled={!this.props.new_data_row.final}
                                >
                                Request/Enquire
                                <Icon style={{marginLeft:'5px'}}name="paper plane"/>
                                </Button>
                                </span>
                        </Form>
                        </span>
                        :
                        null
                    }
                </div>
                {
                    this.props.new_data_row.up
                    ?
                    <Button size="big" className="close-transparent" onClick={()=>this.changeNewDataRow({name:'service_category',value:null})} icon="close"/>
                    :
                    null
                }
            </div>

            <div className={"home-hero-categories-container " + (this.props.new_data_row.up?"swim-left":"")}>
                {
                    this.state.current.map(srvc=>(
                        <div className="" onClick={()=>{this.changeNewDataRow({name:'service_category',value:srvc.value})}}>
                            <img
                            key={srvc.text}
                            alt={srvc.text}
                            className=""
                            src={`/images/deskneed_products/active/ic_${srvc.text.split(" ").join("_").toLowerCase()}.png`}
                            onError={(e)=>e.target.src =`../pics/icons/ic_maintenance.svg`}
                            /><br/>
                            <span className="">{srvc.text}</span>
                        </div>
                    ))
                }
            </div>
{
            // <div className={this.props.new_data_row.up?"card-holder half":"card-holder"} >
            //     <div className="tagline">
            //         <h1>
            //             <TextLoop interval={3000}>
            //                 <span>
            //                     SOLUTION TO ALL <b>NEEDS</b>
            //                     <br/>
            //                     OF<b> YOUR WORKPLACE</b>
            //                 </span>
            //                 <span>
            //                     STATIONERY TO FURNITURE
            //                     <br/>
            //                     <b>AT ONE PLACE WITH EASE</b>
            //                 </span>
            //                 <span>
            //                     AUTOMATED, PLUG AND PLAY?
            //                     <br/>
            //                     <b>ADMIN ERP SYSTEM</b>
            //                 </span>
            //                 <span>
            //                     MULTIPLE BRANCHES?
            //                     <br/>
            //                     <b>ONE SYSTEM</b>
            //                 </span>
            //             </TextLoop>
            //         </h1>
            //     </div>
            //     <div className="cat-console">
            //         <Icon size="huge" color={parseInt(this.state.cat_scroll_index) - 6 < 0?"grey":"black" } name="angle left" onClick={()=>{this.scrollCat('back')}}/>
            //         <Grid
            //         centered textAlign="left"
            //         padded>
            //             {
            //                 this.state.current.map(srvc=>(
            //                     <Grid.Column mobile="5" computer={this.props.new_data_row.up?5:2} className="category-box" onClick={()=>{this.changeNewDataRow({name:'service_category',value:srvc.value})}}>
            //                     <Card  className="service-cards">
            //                     <img
            //                     key={srvc.text}
            //                     alt={srvc.text}
            //                     className="category_image"
            //                     src={`../pics/icons/ic_${srvc.text.split(" ").join("_").toLowerCase()}.svg`}
            //                     onError={(e)=>e.target.src =`../pics/icons/ic_maintenance.svg`}
            //                     />
            //                     </Card>
            //                     <span className="category_text">{srvc.text}</span>
            //                     </Grid.Column>
            //                 ))
            //             }
            //         </Grid>
            //
            //         <Icon color={parseInt(this.state.cat_scroll_index) + 6 >= this.props.app_data.options.service.length?"grey":"black" } size="huge" name="angle right" onClick={()=>{this.scrollCat('forward')}}/>
            //     </div>
            // </div>
}
        </div>

        <div className="why-us-holder">
            <h2>Why Us?</h2>
            <div className="why-us-grid">
                <div className="why-us">
                    <img src="/images/billing_compliance.svg"/>
                    <h2>BILLING & COMPLIANCE</h2>
                    <p>Verifed Vendors, All legal, Labour laws, Financial compliances</p>
                </div>
                <div className="why-us">
                    <img src="/images/one_stop_solution.png"/>
                    <h2>ONE STOP SOLUTION</h2>
                    <p>End to End service provider. Various Supplies, Stationery to Furniture, at one place.</p>
                </div>
                <div className="why-us">
                    <img src="/images/order_placed.png"/>
                    <h2>HASSLE FREE PURCHASE</h2>
                    <p>Online and transparent system to make purchase.</p>
                </div>
                <div className="why-us">
                    <img src="/images/transparency_control.svg"/>
                    <h2>TRANSPARENCY & BETTER CONTROL</h2>
                    <p>Top to bottom transparency to create better relationship.</p>
                </div>
                <div className="why-us">
                    <img src="/images/happy_customer.png"/>
                    <h2>USER FRIENDLY TECHNOLOGY</h2>
                    <p>Technology Tools to manage entire experience of availing supplies from us.</p>
                </div>
                <div className="why-us">
                    <img src="/images/timely_delivery.png"/>
                    <h2>ASSURED TIMELY DELIVERY</h2>
                    <p>On time availability of supplies as per your business needs.</p>
                </div>
            </div>
        </div>

        <div className="how-we-work-container">
            <h2>How We Work?</h2>
            <div className="how-we-work-row">
                <div className="how-we-work-card first">
                    <div className="top">
                        <img src="/images/empty_table_icon.png"/>
                        <h3>Step 1</h3>
                    </div>
                    <div className="bottom">
                        <p>Identify needs at your office. Stationery, canteen items etc.</p>
                    </div>
                </div>
                <div className="how-we-work-card second">
                    <div className="top">
                        <img src="/images/order_online_icon.png"/>
                        <h3>Step 2</h3>
                    </div>
                    <div className="bottom">
                        <p>Order at Deskneed via any mode - online, via mail or via call.</p>
                    </div>
                </div>
                <div className="how-we-work-card third">
                    <div className="top">
                        <img src="/images/assured_quality_icon.png"/>
                        <h3>Step 3</h3>
                    </div>
                    <div className="bottom">
                        <p>Get best rates from the market with the help of Deskneed, assured with proper invoice and compliances with transparency.</p>
                    </div>
                </div>
                <div className="how-we-work-card fourth">
                    <div className="top">
                        <img src="/images/logistics_icon.png"/>
                        <h3>Step 4</h3>
                    </div>
                    <div className="bottom">
                        <p>Items get delivered at your place. Focus on your work and let Deskneed fulfill your needs.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="traction-container">
            <div>
                <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>1500+</p>
                <p style={{fontSize: "large"}}>Orders Served</p>
            </div>
            <div>
                <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>800</p>
                <p style={{fontSize: "large"}}>B2B Customers</p>
            </div>
            <div>
                <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>50+</p>
                <p style={{fontSize: "large"}}>Cities Covered</p>
            </div>
        </div>

        <Modal
        open={this.state.showListModal}
        onClose={() => {this.setState({showListModal:false})}}
        size={this.state.contracts.length > 0 ? "large" : "small"} closeIcon>
            <Modal.Content>
                <Header block textAlign='center'>Create List.</Header>
                {
                    this.props.app_data.user_details.login
                    ?
                    <Button size="tiny" color="blue" loading={this.state.loadingContracts} onClick={() => this.loadContracts()}>Load Contracts</Button>
                    :
                    null
                }
                {
                    this.state.contracts.length > 0
                    ?
                    <Dropdown
                    size="tiny"
                    placeholder='Select Contract'
                    fluid selection search
                    options={this.state.contracts.map(function(elem, index) {
                        return {
                        text: elem.voucher_id,
                        value: index,}})}
                    onChange={(e, data) => this.populateContract(e, data)}/>
                    :
                    null
                }
                <Grid>
                    <Grid.Row columns={this.state.selectedContractProducts.length > 0 ? "2" : "1"}>
                    {
                        this.state.selectedContractProducts.length > 0
                        ?
                        <Grid.Column style={{overflowY:'scroll', height:'40vh'}}>
                            <Table celled compact size="small">
                            <Table.Body>
                            {
                                this.state.selectedContractProducts.map((product, index) => (
                                    <Table.Row>
                                        <Table.Cell>
                                            {product.name + (product.description != "" ? " (" + product.description + ")" : "")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {product.rate}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button size="tiny" icon="arrow alternate circle right" onClick={() => this.addProductToList(true, index)}></Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                            </Table.Body>
                            </Table>
                        </Grid.Column>
                        :
                        null
                    }
                    <Grid.Column style={{overflowY:'scroll', maxHeight:'40vh'}}>
                        <Table celled compact size="small">
                            <Table.Body>
                            {
                                this.props.new_data_row.list != undefined
                                ?
                                this.props.new_data_row.list.map((list_element, index)=>(
                                    <Table.Row>
                                        <Table.Cell width={8}>
                                            <Input disabled={list_element.product_id != undefined} fluid placeholder='Item Name' maxLenght="20" name="name" value={list_element.name} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
                                        </Table.Cell>
                                        <Table.Cell width={5}>
                                            <Input disabled={list_element.product_id != undefined} fluid type="number" iconPosition='left' placeholder='Rate (Approx)' name="rate" value={list_element.rate} onChange={(e, data) => this.modifyListElement(e, data, index)}>
                                        <Icon name='rupee sign' />
                                            <input/>
                                            </Input>
                                        </Table.Cell>
                                        <Table.Cell width={3}>
                                            <Input fluid type="number" placeholder='Quantity' name="qty" value={list_element.qty} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button size="tiny" floated="right" color="red" icon="close" style={{display: (this.props.new_data_row.list.length > 0 ? "inline" : "none")}} onClick={() => this.addToList(false, index)}/>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                :
                                null
                            }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Button size="tiny" floated="right" color="blue" onClick={() => this.addToList(true)}>Add More</Button>
                            <Button size="tiny" floated="right" color="green" onClick={() => {this.setState({showListModal:false})}}>Done</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
        </div>
        )
    }
}
const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    Contact:  (data) => dispatch(contactAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
    setAppData: (data) => dispatch(appDataAction(data)),
    deployInterRouteMessage: (data) => dispatch(InterRouteCommunication(data))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeBody));
