import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../Components/section_testimonials';
import SectionFaq from '../Components/section_faq';
import SectionContact from '../Components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/franchisee_body.css'

class FranchiseeBody extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            onboarding_details: [
                {
                    image: '/images/partnership.png',
                    text: 'Register with Deskhub with an easy onboarding process and start getting benefits.'
                },
                {
                    image: '/images/relax_work.png',
                    text: 'Ensure getting regular business with the hellp of Deskneed. New leads everyday.'
                },
                {
                    image: '/images/work_checklist.png',
                    text: 'Complete your work with quality on time. Only you are best at the work you do.'
                },
                {
                    image: '/images/earn_money.png',
                    text: 'Regularised payments released in a timely manner. No need to chase with customers anymore.'
                },
            ],
            selected_onboarding: {
                image: '/images/partnership.png',
                text: 'Register with Deskhub with an easy onboarding process and start getting benefits.'
            }
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    showOnboardingDetails(index){
        this.setState({
            selected_onboarding: {...this.state.onboarding_details[index]}
        })
    }

    render(){
        return(
            <div>
                <div className="body-franchisee">
                    <div className="body-franchisee-1-1">
                        <div style={{fontSize: "large", width: "60%", textAlign:"left", paddingRight:'5%'}}>
                            <p style={{fontSize: "x-large"}}><b>Grow and earn with Deskneed</b></p>
                            <p style={{fontSize: 'medium'}}>
                                Deskneed gives franchise offers at different cities across North East for management of business in local area. Interested individuals are on boarded for doing business with Deskneed on a profit sharing and FOCO (Franchise owned Company operated) model.
                            </p>
                        </div>
                        <img src="/images/business_growth.png" style={{width: "40%"}}/>
                    </div>
                </div>

                <div className="body-franchisee-2">
                    <p style={{fontSize: "x-large", fontWeight:'bold', textAlign:'center'}}>Benefits & Support.</p>
                    <div>
                        <div className="body-about-2-1">
                            <img src="/images/man_calling.png"/>
                            <h2>Benefits by joining!</h2>
                            <ul>
                                <li>Monetary benefit against all the business.</li>
                                <li>The best returns against their sweat and money invested.</li>
                                <li>Incentivized benefits in terms of travel, cou pons etc.</li>
                            </ul>
                        </div>
                        <div className="body-about-2-2">
                            <img src="/images/tech_support.png"/>
                            <h2>Support from Deskhub!</h2>
                            <ul>
                                <li>Technology support for receiving orders, management of orders.</li>
                                <li>Brand support to attach with and do business with pride</li>
                                <li>Accounting & Legal support</li>
                                <li>Business training support.</li>
                            </ul>
                        </div>
                    </div>
                </div>
{
                // <div className="body-franchisee-3">
                //     <p style={{fontSize: "x-large", fontWeight:'bold'}}>How It Works?</p>
                //     <div className="franchisee-onboarding">
                //         <div className="franchisee-onboarding-points">
                //             <div onClick={() => this.showOnboardingDetails(0)}>
                //                 <p>1</p>
                //                 <p>Sign up with Deskhub in no time.</p>
                //             </div>
                //             <div onClick={() => this.showOnboardingDetails(1)}>
                //                 <p>2</p>
                //                 <p>Get new leads daily.</p>
                //             </div>
                //             <div onClick={() => this.showOnboardingDetails(2)}>
                //                 <p>3</p>
                //                 <p>Deliver services to clients.</p>
                //             </div>
                //             <div onClick={() => this.showOnboardingDetails(3)}>
                //                 <p>4</p>
                //                 <p>Get paid without any chase.</p>
                //             </div>
                //         </div>
                //         <div className="franchisee-onboarding-detail">
                //             <img className="franchisee-onboarding-detail-image" src={this.state.selected_onboarding.image}/>
                //             <p className="franchisee-onboarding-detail-text">
                //                 {this.state.selected_onboarding.text}
                //             </p>
                //             <Button variant="contained" color="primary" size="medium">Sign Up</Button>
                //         </div>
                //     </div>
                // </div>
}
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(FranchiseeBody);
