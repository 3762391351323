import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../Components/section_testimonials';
import SectionFaq from '../Components/section_faq';
import SectionContact from '../Components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/safety_body.css'

class SafetyBody extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    showContact(index){
        $('html, body').animate({
            scrollTop: $('.section-contact').offset().top - 70 //#DIV_ID is an example. Use the id of your destination on the page
        }, 'fast');
    }

    render(){
        return(
            <div>
                <div className="body-safety">
                    <div className="body-safety-1-1">
                        <div style={{textAlign:"center"}}>
                            <p style={{fontSize: "xx-large"}}><b>Helping You Create a Safe Working Place</b></p>
                            <p style={{fontSize: 'large'}}>
                                Handholding you towards safety with expert guidance.
                            </p>
                        </div>
                        <img src="/images/safety/bg_full.svg" style={{width: "100%"}}/>
                        <div>
                            Eliminate any Risk.
                            <br/>
                            <br/>
                            <Button variant="contained" color="primary" size="large" onClick={()=>this.showContact()}>Know More</Button>
                        </div>
                    </div>
                </div>

                <div className="body-safety-2">
                    <p style={{fontSize: "x-large", fontWeight:'bold', textAlign:'center'}}>Safety Necessities.</p>
                    <div>
                        <div className="body-safety-2-1">
                            <img src="/images/safety/sanitisation.svg"/>
                            <h2>Scheduled Safety Checks</h2>
                            <p>Schedule hygiene check-up at workplace. Cleaning and sanitisation on regular basis.</p>
                        </div>
                        <div className="body-safety-2-2">
                            <img src="/images/safety/regular_safety.svg"/>
                            <h2>Training & Drills</h2>
                            <p>Training session by experts at your workplace for training. Helping in planning and executing safety control in workplace. Preparation of protocol for workplace for better management.</p>
                        </div>
                        <div className="body-safety-2-3">
                            <img src="/images/safety/equipments.svg"/>
                            <h2>Awareness Campaigns</h2>
                            <p>Poster Preparation, notice board preparation for Covid -19.</p>
                        </div>
                    </div>
                </div>

                <div className="body-safety-2 next">
                    <p style={{fontSize: "x-large", fontWeight:'bold', textAlign:'center'}}>Safety Protocols at Workplace.</p>
                    <div>
                        <div className="body-safety-2-1">
                            <img src="/images/safety/sanitisation.svg"/>
                            <h3>Entry & Exit Checks</h3>
                            <p>Each person extering and exiting the workplace must be checked with temperature and hands sanitised. Temperatures must be kept in record.</p>
                        </div>
                        <div className="body-safety-2-2">
                            <img src="/images/safety/regular_safety.svg"/>
                            <h3>Identifying and securing contact points</h3>
                            <p>Contact points like door knob, common telephones etc must be sanitised in regular intervals.</p>
                        </div>
                        <div className="body-safety-2-3">
                            <img src="/images/safety/equipments.svg"/>
                            <h3>Safety in using common equipments.</h3>
                            <p>Users must sanitise their hands before and after use of any machinery hence prohibiting any risk of infectino in common users.</p>
                        </div>
                        <div className="body-safety-2-3">
                            <img src="/images/safety/sanitisation.svg"/>
                            <h3>Regular sanitisation</h3>
                            <p>Offices, workplaces must be sanitised at least once every week to ensure safe work environment.</p>
                        </div>
                    </div>
                </div>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(SafetyBody);
