import React from 'react';
import { Container,Button ,Image,Card,Icon,Grid ,Input, Dropdown } from 'semantic-ui-react'
import "../styles/sprinter.css"
import { connect } from 'react-redux';
import {appDataAction} from '../actions/appDataAction';
import image from "../assets/deskneed_back_person.jpg"

class Sprinter extends React.Component {
  constructor(props){
    super(props);
    this.state ={
        i :0,
    }
  }
  componentDidMount(){
    let length = this.props.app_data.hasOwnProperty('testimonials')?this.props.app_data.testimonials.length -1:0
    console.log(length);

    if(this.props.app_data.hasOwnProperty('testimonials')){
      setInterval(() => {
          this.setState({i:this.state.i == length?0: this.state.i+1,},()=>{
          document.getElementById('sprinter-text').className = 'animate'
          setTimeout(()=>{
            try {
              document.getElementById('sprinter-text').className = ''
            } catch (error) {
              return false;
            }
          },this.props.speed/4)
          })
      }, this.props.speed);
    }
  }
  moveToTestimonial(pos){
    let length = this.props.app_data.testimonials != undefined?this.props.app_data.testimonials.length -1:0
      switch (pos) {
          case 'prev':
              this.setState({ i:this.state.i == 0?length: this.state.i-1,},()=>{
                document.getElementById('sprinter-text').className = 'animate'
                setTimeout(()=>{
                  document.getElementById('sprinter-text').className = ''
                },this.props.speed/4)
              })
              break;
          case 'next':
                this.setState({ i:this.state.i == length?0: this.state.i+1,},()=>{
                  document.getElementById('sprinter-text').className = 'animate'
                  setTimeout(()=>{
                    document.getElementById('sprinter-text').className = ''
                  },this.props.speed/4)
                })
              break;
          default:
              break;
      }
  }
  render(){
    return(

        <div className="sprinter">
            <div className="sprinter-text-wrapper">
                <Icon size="big" name="angle left" onClick={()=>{this.moveToTestimonial('prev')}}/>
                <div id="sprinter-text">
                    {
                        this.props.app_data.hasOwnProperty('testimonials')
                        ?
                            <>
                            {
                                this.props.app_data.testimonials[(this.state.i)].text
                            }
                            </>
                        :
                            null
                    }
                    <div className="testimonial-user">
                        <img src={this.props.app_data.testimonials[(this.state.i)].image || image}/>
                        <span>{this.props.app_data.testimonials[(this.state.i)].name || ''}</span>
                    </div>
                </div>
                <Icon size="big" name="angle right" onClick={()=>{this.moveToTestimonial('next')}}/>
            </div>
        </div>

    )
  }
}

const mapStateToProps = state => ({
  ...state
 })
const mapDispatchToProps = dispatch => ({
  setAppData: (data) => dispatch(appDataAction(data))
 })
 export default connect(mapStateToProps, mapDispatchToProps)(Sprinter);
