import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';
import { NavLink, withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';

import '../styles/footer.css'

class Footer extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div className="footer">
{
                // <div className="footer-partners">
                //     <a href="http://deskneed.com" target="blank" className="footer-dsk-references">
                //         <img src="images/deskneed.png" className="footer-dsk-references-images"/>
                //     </a>
                //     <a href="http://deskfix.com" target="blank" className="footer-dsk-references">
                //         <img src="images/deskfix.png" className="footer-dsk-references-images"/>
                //     </a>
                // </div>
}

                <div className="footer-links">
                    <div className="first">
                        <span className="footer-headings">Contact Us</span> <br/>
                        <img src="/images/icon_phone.png"/>+91-9954683549<br/>
                        <img src="/images/icon_location.png"/>Assam, India<br/>
                        <img src="/images/icon_mail.png"/>contact@deskneed.com<br/>
                    </div>
                    <div className="second">
                        <span className="footer-headings">Our Company</span><br/>
                        <NavLink activeClassName="active" className="sidebar-nav-link" to="/about">About</NavLink>
                        <NavLink activeClassName="active" className="sidebar-nav-link" to="/seller">Seller</NavLink>
                        <NavLink activeClassName="active" className="sidebar-nav-link" to="/franchisee">Franchisee</NavLink>
                    </div>
                    <div className="third" >
                        <span className="footer-headings">Social Contacts</span><br/>
                        <a href="https://www.facebook.com/deskneed/" target="_blank"><img src="/images/facebook.svg"/>Facebook<br/></a>
                        <a href="http://linkedin.com/company/deskneed" target="_blank"><img src="/images/linkedin.svg"/>LinkedIn<br/></a>
                        <a href="https://twitter.com/_deskneed" target="_blank"><img src="/images/twitter.png"/>Twitter<br/></a>
                        <a href="https://www.instagram.com/deskneed" target="_blank"><img src="/images/instagram.svg"/>Instagram<br/></a>
                        <a href="https://angel.co/company/deskneed-1" target="_blank"><img src="/images/angel.png"/>Angel<br/></a>
                    </div>
                    <div className="third" >
                        <span className="footer-headings">Reach us</span><br/>
                        <a href="https://goo.gl/maps/ohQHnkQRdD9BRSdz5" target="blank" style={{color:'black', textDecoration:'none'}}>
                        <img src="/images/icon_location.png"/>R G B Road, Ganeshguri (Near Assam Minorities Development Board), Guwahati, Assam - 781006<br/>
                        +91-9954683549</a>
                    </div>
                </div>
            </div>
		)
    }
}
export default Footer;
