import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../Components/section_testimonials';
import SafetyBody from '../Components/safety_body';
import SectionFaq from '../Components/section_faq';
import SectionContact from '../Components/section_contact';
import { Helmet } from 'react-helmet';

import {Button, TextField} from '@material-ui/core';

import '../styles/safety_body.css'

class Safety extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    render(){
        return(
            <div>
                <Helmet>
                    <title>Safety at office from Corona Virus</title>
                    <description>Get your safety subscription. Regular sanitisation, use sanitisers, infrared thermometers and more</description>
                </Helmet>

                <SafetyBody/>

                <SectionContact title="Make Your Workplace Safe"/>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(Safety);
