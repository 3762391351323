import React from 'react';
import {connect } from 'react-redux';
import {newDataRowAction } from '../actions/newDataRowAction';
import {contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import {appDataAction} from '../actions/appDataAction'
import Client from '../services/Client';
import { NavLink, withRouter } from "react-router-dom";

import { Button, Container, Menu, Form, Card, Icon, Modal, Loader, Input, Select, Dropdown, GridColumn } from 'semantic-ui-react'
import DehazeIcon from '@material-ui/icons/Dehaze';

import '../styles/sidebar.css'

class Sidebar extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            allowed_routes:{
                user:['/','/home','/orders','/invoice','/account','/company','/gst-reports','/quotation','/logout','/contract','/delivery'],
                admin:['/','/home','/orders','/invoice','/account','/company','/users','/gst-reports',,'/quotation','/logout','/contract','/delivery']
            }
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        let allowed_routes= this.state.allowed_routes[this.props.app_data.user_details.role]

        return(
            <div className="sidebar-main">
                <div onClick={() => this.props.toggleSidebar()}>
                    <span class="material-icons sidebar-close">
                        keyboard_backspace
                    </span>
                </div>
                {
                    this.props.app_data.user_details.login == true
                    ?
                    <Menu text className="sidebar-navigation">
                        <NavLink style={{display:allowed_routes.includes('/orders')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/orders">Orders</NavLink>
                        <Dropdown item simple text="Vouchers" style={{}}>
                            <Dropdown.Menu style={{marginTop: 0}}>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/invoice')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/invoice">Invoices</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/quotation')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/quotation">Quotations</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/contract')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/contract">Contract</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/delivery')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/delivery">Delivery Note</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown item simple text="Account" style={{}}>
                            <Dropdown.Menu style={{marginTop: 0}}>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/invoice')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/account">
                                        Details
                                    </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/company')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/company">
                                        Company
                                    </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/users')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/users">
                                        Users
                                    </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink style={{display:allowed_routes.includes('/logout')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/logout">Logout</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <NavLink style={{display:allowed_routes.includes('/gst-reports')?'flex':'none'}} activeClassName="active" className="sidebar-nav-link" to="/gst-reports">GST-Reports</NavLink>
                        <a className="nav-link" target="blank" href="https://fix.deskhub.in" style={{alignSelf:'flex-start'}}>
                            <img src="/images/deskfix.png"/>
                        </a>
                    </Menu>
                    :
                    <div className="sidebar-navigation">
                        <a href="https://deskhub.in">Home</a>
                        <NavLink activeClassName="active" className="sidebar-nav-link" to="/about" onClick={() => this.props.toggleSidebar()}>About</NavLink>
                        <NavLink activeClassName="active" className="sidebar-nav-link" to="/seller" onClick={() => this.props.toggleSidebar()}>Seller</NavLink>
                        <NavLink activeClassName="active" className="sidebar-nav-link" to="/franchisee" onClick={() => this.props.toggleSidebar()}>Franchisee</NavLink>
{
                        // <NavLink activeClassName="active" className="sidebar-nav-link" to="/blog" onClick={() => this.props.toggleSidebar()}>Blog</NavLink>
}
                        <a className="nav-link" target="blank" href="https://fix.deskhub.in" style={{alignSelf:'flex-start'}}>
                            <img src="/images/deskfix.png"/>
                        </a>
                    </div>
                }
                <div className="sidebar-contact">
                    <span className="sidebar-contact-headings">Contact Us</span> <br/>
                    <img src="images/icon_phone.png"/>+91-9954683549<br/>
                    <img src="images/icon_location.png"/>Assam, India<br/>
                    <img src="images/icon_mail.png"/>contact@deskneed.com<br/>
                </div>
            </div>
		)
    }
}

const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    setAppData: (data) => dispatch(appDataAction(data)),
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
