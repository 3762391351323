import React from 'react';
import { Container} from 'semantic-ui-react'

// import logo from './logo.png'
const logo = require('../routes/logo.png')
export default class NoMatch extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Container textAlign="center" style={{padding:'20vh 0vh'}}>
          <img  alt="logo"  src={logo} style={{width:'80%'}} />
          <h1 className="tag-line" style={{fontSize:'3rem'}}>! Faulty Route</h1>
      </Container>
    )
  }
}

