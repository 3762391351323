import React from 'react';
import { Table,Checkbox,Segment,Image,Button,Input ,Icon,Dimmer , Dropdown, Container, Loader, Grid } from 'semantic-ui-react'
import "../styles/gst.css"
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import Client from '../services/Client';
import Navlinks from './Navlinks'
import Footer from './footer';
const logo = require('./logo.png')
const converter = require('number-to-words');


class Gst extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            invoices:[
                {voucher_date:'2019-08-01',customer:'Some Customer',invoice_id:'ABC-123',order_id:'123456',amount:423321,status:'Paid'},
                {voucher_date:'2019-08-01',customer:'that Customer',invoice_id:'CBA-123',order_id:'123458',amount:32154,status:'Paid'},
                {voucher_date:'2019-08-01',customer:'this Customer',invoice_id:'BCA-123',order_id:'123459',amount:67421,status:'Created'},
            ],
            sort_key:'voucher_date',
            order:'asc',
            data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFieldsGST: [
				{"value": "month", "text": "Month", "type": "date"},
				{"value": "amount", "text": "Amount", "type": "select"},
				{"value": "tax", "text": "Tax Amount", "type": "field"},
            ],
            tableFieldsInvoices:[
                {value:"voucher_id",text:'Invoice No'},
                {value:'date',text:'Date'},
                {value:'payable_amount',text:'Amount'},
            ],
            tableFieldsGstr1:[
                {value:'voucher_id',text:'Invoice No'},
                {value:'date',text:'Date'},
                {value:'payable_amount',text:'Payable Amount'},
                {value:'tax',text:'Tax'},
                {value:'taxable_amount',text:'Taxable Amount'}
            ],
			defaultSize: 'small',
			tableButtons: [
			],
			paymentReceipt: {paymentInvoices:[{}]},
			currentDataRow: {},
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'customer',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_customer: [],
			options_voucher_id: [],
			options_to_ledger: [],
			graph_customer:false,

            offset_gst:0,
            aggregated_months:[],
            observed_month:'',
            observed_month_data:[],
            observed_invoices:[],
            gstr1_data_rows:[],
            show_generate:true
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
    componentWillMount(){
        // var monthSelection = [{text: "All", value: 0}];
		// var startMonth = 1;
		// var startYear = 2018;
		// var today = new Date();
		// var endMonth = today.getMonth()+1; //January is 0!
		// var endYear = today.getFullYear();
		// var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}

		// var today = new Date();
		// var dd = today.getDate();
		// var mm = today.getMonth() + 1; //January is 0!
		// var yyyy = today.getFullYear();
		// var dateVaue = yyyy + '-' + mm + '-' + dd;

		// while(endYear >= startYear){
		// 	if(startYear == endYear && startMonth > endMonth){
		// 		break;
		// 	}
		// 	else{
		// 		monthSelection.push({
		// 			text: monthConvert[endMonth] + " " + endYear,
		// 			value: endYear + "-" + ("0" + endMonth).slice(-2)
		// 		})
		// 		endMonth--;
		// 		if(endMonth <= 0){
		// 			endYear--;
		// 			endMonth = 12;
		// 		}
		// 	}
        // }

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
        var end_date = yyyy + '-' + mm + '-' + dd;
        var start_date = yyyy + '-' + mm + '-01'
        let option_branches = []
        option_branches.push({text:'All',value:'All'})
        this.props.app_data.user_details.branches.forEach(branch => {
          option_branches.push({text:branch.branch_name, value:branch.id})
        })
        let cust_branch
        if(option_branches.length == 2){
          cust_branch = option_branches[1].value
        } else {
          cust_branch= option_branches[0].value
        }

        this.setState({
            option_branches,
            cust_branch:cust_branch,
            start_date:start_date,
            end_date:end_date
        },()=>{
            this.getInvoicesMonthArray()
        })
    }
    generateGstReport(){
        this.setState({listing_gst:true})
        let user_details = this.props.app_data.user_details
        if (this.state.observed_invoices.length == 0) {
            this.setState({show_generateing:false,gstr1_data_rows:[]})
        } else {
            this.setState({loader:true})
            Client.getGSTreport(
                user_details.token,user_details.reg_id,user_details.username,
                this.state.offset_gst,
                null,
                'date',
                'asc',
                {['accounting_vouchers.id']:[...this.state.observed_invoices]},
                Math.random(22)
            )
            .then(res=>{
                    this.setState({gstr1_data_rows:res.data_rows,show_generate:false,show_generateing:false,loader:false})
            })
        }
    }
    getInvoicesMonthArray(){
        let user_details = this.props.app_data.user_details
        this.setState({loadingGST:true})
        let filter ={}
        if (this.state.cust_branch != 'All') {
          filter= {cust_branch:[this.state.cust_branch]}
        } else {
          let filter_branches = this.props.app_data.user_details.branches.map(branch => {return branch.id})
          filter = {cust_branch:[...filter_branches]}
        }
		Client.getAccountingSale(user_details.token,user_details.reg_id,user_details.username,
			this.state.offset_gst,
			null,
			'date',
			'asc',
            filter,
            {date:[this.state.start_date,this.state.end_date]},
            Math.random(22)
			).then(res=>{

            let withMonthAsKey = this.getDistictIn('month', this.withMonth(res.data_rows))
            let month_with_aggregations = this.arrgregateMonths(withMonthAsKey)

            console.log(month_with_aggregations);
            this.setState({aggregated_months:month_with_aggregations,loadingGST:false,withMonthAsKey,fetching:false})
		})
    }
    showDataRowDetails(month){
        this.setState({listing_gst:false})
        if (month == this.state.observed_month) {
            this.setState({observed_month:'',gstr1_data_rows:[],show_generate:false,})
        } else {
            let withMonthAsKey = this.state.withMonthAsKey
            this.setState({observed_month:month,observed_month_data:[]})
            let observed_month_data
            for (const key in withMonthAsKey) {
                if (key == month) {
                    observed_month_data = withMonthAsKey[key];
                }
            }
            console.log(observed_month_data);

            let invoice_ids = this.getInvoiceIdsForMonth(observed_month_data)
            this.setState({observed_month_data:observed_month_data,
                show_generateing:true,
                observed_invoices:[],gstr1_data_rows:[],
                observed_invoices:[...invoice_ids]
                },()=>{
                    if(this.state.observed_month_data.length){
                        this.setState({show_generate:true})
                    }
                })
        }
    }
    checkAll(){

        let invoice_ids = this.getInvoiceIdsForMonth(this.state.observed_month_data)
        if (this.state.observed_invoices.length == this.state.observed_month_data.length) {
            invoice_ids = []
        }
        this.setState({
            observed_invoices:[...invoice_ids],
            show_generateing:true
        },()=>{
            if (this.state.show_generate == false) {
                this.generateGstReport()
            }
        })
    }
    prepareInvoiceIdArray(id){
        let observed_invoices = this.state.observed_invoices
        let new_observed_invoices
        if (observed_invoices.includes(id)) {
            new_observed_invoices =observed_invoices.filter(elem=>{
                return elem != id
            })
            console.log(observed_invoices);

        } else {
            observed_invoices.push(id)
            new_observed_invoices = [...observed_invoices]
        }
        this.setState({observed_invoices:new_observed_invoices,show_generateing:true},()=>{
            if (this.state.show_generate == false) {
                this.generateGstReport()
            }
        })
    }
    getInvoiceIdsForMonth(month){
        let arr =[]
        month.forEach(elem=>{
            arr.push(elem.id)
        })
        return arr
    }
    sortby(field){
        let aggregated_months = this.state.aggregated_months
        let sort_key = this.state.sort_key
        let order = this.state.order
        if (sort_key == field) {
            order = order == 'asc'?'dsc':'asc'
        } else {
            sort_key = field
        }
        let new_aggregated_months = aggregated_months.sort((a,b)=>{
            console.log(a[field]);
            return order == 'asc' ?a[field] > b[field]: b[field] > a[field]
        })
        this.setState({sort_key,aggregated_months,order})

    }
    arrgregateMonths(months){
        let aggregated =[]
        for (const key in months) {
            let amount = 0
            let tax = 0
            months[key].forEach(invoice=>{
                amount += parseFloat(invoice.payable_amount)
                tax += parseFloat(invoice.tax_amount)
            })
            aggregated.push({month:key,amount:amount,tax:tax})
        }
        return aggregated
    }
    withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []
        // array = array.sort((a,b)=>{
        //     return a.date > b.date
        // })
        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    getDistictIn(key,data_rows){
        var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
        })
		return distincts
    }
    excelfyDataRows(){
        let my_json = this.state.gstr1_data_rows
        let new_json =  my_json.map((each_json=>{
            return {
                ['Voucher Id']:each_json.voucher_id,
                Date:each_json.date,
                ['Payable Amount']:each_json.payable_amount,
                Tax:each_json.tax,
                ['Taxable Amount']:each_json.taxable_amount
            }
        }))
        let work_book = XLSX.utils.book_new()
        let work_sheet = XLSX.utils.json_to_sheet(new_json)
        XLSX.utils.book_append_sheet(work_book,work_sheet,"deskneed gst")
        let exported = XLSX.write(work_book,{bookType:'xlsx',type:'array'})
        saveAs(new Blob([exported],{type:'application/octet-stream'}),'gst_report.xlsx')
    }
    consoleDataChange(e,data){
        console.log(data);
        let fetching = false
        if (data.name=='cust_branch') {
            fetching=true
        }
        this.setState({
            [data.name]:data.value,fetching
        },()=>{
            this.getInvoicesMonthArray()
        })
    }
    render(){
        return(
                <>
                {
                    this.props.app_data.user_details.login
                    ?
                    <Navlinks
                    company={this.state.company_details}
                    user={this.state.user_details}
                    />
                    :
                    null
                }
                <div className="gst-block">
                    <div className="head-consoles">
                        <div>

                        </div>
                    </div>
                    <Grid stackable  className="gst-area" columns="equal">
                        <Grid.Column className={this.state.listing_gst?"hide":""}>
                            <div className="theme-table">
                                <div className="right-console">
                                    <Dropdown
                                        placeholder="branch"
                                        name="cust_branch"
                                        selection
                                        loading={this.state.fetching}
                                        className='dropdown-margin'
                                        onChange={(e,data)=>this.consoleDataChange(e,data)}
                                        value={this.state.cust_branch}
                                        options={this.state.option_branches}
                                    />
                                    <Input
                                        placeholder="From"
                                        className="mid-input"
                                        onChange={(e,data)=>this.consoleDataChange(e,data)}
                                        value={this.state.start_date}
                                        type="date"
                                        name="start_date"
                                    />
                                    <Input
                                        placeholder="To"
                                        onChange={(e,data)=>this.consoleDataChange(e,data)}
                                        value={this.state.end_date}
                                        type="date"
                                        name="end_date"
                                    />
                                </div>
                                <Table unstackable celled>
                                    <Table.Header>

                                    <Table.Row>
                                        {
                                            this.state.tableFieldsGST.map(field=>(
                                                <Table.HeaderCell style={{cursor:'pointer'}} onClick={()=>this.sortby(field.value )}>
                                                    {field.text}
                                                    <Icon name=
                                                    {
                                                        this.state.sort_key == field.value
                                                        ?
                                                            this.state.order == 'asc'
                                                            ?
                                                            'angle up'
                                                            :
                                                            'angle down'
                                                        :
                                                        null
                                                    }
                                                    />
                                                </Table.HeaderCell>
                                            ))
                                        }
                                    </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.loadingGST
                                            ?
                                            <Table.Row>
                                                <Table.Cell colSpan={3}>
                                                    <Segment>
                                                        <Dimmer active>
                                                        <Loader />
                                                        </Dimmer>
                                                        <Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                                    </Segment>
                                                </Table.Cell>
                                            </Table.Row>
                                            :
                                            null
                                        }
                                        {
                                            this.state.aggregated_months.map(month =>(
                                                <>
                                                <Table.Row
                                                    style={{
                                                        cursor:'pointer',

                                                    }}
                                                    onClick={()=>{this.showDataRowDetails( month.month)}} >
                                                    {
                                                        this.state.tableFieldsGST.map((field)=>(
                                                            <Table.Cell>
                                                                {
                                                                    field.value == "month"
                                                                    ?
                                                                        this.state.observed_month==month.month
                                                                        ?
                                                                        <Icon name="triangle down"/>
                                                                        :
                                                                        <Icon color="grey" name="triangle right"/>
                                                                    :
                                                                    null
                                                                }
                                                                {(field.value=='amount'||field.value=='tax')?month[field.value].toFixed(2):month[field.value]}

                                                            </Table.Cell>
                                                        ))
                                                    }

                                                </Table.Row>
                                                    {
                                                        this.state.observed_month == month.month
                                                        ?
                                                        <Table.Row >
                                                            <Table.Cell style={{padding:'0px'}} colSpan={4}>
                                                            <Table unstackable className="inner-table" celled compact fluid wide>
                                                                <Table.Header fullWidth>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell >
                                                                            <Checkbox
                                                                                checked={this.state.observed_invoices.length == this.state.observed_month_data.length}
                                                                                onChange={()=>{this.checkAll()}}
                                                                                />
                                                                        </Table.HeaderCell>
                                                                        {
                                                                            this.state.tableFieldsInvoices.map(field=>(
                                                                                <Table.HeaderCell>
                                                                                    {
                                                                                        field.text
                                                                                    }
                                                                                </Table.HeaderCell>
                                                                            ))
                                                                        }
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                {
                                                                    this.state.observed_month_data.map(invoice=>(
                                                                        <Table.Row  >
                                                                            <Table.Cell collapsing>
                                                                                <Checkbox
                                                                                    radio
                                                                                    checked={this.state.observed_invoices.includes(invoice.id)}
                                                                                    onClick={()=>{this.prepareInvoiceIdArray(invoice.id)}}
                                                                                     />
                                                                            </Table.Cell>
                                                                            {
                                                                                this.state.tableFieldsInvoices.map((field)=>(
                                                                                    <Table.Cell>
                                                                                        {invoice[field.value]}
                                                                                    </Table.Cell>
                                                                                ))
                                                                            }

                                                                        </Table.Row>
                                                                    ))
                                                                }
                                                                </Table.Body>
                                                            </Table>
                                                            </Table.Cell>
                                                        </Table.Row>

                                                    :
                                                    null
                                                }
                                                </>
                                            ))
                                        }

                                    </Table.Body>
                                </Table>
                            </div>
                        {
                            this.state.observed_invoices.length >0 && this.state.show_generate
                            ?
                            <div className="action-container">
                                <Button className="action" onClick={()=>{this.generateGstReport()}}>
                                    Generate GST Report
                                </Button>
                            </div>
                            :
                            null
                        }
                        </Grid.Column>
                        <Grid.Column className={!this.state.listing_gst?"hide":""}>

                        {
                            this.state.gstr1_data_rows.length
                            ?
                            <>
                                <Table className="excelable" celled  compact unstackable>
                                    <Table.Header>
                                        {
                                            this.state.tableFieldsGstr1.map(field=>(
                                                <Table.HeaderCell>
                                                    {field.text}
                                                </Table.HeaderCell>
                                            ))
                                        }
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            this.state.show_generateing
                                            ?
                                            <Table.Row>
                                                <Table.Cell colSpan={5}>
                                                    <Segment>
                                                        <Dimmer active>
                                                            <Loader />
                                                        </Dimmer>
                                                        <Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                                    </Segment>
                                                </Table.Cell>
                                            </Table.Row>
                                            :
                                            this.state.gstr1_data_rows.map(data_row=>(
                                                <Table.Row>
                                                    {
                                                        this.state.tableFieldsGstr1.map(field=>(
                                                            <Table.Cell>
                                                                {data_row[field.value]}
                                                            </Table.Cell>
                                                        ))
                                                    }
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>
                                <div className="action-container">
                                    <Button className="action back action-left" onClick={()=>{this.setState({listing_gst:false,show_generate:true})}}>
                                        Back
                                    </Button>
                                    <Button className="action action-right" onClick={()=>{this.excelfyDataRows()}}>
                                        Download as Excel
                                    </Button>
                                </div>
                                </>
                                :
                                this.state.listing_gst && !this.state.loader
                                ?
                                <div className="placeholder" style={{flexDirection:'column'}}>
                                    <h1>Something Went Wrong <br/>Server Error!</h1>
                                    <Button className="action" fluid wide onClick={()=>{this.setState({listing_gst:false,show_generate:true})}}>
                                        Back
                                    </Button>
                                </div>
                                :
                                !this.state.loader
                                ?
                                <div className="placeholder">
                                    <h1>Select Entries to <br/>generate Spreadsheet</h1>
                                </div>
                                :
                                <div className="placeholder">
                                    <Loader active/>
                                </div>
                            }
                            </Grid.Column>
                        {
                            this.state.message?this.state.message:null
                        }
                    </Grid>

                </div>
                </>
        )
    }
}
   const mapStateToProps = state => ({
    ...state
   })
  const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    Contact:  (data) => dispatch(contactAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
   })
  export default connect(mapStateToProps, mapDispatchToProps)(Gst);
