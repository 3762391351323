import React from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Label, Message } from 'semantic-ui-react'
import "../styles/home.css"
import "../styles/contact.css"
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
import Client from '../services/Client';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import Footer from './footer';
import {appDataAction} from '../actions/appDataAction'


class Contact extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            new_contact_row:{},
            message:false,
            done:false,
            error_feilds:[]
        }
    }
    componentWillMount(){
        if (this.props.app_data.login_mode == "query") {
            let new_contact_row = this.state.new_contact_row
            let queryObject = this.props.new_data_row
            let service = this.props.option_services.find(elem=>{return elem.value == queryObject.service_category})
            let service_name = service.text
            let city = queryObject.city
            let requirement = queryObject.requirement
            let service_time = queryObject.service_time
            let subscription_type = queryObject.subscription_type
            let description = queryObject.description
            new_contact_row["query"] = `query for service ${service_name} ${description?'with subscription type: '+description:''}`+
                                        `, expected timing: ${service_time}, addition details ${requirement}`
            this.setState({new_contact_row})
        }
    }
    setContactFormData(e,data){
        let new_contact_row = this.state.new_contact_row

        this.setState({error_feilds:this.state.error_feilds.filter(elem => elem!=data['name'])})
        new_contact_row[data.name] = data.value
        this.setState({new_contact_row})
    }
    sendRequest(){
        let data = new FormData()
        let user_details = this.props.app_data.user_details
        let new_data_row = this.state.new_contact_row
        new_data_row.files = []
        this.props.app_data.files.forEach((file,i)=>{
            data.append('image'+i,file.file,file.file.name)
            console.log("test",file);
            // new_data_row.files.push('image'+i)
            new_data_row.files.push({file_name:file.file.name,file_key:'image'+i})

        })
        for (var key of data.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
        if (this.validator(["name","phone"],new_data_row)) {
            this.setState({loading:true})
            
            Client.createContactRequest(user_details.token,user_details.reg_id,user_details.username,new_data_row,data)
                .then(res=>{
                    this.setState({done:true,loading:false,new_contact_row:{}})
                    setTimeout(()=>{
                        this.setState({message:false})
                        this.props.setAppData({...this.props.app_data,files:[],show_preview:false})
                },4000)
            })
            .catch(err=>{
                this.setState({message:"Request Not Sent",loading:false})
                setTimeout(()=>{
                    this.setState({message:false})
                },4000)
            })
        } else {
            console.log("validation failed for contact form.")
        }
        
    }
    validator(keys_array,target_obj){
        let error_feilds = []
        // console.log(this.props.app_data.user_details.approval_required != 1);
    
        keys_array.forEach(key => {
              if ( target_obj[key] == '' || target_obj[key] == null || target_obj[key] == undefined || target_obj[key] == 'undefined') {
                console.log("key :" ,key,"value",target_obj[key]);
    
                error_feilds.push(key)
              }
        });
        this.setState({error_feilds},()=>{
        })
        if (error_feilds.length) {
          // console.log(error_feilds);
          //some errors send false
          return false
        } else {
          // console.log(error_feilds,"pass");
          //0 errors send true
          return true
        }
      }
    render(){
        return(
            <>
                
                {
                    this.state.done && this.props.app_data.login_mode == "query"
                    ?
                    <div className="contact-success-message">
                        <Message >
                            <Message.Header>
                                <Icon name="check circle outline" color="green"/>
                                Your request has been sent to team.
                                Our executives will get back to you shortly
                            </Message.Header>
                        </Message>
                        {/* <Footer/> */}
                        <hr/>
                        <div className="contact-footer">
                            {/* <span className="head-text">Contact:</span> */}
                            <div>
                                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@deskneed.com" target="_blank">contact@deskneed.com</a><br/>
                                +91-9954683549<br/>
                                <a target="blank" href="https://blog.deskneed.com">Blog</a>
                            </div>
                        </div>
                        {/* <Grid className="contact-footer" stackable columns="equal" >
                            <Grid.Row >
                            <Grid.Column>
                                <a target="blank" href="/about">About Us</a> <br/>
                                <a target="blank" href="/business">Service Engineer / Seller</a> <br/>
                                <a target="blank" href="/franchise">Local Partners / Franchise</a> <br/>
                            </Grid.Column>
                            <Grid.Column style={{textAlign:'center'}}>
                                <span className="head-text">Company Details:</span><br/>
                                CIN: U74999MP2016PTC041773<br/>
                                GST: 18AAXCS9739A1ZO<br/>
                                DIPP: DIPP1842<br/>
                                MSME UAM No.: AS03D0001520
                            </Grid.Column>
                            <Grid.Column className="last" style={{textAlign:'right'}}>
                            </Grid.Column>
                            </Grid.Row>
                        </Grid> */}
                    </div>
                    :
                    
                    <Form onSubmit={()=>this.sendRequest()} className="contact-form">
                        <Message style={{display:this.state.message?'block':'none'}} >
                            <Message.Header>
                                {this.state.message}
                            </Message.Header>
                        </Message>
                        <Form.Field error={this.state.error_feilds.includes('name')} >
                        <Input   label={<Label style={{width:'20%',textAlign:'left'}} basic>Name</Label>} id="contact_target" value={this.state.new_contact_row.name || ''} name="name" placeholder='Enter Your Name' onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                        </Form.Field>
                        <Form.Field error={this.state.error_feilds.includes('phone')}>
                        <Input  label={<Label style={{width:'20%',textAlign:'left'}} basic>Phone</Label>}  value={this.state.new_contact_row.phone || ''} name="phone" placeholder='Enter Your Phone Number' onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                        </Form.Field>
                        <Form.Field>
                        <Input  label={<Label style={{width:'20%',textAlign:'left'}} basic>Email</Label>} value={this.state.new_contact_row.email || ''} name="email" placeholder='Enter Your Email Address' onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                        </Form.Field>
                        <Form.Field>
                        <Input  label={<Label style={{width:'20%',textAlign:'left'}} basic>Organisation</Label>} value={this.state.new_contact_row.organisation || ''} name="organisation" placeholder='Enter Your Organisation Name' onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                        </Form.Field>
                        <Form.Field>
                        <Input  label={<Label style={{width:'20%',textAlign:'left'}} basic>Query</Label>}  value={this.state.new_contact_row.query || ''} name="query" placeholder='Enter Your Query' onChange={(e,data)=>{this.setContactFormData(e,data)}} />
                        </Form.Field>
                        <Button  fluid wide type='submit' loading={this.state.loading} positive>Request/Enquire</Button>
                    </Form>
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    ...state
   })
  const mapDispatchToProps = dispatch => ({
    // newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    setAppData: (data) => dispatch(appDataAction(data)),

   })
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
  