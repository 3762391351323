import React from 'react';
import $ from 'jquery';
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import Client from './services/Client';
import { simpleAction } from './actions/newDataRowAction';
import Topbar from './Components/topbar'
import Footer from './Components/footer'
import Sidebar from './Components/sidebar'
import PagesLink from './routes/page-links'
import Navlinks from './routes/Navlinks'
import Home from './routes/home'
import Blog from './routes/blog'
import About from './routes/about'
import Seller from './routes/seller'
import Franchisee from './routes/franchisee'
import Safety from './routes/safety'
import Orders from './routes/orders'
import Invoice from './routes/invoice'
import Account from './routes/account'
import Employee from './routes/employee'
import Gst from './routes/gst'
import Company from './routes/company'
import Logout from './routes/logout';
import NoMatch from './Components/Nomatch'
import Contract from "./routes/contract";
import { connect } from 'react-redux'
import {cityAction} from './actions/optionsCityAction'
import {serviceAction} from './actions/optionsServiceAction'
import {categoryAction} from './actions/optionsCategoryAction'
import {appDataAction} from './actions/appDataAction'
import { newDataRowAction } from './actions/newDataRowAction';
import Delivery from "./routes/delivery"
import Feedback from './routes/feedback'
import VerifyOrder from './routes/verify-order'
import PricingModal from "./routes/pricing-modal"
import { Loader, Modal } from 'semantic-ui-react'
import Quotation from './routes/quotation';
import { log } from 'util';
import PricingModalForType from './routes/pricing-modal-for-type';
import FilePreview from './routes/file-preview'
import FileOrdersPreview from './routes/file-order-preview'

// import pricingModal from './routes/pricing-modal';
class App extends React.Component {
    constructor(props){
        super(props);
        var safetyView = this.checkSubdomain();
        this.state ={
            safetyView: safetyView,
            new_data_row:{final:false},
            new_contact_row:{},
            allowed_routes:{
                user:['/','/home','/orders','/invoice','/account','/company','/gst-reports','/quotation','/logout','/contract','/delivery'],
                admin:['/','/home','/orders','/invoice','/account','/company','/users','/gst-reports','/quotation','/logout','/contract','/delivery']
            }
        }

        this.props.history.listen((location, action) => {
            // location is an object like window.location
            this.setState({
                safetyView: this.checkSubdomain()
            });
        });
    }

    checkSubdomain(){
        if(window.location.hostname.split('.').length > 2 && window.location.hostname.split('.')[0] == "safety")
        {
            // if(window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') == '' || window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') == 'home'){
                return true;
            // }
        }
        return false;
    }

    componentWillMount(){
        let initial_appdata = {
            user_details: {
                login:false,
            },
            files:[],
            order_files:[],
            show_order_preview:false,
            login_mode:"login",
            show_preview:false
        }
        this.props.setAppData(initial_appdata);
        this.props.optionCities([
            {text:"None",value:undefined},
        ])
        this.props.optionServices([
            {text:"None",value:undefined},
        ])
        this.props.setAppData({
            ...this.props.app_data,
            testimonials:[{text:''}],
            files:[],
            show_preview:false,
            order_files:[],
            show_order_preview:false,
        })
        let optionServices =[]
        let optionCities =[]
        let optionCategories = []
        // app bootstraping data
        if (this.props.history.pathname == '/logout' ) {
            if (localStorage.getItem('user_details') != null) {
                this.setState({status:'User Logging Out'})
                return 0
            } else {
                window.location.reload()
            }
        } else {
            Client.getCorpAppData().then((res)=>{
                this.setState({status:'Fetching App Data ...'})
                res.cities.map(city=>{
                    optionCities.push({text:city,value:city})
                })
                res.service_categories.map(srvc_cat=>{
                    optionServices.push({
                        text:srvc_cat.category,
                        value:srvc_cat.id,
                        sub_categories:srvc_cat.sub_categories,
                        city:srvc_cat.city,
                        starting_price:srvc_cat.starting_price
                    })
                })
                this.props.optionServices(optionServices)
                this.props.optionCities(optionCities)
                this.props.optionCategories(optionCategories)
                this.props.setAppData({
                    ...this.props.app_data,
                    options:{service:optionServices,cities:optionCities },
                    testimonials:res.testimonials
                })
                //re-auth user
                let user_details = JSON.parse(localStorage.getItem('user_details'))
                if (user_details) {
                    if (user_details.hasOwnProperty('login')) {
                        if (user_details.login == true) {
                            Client.getUserDetails(user_details.token,user_details.username,user_details.reg_id).then((res)=>{
                                this.setState({status:'Fetching User Data ...'})
                                if (res.responseResult == 'success') {
                                    let user_obj = {
                                        name : res.name,
                                        username : res.username,
                                        city: res.city,
                                        role : res.role,
                                        email : res.email,
                                        phone  : res.phone,
                                        company : res.company.name,
                                        branches : res.company.branches,
                                        designation : res.designation,
                                        token : user_details.token,
                                        login : true,
                                        company_id : res.company.id,
                                        reg_id : user_details.reg_id,
                                        allowed_routes: this.state.allowed_routes[res.role],
                                        approval_required:res.approval_required,
                                        approvers:JSON.parse(res.approvers)
                                    }
                                    let allowed_routes = user_obj.allowed_routes
                                    console.log("location",window.location.pathname.split('/'))
                                    if (!allowed_routes.includes(window.location.pathname) && !allowed_routes.includes(window.location.pathname.split('/')[0])) {
                                    }
                                    let options_branches= user_obj.branches.map(branch =>{
                                        return {text:branch.branch_name,value:branch.id,city:branch.city}
                                    })
                                    user_obj['options_branches'] = options_branches
                                    user_obj['options_approvers'] =[]
                                    this.props.setAppData({...this.props.app_data,user_details:user_obj})
                                    localStorage.setItem('user_details',JSON.stringify(user_obj))
                                    this.setState({status:'User Authenticated'})
                                    if (user_obj.approval_required) {
                                        let approvers = user_obj.approvers
                                        Client.getCompanyUsers(user_obj.token,user_obj.reg_id,user_obj.username,null,null,{account_id:approvers},"rehydrate").then(res=>{
                                            let users = res.data_rows.map(user=>{
                                              return {value:user.account_id,text:user.name}
                                            })
                                            user_obj['options_approvers'] =users
                                            this.props.setAppData({...this.props.app_data,user_details:user_obj})
                                        })
                                    }
                                }
                            }).catch(err=>{
                            })
                        } else {
                        let reg_id =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                        this.props.setAppData({...this.props.app_data,user_details:{reg_id:reg_id}});
                        this.setState({status:'User Not Authenticated'})
                        if (window.location.pathname == '/login') {
                        // alert('login')
                        // this.props.setAppData({...this.props.app_data,showLoginModal:false})
                        }
                    }
                    } else {
                        this.setState({status:'User Not Authenticated'})
                        if (window.location.pathname == '/login') {
                        }
                    }
                } else {
                    let reg_id =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                    this.props.setAppData({...this.props.app_data,user_details:{reg_id:reg_id}});
                    this.setState({status:'User Not Authenticated'})
                    if (window.location.pathname == '/login') {
                        this.props.setAppData({...this.props.app_data,showLoginModal:true,login_mode:"login"})
                    }
                }
            });
        }
    }
    componentDidMount(){
        // let am_i_the_source
        let user =   JSON.parse(localStorage.getItem('user_details')) || {}
        window.tab = Math.random('salt')
        window.addEventListener('storage', function(e) {
            console.log('localStorage was changed',JSON.parse(e.newValue))
            // this.console.log(JSON.parse(e.storageArea).user_details);
            if (user.login) {
                if(JSON.parse(e.newValue) == null  )
                    window.location.reload()
            } else {
                window.location.reload()
            }
        });
        if (window.location.pathname == '/logout') {
            let reg_id =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            localStorage.removeItem('user_details')
            // this.setState({})
            this.props.setAppData({...this.props.app_data,user_details:{login:false,reg_id:reg_id}})
            setTimeout(()=>{
                this.props.history.push('/')
            },0)

            Client.logout(user.username,user.token,user.reg_id).then(res=>{
            })
            .catch(err=>{
            })
        }

        //on react router change
        this.props.history.listen((location, action) => {
            if (location.pathname == '/home' || location.pathname == "/" || location.pathname == "") {
                this.props.newDataRowAction({list:[{name:''}]})
            }
            // this.props.setAppData({...this.props.app_data,visibleToggleables:''})
            if(location.pathname == '/logout'){
                let user =  this.props.app_data.user_details || JSON.parse(localStorage.getItem('user_details')) || {}
                let reg_id =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                localStorage.removeItem('user_details')

                this.props.setAppData({...this.props.app_data,user_details:{login:false,reg_id:reg_id}})

                this.props.history.push('/');

                Client.logout(user.username,user.token,user.reg_id).then(res=>{})
                .catch(err=>{})
            }
            console.log(location);
        });
    }

    toggleSidebar(){
        var left = $(".sidebar-main").css('left');
        left = left.substring(0, left.length - 2)
        if(left >= -10){
            $(".sidebar-main").css('left', '-75vw');
        }
        else{
            $(".sidebar-main").css('left', '0vw');
        }
    }

    render(){
        return(
            <div>
                {
                    (this.state.status == 'User Authenticated' || this.state.status == 'User Not Authenticated')
                    ?
                        <div>
                            {
                                this.props.app_data.user_details.login && false
                                ?
                                <PagesLink/>
                                :
                                null
                            }
                            <Sidebar history={this.props.history} toggleSidebar={this.toggleSidebar}/>
                            <Topbar history={this.props.history} toggleSidebar={this.toggleSidebar}/>
                            <div style={{height:'15vh'}}/>
                            {
                                this.state.safetyView
                                ?
                                <Safety/>
                                :
                                <Switch>
                                    <Route exact path="/orders" children={(props)=>(<Orders /> )} />
                                    <Route exact path="/invoice" children={(props)=>(<Invoice /> )} />
                                    <Route exact path="/account" children={(props)=>(<Account /> )} />
                                    <Route exact path="/company" children={(props)=>(<Company /> )} />
                                    <Route exact path="/contract" children={(props)=>(<Contract/>)}/>
                                    <Route exact path="/users" children={(props)=>(<Employee /> )} />
                                    <Route exact path="/gst-reports" children={(props)=>(<Gst /> )} />
                                    <Route exact path="/quotation" children={(props)=>(<Quotation /> )} />
                                    {/* <Route exact path="/logout" children={(props)=>(<Logout /> )} /> */}
                                    <Route exact path="/delivery" children={(props)=>(<Delivery /> )} />
                                    <Route path="/order/verify" children={(props)=>(<VerifyOrder /> )} />
                                    <Route path="/feedback" children={(props)=>(<Feedback /> )} />
                                    <Route path="/blog" children={(props)=>(<Blog /> )} />
                                    <Route path="/about" children={(props)=>(<About /> )} />
                                    <Route path="/franchisee" children={(props)=>(<Franchisee /> )} />
                                    <Route path="/seller" children={(props)=>(<Seller /> )} />
                                    <Route path="/" children={(props)=>(<Home /> )} />
                                    <Route component={NoMatch} />
                                </Switch>
                            }
                            <Footer/>
                        </div>
                    :
                    <Loader style={{marginTop:'50vh'}} active inline='centered'/>
                }
                <Modal
                    open={this.props.app_data.showPricingModal}
                    onClose={()=>{this.props.setAppData({...this.props.app_data,showPricingModal:false})}}>
                    <PricingModal/>
                </Modal>
                <Modal
                    open={this.props.app_data.showPricingModalForType !==undefined && this.props.app_data.showPricingModalForType !==false}
                    onClose={()=>{this.props.setAppData({...this.props.app_data,showPricingModalForType:false})}}>
                    <PricingModalForType/>
                </Modal>
                <Modal open={this.props.app_data.show_preview !== false} onClose={()=>this.props.setAppData({...this.props.app_data,show_preview:false})}>
                    <Modal.Content>
                        <FilePreview/>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    optionCities: (data) => dispatch(cityAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
    setAppData: (data) => dispatch(appDataAction(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
