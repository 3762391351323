import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../Components/section_testimonials';
import SellerBody from '../Components/seller_body';
import SectionFaq from '../Components/section_faq';
import SectionContact from '../Components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/about_body.css'

class Seller extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    render(){
        return(
            <div>
                <p style={{fontSize:'xx-large', fontWeight:'bold', textAlign:'center'}}>Grow your Business with Deskhub</p>

                <SellerBody/>

                <SectionContact/>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(Seller);
