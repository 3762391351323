import React from 'react';
import { Container,Button ,Form,Table,Card,Icon,Grid ,Input,Placeholder,Label, Dropdown,Modal, GridColumn,Responsive, Checkbox } from 'semantic-ui-react'
import "../styles/account.css"
import Sprinter from '../Components/Sprinter'
import SimpleMap from '../Components/Simplemap';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import Client from '../services/Client';
import Navlinks from './Navlinks'
import Footer from './footer';
class Employee extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            observed_user:false,
            table_fields:[
                {value:'name',text:'Name',type:'field'},
                {value:'phone',text:'Phone',type:'field'},
                {value:'email',text:'Email',type:'field'},
            ],
            users:[],
            new_data_row:{approval_required:0,approvers:[]},
            sort:'name',
            sort_order:'asc'
        }
        this.getUsers = this.getUsers.bind(this)
        this.branchIdToText = this.branchIdToText.bind(this)
    }
    componentDidMount(){
        this.getUsers()
    }
    getUsers(){
        let user_details = this.props.app_data.user_details
        Client.getCompanyUsers(user_details.token,user_details.reg_id,user_details.username).then(res=>{
            let users = []
            res.data_rows.forEach(user=>{
                user.corp_branch = user.corp_branch
                user.approvers = JSON.parse(user.approvers)
                try {
                    user.city = JSON.parse(user.city)
                } catch (error) {
                    console.log(error);

                }
                users.push(user)
            })
            this.setState({users,observed_user:false},()=>{this.sortUsers()})
        })
    }
    consoleDataChange(e,data){
        console.log(data);
        let observed_user =''
        if(data.name == 'observed_user'){
            observed_user = this.state.users.filter(user=>{
                return user.account_id == data.value
            })

            this.setState({[data.name]:data.value,observed_user:observed_user[0]})
        }
    }
    newDataRowChange(e,data){
        let new_data_row = this.state.new_data_row
        new_data_row[data.name]=data.value
        let user_details =  this.props.app_data.user_details
        if(data.name=='corp_branch' && !new_data_row.length && data.value.length){
            let branch_selected = user_details.branches.find(branch=>{
                return branch.id == data.value
            })
            new_data_row.city = branch_selected.city
            console.log("branch selected",branch_selected,data.value);
        }
        if (data.name=='corp_branch' &&  !data.value.length) {
            delete new_data_row.city
        }
        if(data.name == "approval_required"){
            console.log(new_data_row['approval_required']);

            new_data_row['approval_required'] = new_data_row['approval_required'] == 1 ? 0 : 1
        }
        this.setState({new_data_row})
    }
    createNewUser(){
        this.setState({createing_user:true})
        let new_data_row = this.state.new_data_row
        let status = new_data_row.edit?'passed':this.validator(new_data_row,["name","corp_branch"])
        if (status == 'passed') {
            new_data_row['active'] = 1
            let user_details = this.props.app_data.user_details
            Client.createCompanyUsers(user_details.token,user_details.reg_id,user_details.username,new_data_row).then(res=>{
                this.setState({createing_user:false,show_create_user_modal:false,error:false,new_data_row:{}})
                this.getUsers()
            }).catch(err=>{
                this.setState({error:'please fill all fields'})
            })
        } else {
            this.setState({createing_user:true,error:status})
        }
    }
    editUser(){
        let new_data_row = this.state.observed_user
        console.log(JSON.parse(this.state.observed_user.corp_branch),this.state.observed_user.corp_branch);

        new_data_row['corp_branch'] =this.state.observed_user.corp_branch
        new_data_row['edit'] = true
        this.setState({new_data_row,show_create_user_modal:true})
    }
    validator(data,shouldHave){
        if ((data.key == data.confirm_key)&& data.key != undefined) {
            return 'passed'
        } else {
            return "Password did'nt matched"
        }
    }
    branchIdToText(id){
        let branches = ""

        console.log(id);
        id.forEach(element => {
            let branch_selected = this.props.app_data.user_details.branches.filter(branch=>{
                return branch.id == element
            })
            branches +=branch_selected[0].branch_name+", "
        });

       return branches
    }
    sort(field) {
        let sort = this.state.sort
        let sort_order = this.state.sort_order
        if(field == sort) {
            sort_order = sort_order == 'asc'?'desc':"asc"
        } else {
            sort = field
        }
        this.setState({sort,sort_order},()=>{
            this.sortUsers()
        })
    }
    sortUsers(){
        let sort = this.state.sort
        let sort_order = this.state.sort_order
        let users = this.state.users
        users.sort((a,b)=>{
            if (sort_order == "asc") {
                return a[sort] < b[sort]
            } else {
                return a[sort] > b[sort]
            }
        })
        this.setState({users})
    }
    render(){
        let user_details =  this.props.app_data.user_details
        let company = this.props.app_data.user_details.company
        let observed_user = this.state.observed_user
        return(
            <>
            {
                this.props.app_data.user_details.login
                ?
                <Navlinks
                company={this.state.company_details}
                user={this.state.user_details}
                />
                :
                null
            }
            <Container className="account">
                <div className="user">
                    <h1 className="company-title"><Icon name="building"/>{company}</h1>
                    <Button primary onClick={()=>{this.setState({show_create_user_modal:true})}}>Create</Button>
                    {
                        this.state.observed_user
                        ?
                        <Button color="black" onClick={()=>{this.editUser()}} >
                            <Icon name="pencil"/>
                            Edit
                        </Button>
                        :
                        null
                    }
                    <hr></hr>
                <Grid stackable>
                        <Grid.Column only='large screen' className="branch-table" width={this.state.observed_user?8:16}>
                        <Table compact celled>
                            <Table.Header>
                                <Table.Row>
                                    {
                                        this.state.table_fields.map(field =>{
                                        return (<Table.HeaderCell onClick={()=>{this.sort(field.value)}}>
                                                    {field.value}
                                                    {
                                                        this.state.sort == field.value
                                                        ?
                                                        <Icon name={this.state.sort_order == "asc"?"angle down":"angle up"}/>
                                                        :
                                                        null
                                                    }
                                                </Table.HeaderCell>
                                            )
                                        })
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body >
                            {
                                    this.state.users.map(user=>{
                                        return (
                                            <Table.Row
                                                style={{backgroundColor:user.account_id == this.state.observed_user.account_id?'red':'white',cursor:'pointer'}}
                                                onClick={(e)=>{this.consoleDataChange(e,{name:'observed_user',value:user.account_id})}}
                                                >
                                                {
                                                    this.state.table_fields.map(field=>{
                                                        return <Table.Cell>{user[field.value]?user[field.value].trim()?user[field.value]:'N/A':'N/A'}</Table.Cell>
                                                    })
                                                }
                                            </Table.Row>
                                        )
                                    })
                            }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Responsive maxWidth={1200}>
                        <Dropdown
                            fluid
                            name="observed_user"
                            wide
                            selection
                            value={this.state.observed_user.id}
                            style={{marginTop:'4vh',width:'100%'}}
                            options={this.state.users.map((user)=>({text:user.name,value:user.account_id}))}
                            onChange={(e,data)=>{this.consoleDataChange(e,data)}}
                            placeholder="Select a branch"
                            />
                    </Responsive>
                    <Grid.Column style={{display:this.state.observed_user?'block':'none'}} width={8}>
                        {
                            this.state.observed_user?
                            <>
                                <div className="detail">
                                    <Label basic color="red"><Icon name="user"/>Name</Label>
                                    <span className="value">{this.state.observed_user.name}</span>
                                </div>
                                <div className="detail">
                                    <Label basic color="red"><Icon name="star"/>Role</Label>
                                    <span className="value address" >{this.state.observed_user.role}</span>
                                </div>
                                <div className="detail">
                                    <Label basic color="red"><Icon name="paper plane"/>E mail</Label>
                                    <span className="value">{this.state.observed_user.email}</span>
                                </div>
                                <div className="detail">
                                    <Label basic color="red"><Icon name="phone"/>Phone</Label>
                                    <span className="value">{this.state.observed_user.phone}</span>
                                </div>
                                <div className="detail">
                                    <Label basic color="red"><Icon name="building"/>Branch</Label>
                                    <span className="value">{ this.branchIdToText(this.state.observed_user.corp_branch) }</span>
                                </div>
                            </>
                            :
                            null
                        }
                    </Grid.Column>
                </Grid>
                </div>
                <Modal open={this.state.show_create_user_modal} onClose={()=>{this.setState({show_create_user_modal:false,new_data_row:{approvers:[],approval_required:0}})}}>
                    <Modal.Header>Create User</Modal.Header>
                    <Modal.Content>
                            {
                                this.state.error
                                ?
                                <h3>{this.state.error}</h3>
                                :null
                            }
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Name</label>
                                        <Input value={this.state.new_data_row.name} fluid placeholder='Name' name="name" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Phone</label>
                                        <Input value={this.state.new_data_row.phone} fluid placeholder='Phone' name="phone" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Email</label>
                                        <Input value={this.state.new_data_row.email} fluid placeholder='Email' name="email" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Branch</label>
                                        <Dropdown
                                            selection
                                            multiple
                                            name="corp_branch"
                                            options={user_details.options_branches}
                                            onChange={(e,data)=>{this.newDataRowChange(e,data)}}
                                            value={this.state.new_data_row.corp_branch?
                                                    Array.isArray(this.state.new_data_row.corp_branch)
                                                    ?
                                                    this.state.new_data_row.corp_branch
                                                    :
                                                    JSON.parse(this.state.new_data_row.corp_branch)
                                                    :[]
                                                }
                                            placeholder='Choose Branch'
                                            />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Role</label>
                                        <Dropdown
                                            selection
                                            options={[
                                                {text:"Admin",value:'admin'},
                                                {text:"User",value:'user'}
                                            ]}
                                            name="role"
                                            onChange={(e,data)=>{this.newDataRowChange(e,data)}}
                                            value={this.state.new_data_row.role}
                                            placeholder='Choose Role'
                                            />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Username</label>
                                        <Input value={this.state.new_data_row.username} fluid placeholder='Username' name="username" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Admin Password</label>
                                        <Input type={'password'} value={this.state.new_data_row.admin_key} fluid placeholder='Admin Password' name="admin_key" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                    <Form.Field
                                        // style={{display:this.state.new_data_row.edit?'none':'inline-block'}}
                                        >
                                        <label>Password</label>
                                        <Input type={'password'} value={this.state.new_data_row.key} fluid placeholder='Password' name="key" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                    <Form.Field
                                        // style={{display:this.state.new_data_row.edit?'none':'inline-block'}}
                                        >
                                        <label>Confirm Password</label>
                                        <Input type={'password'} value={this.state.new_data_row.confirm_key} fluid placeholder='Confirm Password' name="confirm_key" onChange={(e,data)=>{this.newDataRowChange(e,data)}}/>
                                    </Form.Field>
                                </Form.Group>
                                <span style={{display:"flex",justifyContent:"space-between"}}>
                                    <Button
                                        color={this.state.new_data_row.edit?'black':'green'}
                                        loading={this.state.createing_user}
                                        onClick={()=>{this.createNewUser()}}>
                                            {this.state.new_data_row.edit?'Edit':'Create'}
                                    </Button>
                                    <span className="approve">
                                        <span className="check-box-approve">
                                            <span>
                                                Needs Approval?
                                            </span>
                                            <Checkbox checked={this.state.new_data_row.approval_required} onChange={(e)=>this.newDataRowChange(e,{name:"approval_required",value:this.state.new_data_row.approval_required})}/>
                                        </span>
                                        {
                                            this.state.new_data_row.approval_required
                                            ?
                                                <Dropdown
                                                    multiple
                                                    options={this.state.users.map(user=>{return{text:user.username,value:user.account_id}})}
                                                    onChange={(e,data)=>{this.newDataRowChange(e,data)}}
                                                    name="approvers"
                                                    search
                                                    selection
                                                    placeholder="Choose Authorities"
                                                    value={this.state.new_data_row.approvers}
                                                />
                                            :
                                            null
                                        }
                                    </span>
                                </span>
                            </Form>
                    </Modal.Content>
                </Modal>
            </Container>
            </>
        )
    }
}
const mapStateToProps = state => ({
...state
})
const mapDispatchToProps = dispatch => ({
    newDataRowAction: (data) => dispatch(newDataRowAction(data)),
    Contact:  (data) => dispatch(contactAction(data)),
    optionServices: (data) => dispatch(serviceAction(data)),
    optionCategories: (data) => dispatch(categoryAction(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Employee);
