import React from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Placeholder,Label, Dropdown, GridColumn } from 'semantic-ui-react'
import "../styles/account.css"
import Sprinter from '../Components/Sprinter'
import SimpleMap from '../Components/Simplemap';
import { connect } from 'react-redux';
import { newDataRowAction } from '../actions/newDataRowAction';
import { contactAction } from '../actions/contactAction';
import {serviceAction} from '../actions/optionsServiceAction'
import {categoryAction} from '../actions/optionsCategoryAction'
import Navlinks from './Navlinks'
import Footer from './footer';
class Account extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        let user =  this.props.app_data.user_details
        let company = this.props.app_data.user_details.company
        return(
            <>
            {
                this.props.app_data.user_details.login
                ?
                <Navlinks
                company={this.state.company_details}
                user={this.state.user_details}
                />
                :
                null
            }
            <Container className="account">
                <div className="user">
                    <h1><Icon name="user"/> User Details</h1>
                    <hr></hr>
                <Grid centered stackable>
                    <Grid.Column width={7}>
                        <div className="detail">
                            <Label basic color="red"><Icon name="user"/>Name</Label>
                            <span name="value">{user.name?user.name.trim() : 'N/A'}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="phone"/>Phone</Label>
                            <span name="value">{user.phone?user.phone.trim()?user.phone.trim(): 'N/AXXXXXXX' : 'N/A'}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="star"/>Designation</Label>
                            <span name="value">{user.designation?user.designation.trim(): 'N/A'}</span>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <div className="detail">
                            <Label basic color="red"><Icon name="paper plane"/>E-mail</Label>
                            <span name="value">{user.email?user.email.trim() : 'N/A'}</span>
                        </div>
                        <div className="detail">
                            <Label basic color="red"><Icon name="setting"/>Role</Label>
                            <span name="value">{user.role?user.role.trim(): 'N/A'}</span>
                        </div>
                    </Grid.Column>
                </Grid>
                </div>
            </Container>
            </>
        )
    }
}
const mapStateToProps = state => ({
...state
})
const mapDispatchToProps = dispatch => ({
newDataRowAction: (data) => dispatch(newDataRowAction(data)),
Contact:  (data) => dispatch(contactAction(data)),
optionServices: (data) => dispatch(serviceAction(data)),
optionCategories: (data) => dispatch(categoryAction(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Account);
