import $ from 'jquery';
import createHistory from 'history/createBrowserHistory';
import SmartHitter from './smart-hitter';

const serverAddress = '/api';
// const serverAddress = 'http://localhost/doorhopperServer/public/api';
// const serverAddress = 'https://deskneed.com/api';
//science 2 home
// const serverAddress = 'http://192.168.0.06/deskneed/public/api';
// ISHU's
// const serverAddress = 'http://192.168.0.15/doorhopperServer/public/api';

// const serverAddress = 'http://10.10.99.196';
// const serverAddress = 'http://192.168.0.2';
// const serverAddress = 'http://192.168.43.171';

var sh = new SmartHitter();

let monitor_city = localStorage.getItem('city');
const Client={

    //app bootstraping data
    getAccountingSale(token,reg_id,username,offset,limit,sort,sort_order,filter={},range={},networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter) || {}}&limit=${limit}&offset=${offset}`+
        `&sort=${sort || 'date'}&sort_order=${ sort_order|| 'asc'}&range=${JSON.stringify(range) || {}}`
        return sh.hit('/api/vdhp/accounting/invoice/get/'+networkReqNAME, `${serverAddress}/corp/invoice/get`, 'POST', data)
    },
    getAccountingContract(token,reg_id,username,offset,limit,sort,sort_order,filter={},range={},networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter) || {}}&limit=${limit}&offset=${offset}`+
        `&sort=${sort || 'date'}&sort_order=${ sort_order|| 'asc'}&range=${JSON.stringify(range) || {}}`
        return sh.hit('/api/vdhp/accounting/contract/get/'+networkReqNAME, `${serverAddress}/corp/contract/get`, 'POST', data)
    },
    getAccountingQuotation(token,reg_id,username,offset,limit,sort,sort_order,filter={},range={},networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter) || {}}&limit=${limit}&offset=${offset}`+
        `&sort=${sort || 'date'}&sort_order=${ sort_order|| 'asc'}&range=${JSON.stringify(range) || {}}`
        return sh.hit('/api/vdhp/accounting/quotation/get/'+networkReqNAME, `${serverAddress}/corp/quotation/get`, 'POST', data)
    },
    approveQuotation(token,reg_id,username,remark,approve,quotation_id,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&remark=${remark}`+
        `&approve=${approve}&quotation_id=${quotation_id}`
        return sh.hit('/api/vdhp/accounting/quotation/approve'+networkReqNAME, `${serverAddress}/corp/quotation/approve`, 'POST', data)
    },
    approveOrderSlip(token,reg_id,username,new_data_row,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&new_data_row=${JSON.stringify(new_data_row)}`
        return sh.hit('/api/vdhp/order/slip/approve'+networkReqNAME, `${serverAddress}/corp/order/approve`, 'POST', data)
    },
    getGSTreport(token,reg_id,username,offset,limit,sort,sort_order,filter={},networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter) || {}}&limit=${limit}&offset=${offset}`+
        `&sort=${sort || 'date'}&sort_order=${ sort_order|| 'asc'}`
        return sh.hit('/api/vdhp/accounting/invoice/report/gstr1'+networkReqNAME, `${serverAddress}/corp/invoice/report/gstr1`, 'POST', data)
    },
    getCorpAppData(networkReqNAME=""){
        let data = `portal=deskneed`
        return sh.hit('/appdata'+networkReqNAME, `${serverAddress}/corp/appdata`, 'POST', data)
    },
    login(username,key,reg_id,networkReqNAME=""){
        let data = `username=${username}&key=${key}&reg_id=${reg_id}`
        return sh.hit('/login/'+networkReqNAME, `${serverAddress}/corp/account/login`, 'POST',data)
    },
    logout(username,token,reg_id,networkReqNAME=""){
        let data = `username=${username}&token=${token}&reg_id=${reg_id}`
        return sh.hit('/logout/'+networkReqNAME, `${serverAddress}/corp/account/logout`, 'POST',data)
    },
    getUserDetails(token,username,reg_id,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}`
        return sh.hit('/account/'+networkReqNAME, `${serverAddress}/corp/account/details`, 'POST',data)
    },
    getOrders(token,reg_id,username,offset,limit,filter='{}',sort,sort_order,networkReqNAME=""){
        let data =  `reg_id=${reg_id}&token=${token}&username=${username.trim()}&sort=${sort}&sort_order=${sort_order}&offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter) || '{}'}`
        return sh.hit('/orders/'+networkReqNAME, `${serverAddress}/corp/order/get`, 'POST',data)
    },
    verifyOrderWithKey(token,reg_id,username,verifyToken, checkbox, rating, review,otp,networkReqNAME=""){
        let data =  `reg_id=${reg_id}&token=${token}&username=${username ? username.trim() : username}&verifyToken=${verifyToken}&checkbox=${checkbox}&rating=${rating}&review=${review}&otp=${otp}`
        return sh.hit('/orders/complete/verify/'+networkReqNAME, `${serverAddress}/corp/order/complete/verify`, 'POST',data)
    },
    getOrderForVerification(token,reg_id,username,verifyToken,networkReqNAME=""){
        let data =  `reg_id=${reg_id}&token=${token}&username=${username ? username.trim() : username}&verifyToken=${verifyToken}`
        return sh.hit('/orders/complete/verify/get/'+networkReqNAME, `${serverAddress}/corp/order/complete/verify/get`, 'POST',data)
    },
    getOrderComments(token,reg_id,username,filter='{}',networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter)}`
        return sh.hit('/comment/get'+networkReqNAME, `${serverAddress}/corp/order/comment/get`, 'POST',data)
    },
    createOrderComment(token,reg_id,username,new_data_row,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&new_data_row=${JSON.stringify(new_data_row)}`
        return sh.hit('/comment/create'+networkReqNAME, `${serverAddress}/corp/order/comment/create`, 'POST',data)
    },
    createOrder(new_data_row,token,reg_id,username,networkReqNAME="",form_body){
        let data = `reg_id=${reg_id}&token=${token}&username=${username.trim()}&new_data_row=${JSON.stringify(new_data_row)}`
        return fetch(`${serverAddress}/corp/order/create?`+data, {
            method: 'POST',
            body: form_body||'',

        }).then(res=>res.json())
        // return sh.hit('/order/create'+networkReqNAME, `${serverAddress}/corp/order/create`, 'POST',data,form_body)
    },
    createCompanyBranch(token,reg_id,username,new_data_row,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username.trim()}&new_data_row=${JSON.stringify(new_data_row)}`
        return sh.hit('/order/create'+networkReqNAME, `${serverAddress}/corp/branch/create`, 'POST',data)
    },

    getCompanyUsers(token,reg_id,username,offset,limit,filter={},networkReqNAME=""){
        let data =  `reg_id=${reg_id}&token=${token}&username=${username.trim()}&offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter) || {}}`
        return sh.hit('/user/'+networkReqNAME, `${serverAddress}/corp/user/get`, 'POST',data)
    },
    createCompanyUsers(token,reg_id,username,new_data_row,networkReqNAME=""){
        let data =  `reg_id=${reg_id}&token=${token}&username=${username.trim()}&new_data_row=${JSON.stringify(new_data_row)}`
        return sh.hit('/user/'+networkReqNAME, `${serverAddress}/corp/user/create`, 'POST',data)
    },
    getAccountingDeleveryChallan(token,reg_id,username,offset,limit,sort,sort_order,filter={},range={},networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter) || {}}&limit=${limit}&offset=${offset}`+
        `&sort=${sort || 'date'}&sort_order=${ sort_order|| 'asc'}&range=${JSON.stringify(range) || {}}`
        return sh.hit('/api/vdhp/accounting/dnote/get/'+networkReqNAME, `${serverAddress}/corp/dnote/get`, 'POST', data)
    },
    createContactRequest(token=undefined,reg_id=undefined,username=undefined,new_data_row,form_body,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&new_data_row=${JSON.stringify(new_data_row)}`
        return fetch(`${serverAddress}/corp/contact/general?`+data, {
            method: 'POST',
            body: form_body||'',
        }).then(res=>res.json())
        // return sh.hit('/api/vdhp/contact/general/'+networkReqNAME, `${serverAddress}/corp/contact/general`, 'POST', data)
    },
    createFeedback(token=undefined,reg_id=undefined,username=undefined,new_data_row,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&new_data_row=${JSON.stringify(new_data_row)}`
        return sh.hit('/api/vdhp/feedback/general/'+networkReqNAME, `${serverAddress}/corp/order/feedback/create`, 'POST', data)
    },
    createFeedbackWithKey(token=undefined,reg_id=undefined,username=undefined,new_data_row,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&new_data_row=${JSON.stringify(new_data_row)}`
        return sh.hit('/api/vdhp/feedback/key/'+networkReqNAME, `${serverAddress}/corp/order/feedback/create`, 'POST', data)
    },
    ordersFeedbackGet(token,reg_id,username,offset,limit,sort,sort_order,filter={},range={},networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&filter=${JSON.stringify(filter) || {}}&limit=${limit}&offset=${offset}`+
        `&sort=${sort || 'date'}&sort_order=${ sort_order|| 'asc'}&range=${JSON.stringify(range) || {}}`
        return sh.hit('/api/vdhp/order/feedback/get/'+networkReqNAME, `${serverAddress}/corp/order/feedback/get`, 'POST', data)
    },
    getPricing(offset,limit,filter='{}',sort,sort_order,category,city,networkReqNAME=""){
        let data =  `sort=${sort}&sort_order=${sort_order}`+
        `&offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter) || '{}'}&category=${category || ''}&city=${city || ''}`
        return sh.hit('/pricing/', `${serverAddress}/corp/pricing`, 'POST',data)
    },
}
export default Client;
